define('bottomline-execution/serializers/bx/site', ['exports', 'bottomline-execution/serializers/site'], function (exports, _site) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _site.default.extend({
    normalizeResponse: function normalizeResponse(json) {
      var mappedSite = {
        id: json.id,
        type: 'site',
        attributes: {
          Address: json.address,
          City: json.city,
          CountryCode: json.country_code,
          latitude: json.latitude,
          longitude: json.longitude,
          Name: json.name,
          Telephone: json.phone_number,
          ZipCode: json.zip_code
        }
      };

      mappedSite = this._addCustomer(mappedSite, json);

      return { data: mappedSite };
    },
    _addCustomer: function _addCustomer(mappedSite, json) {
      if (Ember.isEmpty(json.customer_type)) return mappedSite;

      var customerId = 'site-' + json.id + '-customer';

      var existingRecord = this.get('store').peekRecord('customer', customerId);
      if (Ember.isPresent(existingRecord)) existingRecord.unloadRecord();

      var customer = this.get('store').createRecord('customer', {
        id: customerId,
        type: json.customer_type.toLowerCase()
      });

      mappedSite.attributes.customer = customer;

      return mappedSite;
    }
  });
});