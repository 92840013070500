define('bottomline-execution/components/details-vehicle-unload', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'storage-compartments',
    tagName: 'div'
  });
});