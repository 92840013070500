define('bottomline-execution/models/shift', ['exports', 'ember-data', 'bottomline-execution/mixins/trip-sorter', 'bottomline-execution/mixins/state-transition', 'bottomline-execution/mixins/adapter-options'], function (exports, _emberData, _tripSorter, _stateTransition, _adapterOptions) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = Model.extend(_stateTransition.default, _tripSorter.default, _adapterOptions.default, {
    startable: true,

    ResourceID: attr('string'),
    ResourceName: attr('string'),
    PlannedStartDT: attr('date'),
    ConfirmedStartDT: attr('date'),
    ConfirmedEndDT: attr('date'),
    state: attr('string'),
    remarks: attr('string'),
    TruckID: attr('string'),
    TruckLicense: attr('string'),
    TrailerID: attr('string'),
    RegionID: attr('string'),
    TrailerLicense: attr('string'),
    OdometerStart: attr('number'),
    OdometerEnd: attr('number'),
    question_list_before: attr('number'),
    question_list_after: attr('number'),
    adr_report_template_name: attr('string'),
    truck: attr(),
    trailer: attr(),
    CompoundVehicleID: attr('string'),

    truck_inventories: hasMany('truck-inventory', { async: false }),
    truck_storages: hasMany('truck-storage', { async: false }),
    trips: hasMany('trips', { async: true }),

    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    nextStates: Ember.computed('state', 'canStart', 'canFinish', function () {
      return this.get('transitions')[this.get('state')];
    }),
    hasStartedElements: Ember.computed.alias('hasStartedTrips'),
    hasStartedTrips: Ember.computed('trips.@each.state', function () {
      return !this.get('trips').every(function (trip) {
        return trip.get('state') === 'planned';
      });
    }),
    started: Ember.computed.equal('state', 'started'),
    finished: Ember.computed.equal('state', 'finished'),
    shifts: Ember.computed(function () {
      return this.store.peekAll('shift').filterBy('TruckID', this.get('appState.currentTruck'));
    }),
    startedShift: Ember.computed('shifts.@each.state', function () {
      return this.get('shifts').findBy('state', 'started');
    }),
    startedTrip: Ember.computed('trips.@each.state', function () {
      return this.get('trips').findBy('state', 'started');
    }),
    showQuestions: Ember.computed.readOnly('appConfiguration.truckSettings.questionaire'),
    groupedTruckInventory: Ember.computed('truck_inventories', function () {
      return this.groupBy(this.get('truck_inventories'), 'material_id', ['currentQty']);
    }),
    canStart: Ember.computed('startable', 'startedShift.id', function () {
      if (this.get('startable') && (!this.get('startedShift') || this.get('startedShift.id') === this.get('id'))) {
        return true;
      }

      return false;
    }),
    canFinish: Ember.computed('trips.isFulfilled', 'trips.@each.state', function () {
      var trips = this.get('trips').filter(function (trip) {
        return trip.get('state') !== 'finished';
      });

      return trips.length === 0;
    }),
    tripStops: Ember.computed('trips.isFulfilled', 'isFulfilled', function () {
      var coll = this.get('trips').map(function (trip) {
        return trip.get('trip_stops').toArray();
      });

      return [].concat.apply([], coll);
    }),
    activeTruck: Ember.computed('TruckID', function () {
      return this.store.peekAll('truck').findBy('TruckID', this.get('TruckID'));
    }),
    activeTrailer: Ember.computed('TrailerID', function () {
      return this.store.peekAll('trailer').findBy('TruckID', this.get('TrailerID'));
    }),
    meterType: Ember.computed('TrailerID', 'activeTruck.@each', 'activeTrailer.@each', function () {
      if (Ember.isPresent(this.get('TrailerID')) && Ember.isPresent(this.get('activeTrailer.MeterTypeID'))) {
        return this.get('activeTrailer.MeterTypeID');
      }

      if (Ember.isPresent(this.get('TruckID')) && Ember.isPresent(this.get('activeTruck.MeterTypeID'))) {
        return this.get('activeTruck.MeterTypeID');
      }

      return null;
    }),

    truckInventoryMaterials: function truckInventoryMaterials() {
      return Ember.A(this.get('truck_inventories')).filter(function (truck_inventory) {
        return truck_inventory.get('currentQty') > 0;
      }).map(function (truck_inventory) {
        return truck_inventory.get('material_id');
      }).compact().uniq();
    },
    getMaxTripSequenceNb: function getMaxTripSequenceNb() {
      return Math.max.apply(null, this.get('trips').map(function (trip) {
        return trip.get('ConfirmedSequenceNb') !== undefined ? trip.get('ConfirmedSequenceNb') : -1;
      }));
    },
    start: function start() {
      this.set('ConfirmedStartDT', new Date());
      this.set('state', 'started');

      return this.save(this.adapterAction('start'));
    },
    finish: function finish() {
      this.set('ConfirmedEndDT', new Date());
      this.set('state', 'finished');
      return this.save(this.adapterAction('finish'));
    },
    cancel: function cancel() {
      this.set('ConfirmedStartDT', null);
      this.set('state', 'planned');

      return this.save(this.adapterAction('cancel'));
    }
  });
});