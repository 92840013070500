define('bottomline-execution/components/input-number-text', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.TextField.extend({
    tagName: 'input',
    type: 'number',

    numericValue: Ember.computed('value', {
      get: function get() {
        var val = parseFloat(this.get('value'));
        if (Ember.isEmpty(val) || isNaN(val)) {
          return undefined;
        } else {
          return val;
        }
      },
      set: function set(key, value) {
        this.set('value', Ember.isEmpty(value) ? undefined : value);
      }
    })
  });
});