define('bottomline-execution/controllers/trips/details', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    selectedTripStop: Ember.computed('parentController.selectedTripStop', function () {
      return this.parentController.selectedTripStop;
    })
  });
});