define('bottomline-execution/mirage/factories/truck-setting', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    compartments: true,
    add_extra_trip_stops: true,
    load_by_weight: true
  });
});