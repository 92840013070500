define('bottomline-execution/controllers/trip-stops/material-transfer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    internetConnection: Ember.inject.service(),

    actions: {
      returnToTripIndex: function returnToTripIndex() {
        this.transitionToRoute('trips.index');
      }
    }
  });
});