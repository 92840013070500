define('bottomline-execution/controllers/trip-stops/show/scan', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    scanner: Ember.inject.service(),
    hpScanner: Ember.inject.service(),
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),
    pictureSaver: Ember.inject.service(),

    queryParams: ['relatedStorageId'],
    loading: false,
    scanners: Ember.A([]),
    previewImage: null,

    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    isAtx: Ember.computed(function () {
      return _environment.default.APP.target === 'atx' && typeof plugins !== 'undefined';
    }),

    testImage: function testImage() {
      return 'R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7';
    },
    getFileEntry: function getFileEntry(imageURI) {
      var _this = this;

      window.resolveLocalFileSystemURL(imageURI, function (fileEntry) {
        var reader = new FileReader();

        reader.onloadend = function (e) {
          var image = e.target.result.split('base64,')[1];

          if (!Ember.isEmpty(image)) {
            _this.set('previewImage', image);
          } else {
            _this.get('toast').error(_this.get('i18n').t('no_image_data.scan_file'));
          }

          fileEntry.remove();
        };

        fileEntry.file(function (file) {
          return reader.readAsDataURL(file);
        });
      });
    },

    actions: {
      scan: function scan() {
        var _this2 = this;

        console.log('Start scanning with: ' + this.get('appState.scanner_brand'));
        this.get('appState').save();

        if (this.get('appState.scanner_brand') === 'epson') {
          this.get('scanner').scan(function (imageData) {
            _this2.set('previewImage', imageData);
            _this2.connectToOriginalNetwork();

            console.log('Scan finished');
          });
        } else if (this.get('appState.scanner_brand') === 'hp') {
          this.get('hpScanner').betterScanPage(function (result) {
            return _this2.getFileEntry(result[0]);
          });
        } else if (this.get('appState.scanner_brand') === 'canon') {
          if (typeof cordova === "undefined") {
            alert('No mobile device bindings found, so assuming test otherwise rebuild');
          } else {
            cordova.plugins.ScangarooWrapper.scan(function () {
              _this2.get('toast').info('Scan cancelled...');
              _this2.transitionToRoute('trip-stops.show', _this2.get('model').id);
              console.error('SCANNER', 'Cancelled');
            }, function (result) {
              console.info('SCANNER', 'Received: ' + result);
              var cordovaFriendlyLocation = result.replace("file:///mnt/sdcard", "file:///storage/extsd");
              console.info('SCANNER File converted to: ' + cordovaFriendlyLocation);
              _this2.getFileEntry(cordovaFriendlyLocation);
            });
          }
        } else {
          alert('Select brand');
        }
      },
      confirm: function confirm() {
        if (Ember.isEmpty(this.get('previewImage'))) {
          this.get('toast').error(this.get('i18n').t('no_image_data.scan_confirm'));
          return false;
        }

        var scannedPicture = this.get('store').createRecord('picture', {
          imageData: this.get('previewImage'),
          TableKey: this.get('model').id,
          TableName: 'TripStop',
          Description: 'png',
          StorageID: this.get('relatedStorageId') || -1,
          PictureType: 'scan',
          BE_PictureGUID: this.get('model').createGuid()
        });

        this.get('pictureSaver').save(scannedPicture);
        this.set('previewImage', null);

        var tripStop = this.get('model');

        tripStop.set('picture_count', (tripStop.get('picture_count') || 0) + 1);
        this.transitionToRoute('trip-stops.show', tripStop.id);
      },
      refresh: function refresh() {
        var _this3 = this;

        if (!escan) {
          WifiWizard.startScan(function () {
            WifiWizard.getScanResults(function (list) {
              _this3.set('scanners', list.filter(function (e) {
                return e.SSID.indexOf('DIRECT-') > -1;
              }));
            });
          });
        }
      },
      cancel: function cancel() {
        this.set('previewImage', null);
        this.transitionToRoute('trip-stops.show');
      }
    }
  });
});