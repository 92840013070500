define('bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    plannedOrConfirmedTripStopStorages: function plannedOrConfirmedTripStopStorages(_ref) {
      var tripStop = _ref.tripStop,
          tripStopStorages = _ref.tripStopStorages,
          storageGroupID = _ref.storageGroupID,
          includeUnplannedStorages = _ref.includeUnplannedStorages;

      return tripStopStorages.filter(function (tripStopStorage) {
        return includeUnplannedStorages || (Number(tripStopStorage.get('plannedQty')) > 0 || Number(tripStopStorage.get('confirmedQty')) > 0 || Number(tripStopStorage.get('orderQty')) > 0 && tripStop.get('isCreatedFromOrder')) && storageGroupID === tripStopStorage.get('storage.StorageGroupID');
      });
    }
  });
});