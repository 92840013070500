define('bottomline-execution/components/truck-storage-end-inventory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    classNameBindings: ['isSelectedMaterial'],
    classNames: 'storage-compartment',
    tagName: 'td',
    tripStopStorage: null,
    truckSettings: null,
    isReadonly: true,
    confirmedQtyChanged: Ember.observer('tripStopTruckStorage.confirmedQty', function () {
      this.updateCorrectionStop();
      this.totalizeCompartments();
    }),
    isSelectedMaterial: Ember.computed('tripStopStorage', 'tripStopTruckStorage', function () {
      return Number(this.get('tripStopStorage.MaterialID')) === Number(this.get('truckStorage.material_id'));
    }),
    readOnly: Ember.computed('isSelectedMaterial', function () {
      return !this.get('isSelectedMaterial');
    }),
    allTripStopTruckStorages: Ember.computed('trip-stop.TripID', function () {
      var trip = this.get('store').peekRecord('trip', this.get('trip-stop.TripID'));
      return trip.get('trip_stops').map(function (ts) {
        return ts.get('trip_stop_truck_storages').toArray();
      }).flatten().filterBy('TruckStorageID', this.get('truckStorage.TruckStorageID'));
    }),
    tripStopTruckStorages: Ember.computed('trip-stop', function () {
      var _this = this;

      return this.get('trip-stop.trip_stop_truck_storages').filter(Ember.run.bind(this, function (tsts) {
        return Number(tsts.get('TruckStorageID')) === Number(_this.get('truckStorage.TruckStorageID')) && Number(tsts.get('StorageID')) !== Number(_this.get('tripStopStorage.StorageID')) && Number(tsts.get('confirmedQty')) !== 0 && tsts.get('TruckStorageID') > 0;
      }));
    }),

    tripStopTruckStorage: Ember.computed('tripStopStorage', 'tripStopTruckStorage', 'truckStorage', function () {
      var _this2 = this;

      var item = this.get('trip-stop.trip_stop_truck_storages').find(Ember.run.bind(this, function (tsts) {
        return Number(tsts.get('TruckStorageID')) === Number(_this2.get('truckStorage.truck_storage_id')) && tsts.get('MaterialID') === _this2.get('truckStorage.material_id');
      }));

      if (Ember.isEmpty(item)) {
        item = this.get('trip-stop.trip_stop_truck_storages').createRecord({
          id: this.get('trip-stop').createGuid('from truck storage end inventory using', this.get('truckStorage')),
          plannedQty: 0,
          TripStopID: this.get('trip-stop.id'),
          TripID: this.get('trip-stop.TripID'),
          TruckStorageID: this.get('truckStorage.truck_storage_id'),
          StorageID: this.get('tripStopStorage.StorageID'),
          TruckID: this.get('truckStorage.truck_id'),
          MaterialID: this.get('truckStorage.material_id'),
          ActivityCode: this.get('trip-stop.ActivityCode'),
          confirmedQty: this.get('truckStorage.currentQty')
        });
      }

      item.setProperties({
        MaterialID: this.get('truckStorage.material_id')
      });

      return item;
    }),
    totalConfirmedQtyBeforeEndStop: Ember.computed(function () {
      var tripStopTruckStoragesBeforeEndStop = this.get('allTripStopTruckStorages').rejectBy('id', this.get('tripStopTruckStorage').id);

      return tripStopTruckStoragesBeforeEndStop.reduce(function (qty, item) {
        if (item.get('trip_stop.isCorrection')) {
          return qty;
        } else if (item.get('trip_stop.isUnload')) {
          return qty + -1 * item.get('confirmedQty');
        } else {
          return qty + 1 * item.get('confirmedQty');
        }
      }, 0);
    }),

    correctionStop: Ember.computed('truckStorage.truck_storage_id', function () {
      return this.get('trip-stop.trip').correctionStop();
    }),

    updateCorrectionStop: function updateCorrectionStop() {
      var _this3 = this;

      var tripStopTruckStorage = this.get('correctionStop.trip_stop_truck_storages').find(function (tsts) {
        return Number(tsts.get('TruckStorageID')) === Number(_this3.get('truckStorage.truck_storage_id'));
      });

      if (Ember.isEmpty(tripStopTruckStorage)) {
        tripStopTruckStorage = this.get('correctionStop.trip_stop_truck_storages').createRecord({
          id: this.get('trip-stop').createGuid('from truck storage end inventory for correction stop'),
          plannedQty: 0,
          confirmedQty: 0,
          TripStopID: this.get('correctionStop').id,
          TripID: this.get('trip-stop.TripID'),
          ActivityCode: this.get('trip-stop.ActivityCode'),
          TruckID: this.get('truckStorage.truck_id'),
          TruckStorageID: this.get('truckStorage.truck_storage_id'),
          MaterialID: this.get('truckStorage.material_id'),
          StorageID: this.get('tripStopStorage.StorageID')
        });
      }

      tripStopTruckStorage.setProperties({
        confirmedQty: -1 * (this.get('totalConfirmedQtyBeforeEndStop') - Number(this.get('tripStopTruckStorage.confirmedQty'))),
        StorageID: this.get('tripStopStorage.StorageID')
      });
    },
    totalizeCompartments: function totalizeCompartments() {
      var _this4 = this;

      var total = this.get('trip-stop').tripStopTruckStorageTotalByMaterial(this.get('truckStorage.material_id'));
      var tripStopStorage = this.get('trip-stop.tripStopStorages').find(function (tripStopStorage) {
        return _this4.get('truckStorage.material_id') === tripStopStorage.get('MaterialID');
      });

      if (tripStopStorage) {
        tripStopStorage.set('confirmedQty', total);
      }
    },


    actions: {
      toggleLock: function toggleLock() {
        this.toggleProperty('isReadonly');
      }
    }
  });
});