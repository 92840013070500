define('bottomline-execution/mixins/wait-app-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    appConfiguration: Ember.inject.service(),

    waitForAppState: function waitForAppState() {
      return this.get('appConfiguration.findState');
    }
  });
});