define('bottomline-execution/serializers/bx/trip-stop-storage', ['exports', 'bottomline-execution/serializers/trip-stop-storage'], function (exports, _tripStopStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tripStopStorage.default.extend({
    tripStopJson: {},
    isLoadStop: Ember.computed.equal('tripStopJson.type', 'load'),
    actionFilterKey: Ember.computed('isLoadStop', function () {
      return this.get('isLoadStop') ? 'product_id' : 'site_storage_id';
    }),
    storageFilterKey: Ember.computed('isLoadStop', function () {
      return this.get('isLoadStop') ? 'MaterialID' : 'id';
    }),
    plannedActions: [],
    executedActions: [],

    getTripStopStorages: function getTripStopStorages(tripStopJson, plannedActions, executedActions) {
      var _this = this;

      this.set('tripStopJson', tripStopJson);
      this.set('plannedActions', plannedActions);
      this.set('executedActions', executedActions);

      var storages = this.get('store').peekAll('storage').filterBy('SiteID', tripStopJson.location.id);

      var tripStopStorages = storages.map(function (storage) {
        var planned = _this._filterActions('plannedActions', storage);
        var executed = _this._filterActions('executedActions', storage);

        return {
          id: tripStopJson.id + '_' + storage.id,
          type: 'trip-stop-storage',
          attributes: {
            confirmedQty: _this._calculateTotal(executed, 'volume_in_ambient_liters'),
            confirmed15Qty: _this._calculateTotal(executed, 'volume_in_standard_liters'),
            Density: _this._calculateTotal(executed, 'density_in_kilograms_per_cubic_meter'),
            MaterialID: storage.MaterialID,
            plannedQty: _this._calculateTotal(planned, 'volume_in_ambient_liters'),
            StorageID: storage.id,
            Temperature: _this._calculateTotal(executed, 'temperature_in_celsius') || undefined,
            TripStopID: tripStopJson.id
          },
          relationships: {
            storage: {
              data: { type: 'storage', id: storage.id }
            }
          }
        };
      });

      return { data: tripStopStorages };
    },
    _filterActions: function _filterActions(actionsType, storage) {
      var targetValue = storage.get(this.get('storageFilterKey'));
      return this.get(actionsType).filterBy(this.get('actionFilterKey'), targetValue);
    },
    _calculateTotal: function _calculateTotal(actions, attribute) {
      if (Ember.isEmpty(actions)) return null;

      return actions.reduce(function (sum, action) {
        return sum + Number(action[attribute]);
      }, 0);
    }
  });
});