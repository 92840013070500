define('bottomline-execution/serializers/bx/truck-storage', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeTruckStorages: function normalizeTruckStorages(vehicleType, compartmentsJson) {
      var mappedCompartments = compartmentsJson.map(function (json) {
        return {
          id: json.vehicle_id + '_' + json.number,
          type: 'truck-storage',
          attributes: {
            maxTruckStorageQty: json.maximum_quantity,
            truck_id: json.vehicle_id,
            TruckStorageID: json.number,
            vehicle_type: vehicleType
          }
        };
      });

      return { data: mappedCompartments };
    }
  });
});