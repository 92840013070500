define('bottomline-execution/services/truck-settings-ajax', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    ajax: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    loadSettings: function loadSettings(truckId) {
      var _this = this;

      if (Ember.isEmpty(truckId)) return Promise.resolve();

      return this.get('ajax').request(this._settingsUrl(truckId)).then(function (data) {
        var settingsId = [truckId, 'settings'].join('');
        var currentSettings = _this.get('store').peekRecord('truck-setting', settingsId) || _this.get('store').createRecord('truck-setting', { id: settingsId });

        currentSettings.setProperties(data);
        currentSettings.save();

        return currentSettings;
      }).catch(function (e) {
        console.error('Error on endpoint, configuration not loaded', e);
      });
    },
    _settingsUrl: function _settingsUrl(truckId) {
      if (this.get('appState.useBeBackend')) return '/bottomline_execution_configuration?truck_id=' + truckId;

      // BP-7011: The truck may be part of more than one compound vehicle, this is currently not supported by BE
      var compoundVehicleId = this.get('store').peekAll('compound-vehicle').findBy('TruckID', truckId).get('id');
      return '/truck/v2/execution/trucks/' + compoundVehicleId + '/settings';
    }
  });
});