define('bottomline-execution/components/questionable-item', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    appConfiguration: Ember.inject.service(),
    tagName: '',
    questionListId: null,
    state: null,
    afterAction: '',
    item: null,
    disabled: false,
    showQuestions: Ember.computed.alias('appConfiguration.truckSettings.questionaire'),
    hasQuestionnaire: Ember.computed('state', 'item.{question_list_before,question_list_after}', function () {
      var showQuestions = this.get('showQuestions');
      var question_list_after = this.get('item.question_list_after');
      var question_list_before = this.get('item.question_list_before');
      var action = this.get('action'); // Can be start or finish

      if (showQuestions) {
        if (action === 'start' && Ember.isPresent(question_list_before)) {
          this.set('questionListId', question_list_before);

          return true;
        }

        if (action === 'finish' && Ember.isPresent(question_list_after)) {
          this.set('questionListId', question_list_after);

          return true;
        }
      }

      return false;
    }),
    actions: {
      openQuestionnaire: function openQuestionnaire() {
        this.set('questionnaireModal', true);
      },
      cancelQuestionnaire: function cancelQuestionnaire() {
        this.set('questionnaireModal', false);
      }
    }
  });
});