define("bottomline-execution/utils/simple-template-util", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function simpleTemplateUtil(template, variables) {
    return template.replace(/%{(.*?)}/g, function (match, key) {
      return variables[key];
    });
  }

  exports.simpleTemplateUtil = simpleTemplateUtil;
});