define('bottomline-execution/services/offline-syncer', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    ajax: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    session: Ember.inject.service(),
    pictureSaver: Ember.inject.service(),
    isRunning: false,
    isServiceFactory: true,
    tripSaveScheduled: false,

    init: function init() {
      this.set('tripSaveScheduled', false);
      this.startTrackingUnsavedRecords();
    },
    startTrackingUnsavedRecords: function startTrackingUnsavedRecords() {
      var _this = this;

      Ember.run.later(function () {
        _this.syncAll().then(function () {
          if (!Ember.testing) {
            _this.startTrackingUnsavedRecords();
          }
        });
      }, Ember.testing ? 1 : 60000);
    },
    syncAll: function syncAll() {
      return Ember.RSVP.all([this.syncPictures(), this.syncTruckPositions()]);
    },
    syncPictures: function syncPictures() {
      if (!this.get('session.isAuthenticated') || !this.get('internetConnection.online')) {
        return;
      }
      return this.get('pictureSaver').syncUnsavedPictures();
    },
    syncTruckPositions: function syncTruckPositions() {
      var _this2 = this;

      if (!this.get('session.isAuthenticated') || !this.get('internetConnection.online')) {
        return;
      }

      if (Ember.isPresent(this.get('store'))) {
        // forcing id, make sure ember sees it as persisted record
        this.get('store').peekAll('truck-position').filterBy('isLoaded', false).setEach('id', (0, _moment.default)().unix());

        var truckPositions = this.get('store').peekAll('truck-position').filterBy('isNew', true);
        Ember.RSVP.all(truckPositions.map(function (t) {
          return t.save();
        })).catch(function (_error) {
          console.error('Error saving truck position(s)');
        }).finally(function () {
          _this2.get('store').peekAll('truck-position').filterBy('isNew', false).invoke('deleteRecord');
          _this2.get('store').peekAll('truck-position').filterBy('isNew', false).invoke('unloadRecord');
        });
      }
    }
  });
});