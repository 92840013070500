define('bottomline-execution/routes/trip-stops/show/scan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.set('model', this.modelFor('trip-stops.show'));
      controller.send('scan');
    }
  });
});