define('bottomline-execution/components/add-language', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    value: '',
    actions: {
      add: function add() {
        var _this = this;

        if (this.get('languages').indexOf(this.get('value')) >= 0 || Ember.isEmpty(this.get('value'))) {
          return;
        }

        return Ember.$.post(_environment.default.i18n.endpoint, {
          namespace: 'deliveryplus',
          locale: this.get('value'),
          options: { description_language: this.get('description') }
        }, 'json').then(function () {
          _this.get('languages').pushObject({
            id: _this.get('value'), title: _this.get('description') || _this.get('value')
          });
          _this.get('i18n').addTranslations(_this.get('value'), {});
          _this.set('value', '');
          _this.set('description', '');
        });
      }
    }
  });
});