define('bottomline-execution/models/bx/customer', ['exports', 'bottomline-execution/models/customer', 'ember-data'], function (exports, _customer, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _customer.default.extend({
    type: attr('string'),

    generateDeliveryTicket: Ember.computed.or('_deliveryTicketCustomer', '_unrestrictedDeliveryTickets'),

    useFlowMeter: Ember.computed('truckSettings.customer_type_with_flow_meter', 'type', function () {
      return this._caseInsensitiveCompare(this.get('truckSettings.customer_type_with_flow_meter'), this.get('type'));
    }),

    canAddStopFromOrder: Ember.computed.or('_addStopFromOrderCustomer', '_unrestrictedAddStopFromOrder'),

    _deliveryTicketCustomer: Ember.computed('truckSettings.restrict_delivery_tickets_for_customer_type', 'type', function () {
      return this._caseInsensitiveCompare(this.get('truckSettings.restrict_delivery_tickets_for_customer_type'), this.get('type'));
    }),

    _addStopFromOrderCustomer: Ember.computed('truckSettings.customer_type_to_filter_orders', 'type', function () {
      return this._caseInsensitiveCompare(this.get('truckSettings.customer_type_to_filter_orders'), this.get('type'));
    }),

    _caseInsensitiveCompare: function _caseInsensitiveCompare(a, b) {
      /*
        The truck setting, passed-in as either the first or the second parameter,
        may be a boolean. In this case, return false to (a) leave the eventual
        feature setting to the _unrestricted<feature> CPs/default behavior, and
        (b) facilitate faster recognition (due to missing functionality) that the
        setting is incorrect.
      */
      if (typeof a !== 'string' || typeof b !== 'string') return false;
      return a.toUpperCase() === b.toUpperCase();
    }
  });
});