define('bottomline-execution/components/app-events', ['exports', 'ember-cli-framework7/mixins/application-view'], function (exports, _applicationView) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_applicationView.default, {
    navigate: "",
    didInsertElement: function didInsertElement() {
      this._super();

      document.addEventListener('deviceready', this.onDeviceReady.bind(this), false);

      Ember.$(document).delegate('button', 'a', 'click', function (ev) {
        var $el = Ember.$(ev.currentTarget);
        $el.prop('disabled', true);
        $el.addClass('disabled-link');
        $el.parent().addClass('disabled');
        setTimeout(function () {
          $el.prop('disabled', false);
          $el.removeClass('disabled-link');
          $el.parent().removedClass('disabled');
        }, 10000);
      });

      if (document.body.offsetWidth < 800) {
        Ember.$('html').css('zoom', '0.7');
      }
    },
    onKeyboardDidShow: function onKeyboardDidShow() {
      Ember.$('.navbar').hide();
    },
    onKeyboardDidHide: function onKeyboardDidHide() {
      Ember.$('.navbar').show();
    },
    onDeviceReady: function onDeviceReady() {
      var _this = this;

      document.addEventListener('native.keyboardhide', this.onKeyboardDidHide);
      document.addEventListener('native.keyboardshow', this.onKeyboardDidShow);
      document.addEventListener('backbutton', function (e) {
        var application = Ember.getOwner(_this).lookup('controller:application');
        var currentPath = application.currentPath;

        e.preventDefault();
        _this.get('navigate')(currentPath);
      }, false);
    }
  });
});