define('bottomline-execution/authorizers/devise', ['exports', 'ember-simple-auth/authorizers/devise', 'bottomline-execution/config/environment'], function (exports, _devise, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend({
    identificationAttributeName: _environment.default.devise.identificationAttributeName,
    tokenAttributeName: _environment.default.devise.tokenAttributeName
  });
});