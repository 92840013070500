define('bottomline-execution/custom-exceptions/meter-exceptions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function MeterException() {
    var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'MeterException';

    this.name = name;
  }

  exports.MeterException = MeterException;
});