define('bottomline-execution/components/location-confirmer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Component.extend({
    locationService: Ember.inject.service('location'),
    assetMap: Ember.inject.service('asset-map'),
    i18n: Ember.inject.service(),

    leafletMapContainer: null,
    currentLocationMarker: null,
    savedLocationMarker: null,
    newLatitude: null,
    newLongitude: null,
    updateLocation: '',
    cancel: '',

    leafletMap: function leafletMap() {
      var leafletMap = null;
      if (!Ember.isPresent(this.get('leafletMapContainer'))) {
        leafletMap = new L.Map('leafletmap', {
          scrollWheelZoom: this.get('mapScrollable'),
          center: new L.LatLng(this.get('locationService.lat'), this.get('locationService.lng')),
          zoom: 30,
          layers: new L.TileLayer('https://b.tile.openstreetmap.org/{z}/{x}/{y}.png')
        });
        leafletMap.addControl(new L.Control.Fullscreen());
        this.set('leafletMapContainer', leafletMap);
      } else {
        leafletMap = this.get('leafletMapContainer');
      }

      return leafletMap;
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.initMarkers();
      var leafletMap = this.leafletMap();
      this.fitMap(leafletMap);

      leafletMap.on("move", function () {
        var marker = _this.getCurrentLocationMarker(leafletMap);
        marker.setLatLng(leafletMap.getCenter());
        leafletMap.on("dragend", function () {
          _this.updateNewLatLng(leafletMap, leafletMap.getCenter());
        });
      });
    },
    getLatLng: function getLatLng() {
      return [this.get('locationService.lat'), this.get('locationService.lng')];
    },
    getSavedLatLng: function getSavedLatLng() {
      return [this.get('model.latitude'), this.get('model.longitude')];
    },
    getNewLatLng: function getNewLatLng() {
      return [this.get('newLatitude'), this.get('newLongitude')];
    },
    updateNewLatLng: function updateNewLatLng(leafletMap, _ref) {
      var lat = _ref.lat,
          lng = _ref.lng;

      this.setProperties({
        newLatitude: lat,
        newLongitude: lng
      });
    },
    getCurrentLocationMarker: function getCurrentLocationMarker(leafletMap) {
      var marker = this.get('currentLocationMarker');
      if (!Ember.isPresent(marker)) {
        marker = L.marker(this.getLatLng(), { opacity: 0.4 }).bindTooltip('<p>' + this.get('i18n').t('new_location') + '</p>', { permanent: true });
        this.set('currentLocationMarker', marker);
      } else {
        this.set('currentLocationMarker', marker.setLatLng(this.getLatLng()));
      }

      marker.addTo(leafletMap).update();
      return marker;
    },
    getSavedLocationMarker: function getSavedLocationMarker(leafletMap) {
      if (Ember.isPresent(this.get('model.latitude'))) {

        var marker = this.get('savedLocationMarker');
        if (!Ember.isPresent(marker)) {
          marker = L.marker(this.getSavedLatLng()).bindTooltip('<p>' + this.get('model.site.Name') + '</p>', { permanent: true });

          this.set('savedLocationMarker', marker);
        } else {
          this.set('savedLocationMarker', marker.setLatLng(this.getSavedLatLng()));
        }

        marker.addTo(leafletMap).update();
        return marker;
      } else {
        return null;
      }
    },
    fitMap: function fitMap(leafletMap) {
      if (this.get('locationService.lat') && this.get('model.latitude') && this.get('newLatitude')) {
        var newLatLng = this.getNewLatLng();
        var center = L.latLng(newLatLng);
        var leafletBounds = L.latLngBounds([this.getSavedLatLng(), newLatLng]);
        var northEast = leafletBounds.getNorthEast();
        var southWest = leafletBounds.getSouthWest();
        var northEastSymmetric = [northEast.lat + (center.lat - northEast.lat) * 2, northEast.lng + (center.lng - northEast.lng) * 2];
        var southWestSymmetric = [southWest.lat + (center.lat - southWest.lat) * 2, southWest.lng + (center.lng - southWest.lng) * 2];
        leafletBounds.extend(L.latLngBounds(southWestSymmetric, northEastSymmetric));
        leafletMap.fitBounds(leafletBounds);
      } else if (this.get('locationService.lat') && this.get('model.latitude')) {
        leafletMap.fitBounds([this.getLatLng(), this.getSavedLatLng()]);
      } else {
        leafletMap.fitBounds([this.getLatLng()]);
      }
    },
    initMarkers: function initMarkers() {
      var leafletMap = this.leafletMap();
      this.getCurrentLocationMarker(leafletMap);
      this.getSavedLocationMarker(leafletMap);

      if (!Ember.isPresent(this.get('newLatitude'))) {
        var _getLatLng = this.getLatLng(),
            _getLatLng2 = _slicedToArray(_getLatLng, 2),
            lat = _getLatLng2[0],
            lng = _getLatLng2[1];

        this.set('newLatitude', lat);
        this.set('newLongitude', lng);
      }
    },


    currentLocationObserver: Ember.observer('locationService.lat', function () {
      this.initMarkers();
      this.fitMap(this.leafletMap());
    }),

    actions: {
      cancel: function cancel() {
        this.get('cancel')();
      },
      confirm: function confirm() {
        var _this2 = this;

        this.get('updateLocation')(this.get('model'), this.get('newLatitude'), this.get('newLongitude')).then(function () {
          _this2.get('toast').info(_this2.get('i18n').t('location_confirmer.success'));
          _this2.get('cancel')();
        }).catch(function () {
          _this2.get('toast').error(_this2.get('i18n').t('location_confirmer.error'));
          _this2.get('cancel')();
        });
      }
    }
  });
});