define('bottomline-execution/models/base', ['exports', 'ember-data', 'bottomline-execution/utils/guid-util'], function (exports, _emberData, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model;
  exports.default = Model.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    isVolatileRemoveableRecord: Ember.computed('isLocalRecord', 'id', function () {
      return this.get('isLocalRecord') || this.get('id') === null;
    }),

    isLocalRecord: Ember.computed('id', function () {
      return _guidUtil.guidRegex.test(this.get('id'));
    })
  });
});