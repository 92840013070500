define('bottomline-execution/models/compound-vehicle', ['exports', 'ember-data/model', 'ember-data'], function (exports, _model, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _model.default.extend({
    TruckID: attr('string'),
    TrailerID: attr('string') // BP-7011: The truck may have multiple trailers, this is currently not supported by BE
  });
});