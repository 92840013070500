define('bottomline-execution/components/f7-page-content/f7-pull-to-refresh', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    setupPullToRefresh: function () {
      var _this = this,
          action = this.get('onPullToRefresh');
      if (action) {
        this.$().addClass('pull-to-refresh-content');
        this.set('hasPullToRefresh', true);
        this.get('f7').initPullToRefresh(this.$());
        this.$().on('refresh', function () {
          var deferred = Ember.RSVP.defer();
          deferred.promise.finally(function () {
            _this.get('f7').pullToRefreshDone();
          });
          _this.sendAction('onPullToRefresh', deferred);
        });
      }
    }.on('didInsertElement')
  });
});