define('bottomline-execution/services/android-intents-atx', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    START: 1,
    STOP: 2,

    stop: function stop(activityCode) {
      var description = this._toDescription(activityCode) + ' gestopt';
      var kind = this.get('STOP');

      this._fireIntent(description, kind);
    },
    start: function start(activityCode) {
      var description = this._toDescription(activityCode) + ' gestart';
      var kind = this.get('START');

      this._fireIntent(description, kind);
    },
    _fireIntent: function _fireIntent(description, kind) {
      window.plugins.webintent.startActivity({
        address: 'com.groeneveldictsolutions.android.atxapi',
        action: 'com.groeneveldictsolutions.android.atxapi.ACTION_REGISTER_ACTIVITY',
        extras: {
          EXTRA_SOURCE: 'BottomLineExecution',
          EXTRA_DESCRIPTION: description,
          EXTRA_TIMESTAMP: Date.now(),
          EXTRA_TYPE: kind
        }
      }, function (e) {
        console.log('Intent succeeded: ' + e + ', ' + kind + ' ' + description);
      }, function (e) {
        console.log('Intent failed: ' + e + ', ' + kind + ' ' + description);
      });
    },
    _toDescription: function _toDescription(activityCode) {
      var description = void 0;

      switch (activityCode) {
        case 'L':
          description = 'loading';
          break;
        case 'U':
          description = 'unloading';
          break;
        case 'E':
          description = 'left on vehicle';
          break;
        default:
          description = 'undefined ActivityCode \'' + activityCode + '\'';
      }

      return description;
    }
  });
});