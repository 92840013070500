define('bottomline-execution/helpers/safe-css', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.safeCss = safeCss;
  var htmlSafe = Ember.String.htmlSafe;
  function safeCss(params, _ref) {
    var format = _ref.format;

    var processedParams = params.map(function (item) {
      return item || '';
    });
    var html = Ember.String.loc(format, processedParams);

    return htmlSafe(html);
  }

  exports.default = Ember.Helper.helper(safeCss);
});