define('bottomline-execution/initializers/framework7-service', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(application) {
    application.inject('route', 'f7', 'service:framework7');
    application.inject('controller', 'f7', 'service:framework7');
    application.inject('component', 'f7', 'service:framework7');
    application.inject('view', 'f7', 'service:framework7');
  }

  exports.default = {
    name: 'framework7-service',
    initialize: initialize
  };
});