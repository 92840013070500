define('bottomline-execution/components/material-on-truck', ['exports', 'bottomline-execution/mixins/trip-stop-material-on-truck'], function (exports, _tripStopMaterialOnTruck) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Ember.Component.extend(_tripStopMaterialOnTruck.default, {
    scopedData: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),

    materialStyle: Ember.computed('inventory.material', 'inventory', function () {
      return new htmlSafe('color: ' + this.get('inventory.material.foreground_color') + '; ' + ('background-color: ' + this.get('inventory.material.background_color') + ';'));
    }),

    truckInventoryWithTripStopNeed: Ember.computed('truckInventory', 'selectedTripStop', function () {
      return this.tripStopMaterialOnTruck({ tripStop: this.get('selectedTripStop') }).sortBy('material.id');
    })
  });
});