define('bottomline-execution/models/bx/trip-stop-storage', ['exports', 'bottomline-execution/models/trip-stop-storage', 'bottomline-execution/mixins/trip-stop-type'], function (exports, _tripStopStorage, _tripStopType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tripStopStorage.default.extend(_tripStopType.default, {

    trip_stop: Ember.computed('TripStopID', function () {
      if (this.get('TripStopID')) {
        return this.store.peekRecord(this.tripStopType(), this.get('TripStopID'));
      }
    })
  });
});