define('bottomline-execution/initializers/remote-logger-service', ['exports', 'ember-cli-remote-logger/initializers/remote-logger-service'], function (exports, _remoteLoggerService) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _remoteLoggerService.default;
    }
  });
  Object.defineProperty(exports, 'initialize', {
    enumerable: true,
    get: function () {
      return _remoteLoggerService.initialize;
    }
  });
});