define('bottomline-execution/components/end-inventory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    model: null,
    state: null,
    tripStopStorage: null,

    showMaterials: true,
    showTruckDetails: true,
    showTrailerDetails: true,

    init: function init() {
      this._super.apply(this, arguments);

      this.set('showMaterials', !this.get('state.truckSettings.compartments'));
    }
  });
});