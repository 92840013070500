define('bottomline-execution/routes/trip-stops/change-terminal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    model: function model() {
      var currentTruck = this.get('appState.currentTruck');
      return this.store.query('terminal', { filter: { truck_id: currentTruck } });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('currentLoadStop', this.modelFor('trips.show').get('loadStop'));
    }
  });
});