define('bottomline-execution/components/bl-ocr-validator', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    userValue: null,
    scannerResponse: Ember.Object.create({}),
    scannerValue: Ember.computed.alias('scannerResponse.scannedValue'),
    value: Ember.computed('userValue', 'scannerValue', function () {
      return this.get('userValue') === this.get('scannerValue') ? this.get('scannerValue') : null;
    }),
    actions: {
      accept: function accept() {
        this.set('scannerResponse.isConfirmed', true);
        this.set('value', this.get('scannerValue'));
        this.set('userValue', this.get('scannerValue'));
      },
      overrule: function overrule() {
        this.set('value', this.get('userValue'));
        this.set('scannerResponse.isConfirmed', true);
        this.set('scannerResponse.isOverruled', true);
      }
    }
  });
});