define('bottomline-execution/utils/save-b64-to-file-util', ['exports', 'bottomline-execution/utils/data-uri-to-blob-util'], function (exports, _dataUriToBlobUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.saveB64ToFileUtil = undefined;


  // cordova.file.externalDataDirectory points to /storage/emulated/0/Android/data/eu.bottomline.Execution/files/
  function saveB64ToFileUtil(content, fileName) {
    var directoryPath = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : cordova.file.externalDataDirectory;

    var dataBlob = (0, _dataUriToBlobUtil.dataURItoBlobUtil)('data:image/jpeg;base64,' + content);

    return new Ember.RSVP.Promise(function (resolve, reject) {
      return window.resolveLocalFileSystemURL(directoryPath, function (directory) {
        directory.getFile(fileName, { create: true }, function (file) {
          file.createWriter(function (fileWriter) {
            fileWriter.onwriteend = function (_event) {
              resolve(fileName);
            };
            fileWriter.write(dataBlob);
          }, function () {
            reject(null);
          });
        });
      });
    });
  }

  exports.saveB64ToFileUtil = saveB64ToFileUtil;
});