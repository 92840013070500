define('bottomline-execution/router', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    this.route('shifts', { resetNamespace: true }, function () {
      this.route('adr-report', { path: ':shift_id/adr-report' });

      this.route('show', { path: ':shift_id' }, function () {
        this.route('trips', { resetNamespace: true }, function () {

          this.route('show', { path: ':trip_id' }, function () {

            this.route('trip-stops', { resetNamespace: true }, function () {
              this.route('new', function () {
                this.route('special-activity');
                this.route('from-site');
              });
              this.route('pok-delivery');
              this.route('change-terminal');

              this.route('material-transfer');

              this.route('show', { path: ':trip_stop_id' }, function () {
                this.route('scan');
                this.route('pictures', { resetNamespace: true });
                this.route('authorize-site');

                this.route('trip-stop-storages', { resetNamespace: true }, function () {
                  this.route('index', { path: ':trip_stop_storage_id' });
                });

                this.route('authorize-delivery');
              });
            });
          });
        });
      });
    });

    this.route('truck-settings');
    this.route('login');
    this.route('settings');
    this.route('edit-truck');

    this.route('loading');
    this.route('hidden-translations', {});
    this.route('dashboard', { path: '' });
    this.route('index', { path: 'index' });
    this.route('emis-debug', {});
    this.route('questions', { path: ':question_list_id' });
  });

  exports.default = Router;
});