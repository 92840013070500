define('bottomline-execution/utils/crypto-utils', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function checkEqualityWithVerificationHash(str) {
    if (!str) return false;

    var hash = CryptoJS.SHA256(str).toString();

    return _environment.default.adminUIPasswordHashes.includes(hash);
  }

  exports.default = {
    checkEqualityWithVerificationHash: checkEqualityWithVerificationHash
  };
});