define('bottomline-execution/components/trip-stop-storage-overview', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    compartments: Ember.computed.alias('truckSettings.compartments'),
    loadTicketnbPerTank: Ember.computed.alias('truckSettings.load_ticketnb_per_tank'),
    filteredTripStopStorages: Ember.computed('model.trip_stop_storages', 'parentController.showUnplannedTripStopStorages', function () {
      if (this.get('parentController.showUnplannedTripStopStorages')) {
        return this.get('model.trip_stop_storages');
      } else {
        return this.get('model.trip_stop_storages').filter(function (item) {
          return item.get('plannedQty') > 0;
        });
      }
    }),
    showTemperatureDensity15Liters: Ember.computed.alias('model.showTemperatureDensity15Liters')
  });
});