define('bottomline-execution/components/f7-checkbox-tag', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['option-group'],

    checkedChanged: Ember.computed('checked', function () {
      if (this.get('parentController')) {
        var selectedStorages = this.get('parentController.selectedSite.storages').filter(function (storage) {
          return storage.get('selected') === true;
        });

        this.set('parentController.selectedStorages', selectedStorages);
      }
    })
  });
});