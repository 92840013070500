define('bottomline-execution/adapters/be/base-adapter', ['exports', 'ember-simple-auth/mixins/data-adapter-mixin', 'active-model-adapter', 'bottomline-execution/config/environment'], function (exports, _dataAdapterMixin, _activeModelAdapter, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var BEAdapter = _activeModelAdapter.ActiveModelAdapter.extend(_dataAdapterMixin.default, {
    namespace: _environment.default.api,
    authorizer: 'authorizer:devise',
    requestHeaders: Ember.inject.service(),

    host: Ember.computed(function () {
      var domain = localStorage.state ? JSON.parse(localStorage.state).state.records[1].domain : null;
      return domain;
    }).volatile(),

    headers: Ember.computed('session.isAuthenticated', function () {
      var authorize = this.get('session.isAuthenticated');
      return this.get('requestHeaders').defaultHeaders({ authorize: authorize });
    }),

    ajaxOptions: function ajaxOptions(url, type, options) {
      var hash = this._super(url, type, options);
      hash.timeout = 200000;
      return hash;
    },
    shouldBackgroundReloadAll: function shouldBackgroundReloadAll() {
      return false;
    },
    shouldReloadAll: function shouldReloadAll() {
      return false;
    },
    shouldReloadRecord: function shouldReloadRecord() {
      return false;
    },
    shouldBackgroundReloadRecord: function shouldBackgroundReloadRecord() {
      return false;
    },
    handleResponse: function handleResponse(status, headers, payload) {
      if (status === 403) {
        if (localStorage['ember_simple_auth:session']) {
          localStorage.setItem('ember_simple_auth:session', null);
        }

        if (window.cordova) {
          document.location = 'index.html';
        } else {
          document.location = '/';
        }
      }

      return this._super(status, headers, payload);
    }
  });

  exports.default = BEAdapter;
});