define('bottomline-execution/serializers/bx/end-inventory', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    store: null,
    tripId: null,
    endInventoryStopId: Ember.computed('tripId', function () {
      return this.get('tripId') + ':E';
    }),

    build: function build(tripId, endInventory) {
      this.set('tripId', tripId);
      var endStopStorages = this._buildTripStopStorages(endInventory);

      this._unloadObsoleteTripStopStorages();

      // Analogous to createEndInventoryStop() in the trip model
      var endStop = {
        id: this.get('endInventoryStopId'),
        type: 'end-inventory',
        attributes: {
          ActivityCode: 'E',
          ConfirmedSequenceNb: 1000,
          SequenceNb: 1000,
          state: this._getState(endInventory),
          TripID: tripId
        },
        relationships: {
          'trip': {
            data: { type: 'trip', id: tripId }
          },
          'trip_stop_storages': {
            data: endStopStorages.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return { type: type, id: id };
            })
          }
        }
      };

      return { tripStop: endStop,
        tripStopStorages: { data: endStopStorages } };
    },
    _unloadObsoleteTripStopStorages: function _unloadObsoleteTripStopStorages() {
      this.get('store').peekAll('trip-stop-storage').filterBy('TripStopID', this.get('endInventoryStopId')).invoke('unloadRecord');
    },
    _buildTripStopStorages: function _buildTripStopStorages(endInventory) {
      var _this = this;

      var compartmentInventories = endInventory.getEach('compartment_inventories').flatten();

      var materialInventories = compartmentInventories.reduce(function (collector, inventory) {
        if (Ember.isEmpty(inventory.product_id)) return collector;

        if (Ember.isEmpty(collector[inventory.product_id])) collector[inventory.product_id] = 0;
        collector[inventory.product_id] += inventory.volume_in_liters;
        return collector;
      }, {});

      return Object.keys(materialInventories).map(function (product_id) {
        return {
          id: _this.get('endInventoryStopId') + '_' + product_id,
          type: 'trip-stop-storage',
          attributes: {
            confirmedQty: materialInventories[product_id],
            MaterialID: product_id,
            TripStopID: _this.get('endInventoryStopId')
          }
        };
      });
    },
    _getState: function _getState(endInventory) {
      if (Ember.isPresent(endInventory)) {
        return 'finished';
      } else {
        return 'planned';
      }
    },
    serialize: function serialize(snapshot) {
      var attrs = snapshot.attributes();

      return {
        kilometer_reading: attrs.Odometer,
        remarks: attrs.DriverRemarks,
        vehicle_inventories: this._mapInventories(snapshot)
      };
    },
    _mapInventories: function _mapInventories(snapshot) {
      var inventories = snapshot.belongsTo('trip').belongsTo('shift').hasMany('truck_inventories').map(function (inventory) {
        return inventory.attributes();
      });

      var vehicleIds = inventories.getEach('truck_id').uniq().compact();
      return vehicleIds.map(function (vehicleId) {
        var vehicleInventories = inventories.filterBy('truck_id', vehicleId);
        return {
          vehicle_id: vehicleId,
          compartment_inventories: vehicleInventories.map(function (inventory) {
            return {
              number: inventory.truck_storage_id.toString(),
              product_id: inventory.material_id,
              volume_in_liters: inventory.currentQty
            };
          })
        };
      });
    }
  });
});