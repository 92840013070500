define('bottomline-execution/controllers/questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    internetConnection: Ember.inject.service(),

    actions: {
      save: function save() {
        var _this = this;

        var data = this.get('answers').map(function (answer) {
          return _this.get('store').serialize(answer);
        });

        this.get('ajax').post('/answers', { data: JSON.stringify({ answers: data }) }).then(function () {
          if (_this.get('tripStop.showQuestionsOnStart')) {
            _this.transitionToRoute('trip-stops.show.index');
          } else {
            _this.transitionToRoute('trips');
          }
        }).catch(function (response) {
          _this.set('errors', response.errors);
        });
      }
    }
  });
});