define('bottomline-execution/components/trip-state-updater', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    action: null,
    isDisabled: false,
    allTrips: Ember.computed.alias('trip.shift.trips'),
    nextStates: Ember.computed.alias('trip.nextStates'),

    notifyAllTrips: function notifyAllTrips() {
      var _this = this;

      this.get('allTrips').reject(function (trip) {
        return trip === _this.get('trip');
      }).forEach(function (trip) {
        trip.notifyPropertyChange('state');
      });
    },
    showTrip: function showTrip(trip) {
      trip.set('isShown', true);
    },


    actions: {
      start: function start(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
        this.showTrip(model);
      },
      cancel: function cancel(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      },
      restart: function restart(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      },
      finish: function finish(state, model) {
        this.get('update-state')(state, model);
        this.notifyAllTrips();
      }
    }
  });
});