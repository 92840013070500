define('bottomline-execution/models/customer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model;
  exports.default = Model.extend({
    appConfiguration: Ember.inject.service(),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),

    _unrestrictedDeliveryTickets: Ember.computed.not('truckSettings.restrict_delivery_tickets_for_customer_type'),
    _unrestrictedAddStopFromOrder: Ember.computed.not('truckSettings.customer_type_to_filter_orders')
  });
});