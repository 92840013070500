define('bottomline-execution/models/bx/virtual-trip-stop', ['exports', 'bottomline-execution/models/trip-stop'], function (exports, _tripStop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tripStop.default.extend({
    cancel: function cancel() {
      this._super.apply(this, arguments);
      if (this.get('state') !== 'finished') {
        this.set('state', 'planned');
      }
    }
  });
});