define('bottomline-execution/services/scoped-data', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    truckId: Ember.computed.alias('appState.currentTruck'),
    trailerId: Ember.computed.alias('appState.currentTrailer'),

    vehicleInventoryByCompartment: function vehicleInventoryByCompartment(vehicleInventories) {
      var vehicleIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [this.get('truckId'), this.get('trailerId')];
      var sortField = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'truck_storage_id';

      var inventories = vehicleInventories.filter(function (e) {
        return Number(e.get('truck_storage_id')) > 0 && vehicleIds.indexOf(e.get('truck_id')) > -1;
      });

      return sortField ? inventories.sortBy(sortField) : inventories;
    },
    truckInventoryByMaterial: function truckInventoryByMaterial(vehicleInventories) {
      var vehicleIds = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [this.get('truckId'), this.get('trailerId')];

      return vehicleInventories.filter(function (e) {
        return Number(e.get('truck_storage_id')) == 0 && vehicleIds.indexOf(e.get('truck_id')) > -1;
      });
    }
  });
});