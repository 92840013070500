define('bottomline-execution/utils/timeout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function timeout(ms) {
    return new Ember.RSVP.Promise(function (resolve) {
      return Ember.run.later(function () {
        return resolve();
      }, ms);
    });
  }

  exports.timeout = timeout;
});