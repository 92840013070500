define('bottomline-execution/helpers/round', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.round = round;
  function round(number) {
    return Math.round(number);
  }

  exports.default = Ember.Helper.helper(round);
});