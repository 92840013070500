define('bottomline-execution/helpers/material-load-horizontal-bar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  exports.default = Ember.Helper.helper(function (params, _ref) {
    var color = _ref.color,
        currentQty = _ref.currentQty,
        maxQty = _ref.maxQty;

    var targetColor = color || '#00d190';
    var targetMaxQty = Number(maxQty);

    if (currentQty) {
      return htmlSafe('background: ' + targetColor + ';height: 20px;' + ('width: ' + getPercentage(currentQty, targetMaxQty) + '%; position: absolute; bottom: 0px;transition: width 5s'));
    }
  });


  function getPercentage(currentQty, maxQty) {
    if (maxQty) {
      return (currentQty / maxQty * 100).toFixed(2);
    }

    return '100';
  }
});