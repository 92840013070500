define('bottomline-execution/services/remote-logger', ['exports', 'ember-cli-remote-logger/services/remote-logger'], function (exports, _remoteLogger) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _remoteLogger.default;
    }
  });
});