define('bottomline-execution/models/trailer', ['exports', 'ember-data/model', 'ember-data', 'bottomline-execution/mixins/vehicle-identifier'], function (exports, _model, _emberData, _vehicleIdentifier) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      hasMany = _emberData.default.hasMany;
  exports.default = _model.default.extend(_vehicleIdentifier.default, {
    TruckID: attr('string'),
    License: attr('string'),
    MeterTypeID: attr('string'),
    VehicleType: attr('number'),
    truck_inventories: hasMany('truck-inventory')
  });
});