define('bottomline-execution/serializers/bx/storage', ['exports', 'bottomline-execution/serializers/storage'], function (exports, _storage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _storage.default.extend({
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var mappedStorages = payload.map(function (json) {
        return {
          id: json.id,
          type: 'storage',
          attributes: {
            MaterialID: _this._getMaterialId(json),
            SequenceNb: json.number,
            SiteID: _this._getLocationId(json),
            usableStorageQty: _this._usableStorageQty(json)
          }
        };
      });

      return { data: mappedStorages };
    },
    _getMaterialId: function _getMaterialId(storageJson) {
      return storageJson.target_product_id || storageJson.product_id;
    },
    _getLocationId: function _getLocationId(storageJson) {
      return storageJson.site_id || storageJson.depot_id;
    },
    _usableStorageQty: function _usableStorageQty(storageJson) {
      return storageJson.usable && storageJson.usable.quantity * 1000;
    }
  });
});