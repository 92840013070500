define('bottomline-execution/models/bx/trip-stop-truck-storage', ['exports', 'bottomline-execution/models/trip-stop-truck-storage', 'ember-cp-validations', 'bottomline-execution/mixins/trip-stop-type'], function (exports, _tripStopTruckStorage, _emberCpValidations, _tripStopType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Validations = (0, _emberCpValidations.buildValidations)({
    MaterialID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    StorageID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    TruckID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    TruckStorageID: [(0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    Volume: {
      validators: [(0, _emberCpValidations.validator)(function (value, options, model) {
        return Ember.isPresent(model.get('confirmedQty')) || Ember.isPresent(model.get('confirmed15Qty'));
      }, {
        dependentKeys: ['model.confirmedQty', 'model.confirmed15Qty']
      })]
    }
  });

  exports.default = _tripStopTruckStorage.default.extend(Validations, _tripStopType.default, {

    trip_stop: Ember.computed('TripStopID', function () {
      if (this.get('TripStopID')) {
        return this.store.peekRecord(this.tripStopType(), this.get('TripStopID'));
      }
    }),

    linkTripStopStorage: function linkTripStopStorage(tripStopStorage) {
      this.setProperties({
        StorageID: tripStopStorage.get('StorageID'),
        TripStopStorageID: tripStopStorage.get('id')
      });

      if (this.get('isLoad')) {
        this.set('MaterialID', tripStopStorage.get('MaterialID'));
      }
    }
  });
});