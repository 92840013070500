define('bottomline-execution/models/storage-material', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    StorageID: attr('number'),
    MaterialID: attr('string'),
    SiteID: attr('string'),
    ToDate: attr('date'),

    storage: belongsTo('storage'),

    material: Ember.computed('MaterialID', 'isLoaded', function () {
      if (this.get('MaterialID')) {
        return this.store.peekRecord('material', this.get('MaterialID'));
      }
    })
  });
});