define('bottomline-execution/mixins/session-management', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    truckSelector: Ember.inject.service(),

    invalidate: function invalidate() {
      var _this = this;

      return this.get('truckSelector').resetCurrentTruck().then(function () {
        return _this._super();
      });
    }
  });
});