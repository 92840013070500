define('bottomline-execution/utils/objects-deep-merge-util', ['exports', '@sentry/browser'], function (exports, _browser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = deepMerge;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function isObject(item) {
    return item && (typeof item === 'undefined' ? 'undefined' : _typeof(item)) === 'object' && !Array.isArray(item);
  }

  function deepMerge(target, source) {
    var output = Object.assign({}, target);
    if (isObject(target) && isObject(source)) {
      Object.keys(source).forEach(function (key) {
        if (isObject(source[key])) {
          if (!(key in target)) Object.assign(output, _defineProperty({}, key, source[key]));else output[key] = deepMerge(target[key], source[key]);
        } else {
          Object.assign(output, _defineProperty({}, key, source[key]));
        }
      });
    } else {
      _browser.captureMessage({ function: 'deepMerge', arguments: arguments });
    }
    return output;
  }
});