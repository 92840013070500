define('bottomline-execution/components/new-stop-map-osm', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    locationService: Ember.inject.service('location'),
    assetMap: Ember.inject.service('asset-map'),

    points: [],
    currentLocation: null,
    mapScrollable: true,
    zoom: 15,
    markersChanged: Ember.observer('markers.@each.selected', function () {
      this.renderPoints();
      this.initCurrentLocation();
    }),
    leafletMap: Ember.computed('selectedTrip', function () {
      var _this = this;

      var lmap = new L.Map('leafletmap', {
        scrollWheelZoom: this.get('mapScrollable'),
        center: new L.LatLng(this.get('locationService.lat'), this.get('locationService.lng')),
        zoom: this.get('zoom'),
        layers: new L.TileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png')
      });

      lmap.addControl(new L.Control.Fullscreen());

      lmap.on('resize', function () {
        _this.renderPoints();
      });

      lmap.on('load moveend', function () {
        if (_this.get('isRequesting') === false) {
          _this.get('set-is-requesting')(true);
          var bounds = lmap.getBounds();
          var boundsUrlString = [bounds.getSouth(), bounds.getWest(), bounds.getNorth(), bounds.getEast()].join(',');
          _this.get('map-bounds')(boundsUrlString);
          _this.get('center') ? _this.set('center', false) : null;
        }
      });

      return lmap;
    }),
    renderPoints: function renderPoints() {
      var _this2 = this;

      var markers = this.get('markers');
      var leafletMap = this.get('leafletMap');

      if (leafletMap) {
        this.removeMarkers();
      }

      if (Ember.isEmpty(markers)) {
        return [];
      }

      var selectedMarker = this.selectedMarker();

      var points = markers.reduce(function (result, point) {
        if (Ember.isEmpty(point.get('latitude')) || Ember.isEmpty(point.get('longitude'))) {
          return result;
        }

        var isSelected = selectedMarker === point;

        var pointMarker = new L.marker([point.get('latitude'), point.get('longitude')], {
          title: point.get('sitename'),
          zIndexOffset: isSelected ? 999 : 888,
          icon: _this2.markerIcon(_this2.getIconPath(isSelected))
        }).on('click', function () {
          _this2.get('select-point')(point);
          // TODO: is it needed?
          _this2.renderPoints();
        });

        result.push(pointMarker);

        return result;
      }, []);

      this.addMarkers(points);
      this.set('currentMarkers', points);
    },
    didRender: function didRender() {
      var _this3 = this;

      this.initCurrentLocation();

      Ember.run.later(function () {
        _this3.get('toggle-width')(true);
        _this3.get('set-is-requesting')(false);
      }, 2000);

      if (this.get('leafletMap')) {
        this.get('locationService');
        this.renderPoints();

        // TODO: maybe remove setTimeout
        setTimeout(function () {
          _this3.get('leafletMap').invalidateSize();
        }, 400);
      }
    },
    selectedMarker: function selectedMarker() {
      return this.markers.findBy('selected', true);
    },
    getIconPath: function getIconPath(isSelected) {
      var selectedIconPath = 'assets/fillingstation-green.png';
      var defaultIconPath = 'assets/fillingstation.png';

      if (isSelected) {
        return selectedIconPath;
      } else {
        return defaultIconPath;
      }
    },
    getLatLng: function getLatLng() {
      return [this.get('locationService.lat'), this.get('locationService.lng')];
    },
    getCurrentLocationMarker: function getCurrentLocationMarker() {
      var marker = this.get('currentLocationMarker');
      if (this.isDestroying || this.isDestroyed) {
        return;
      }
      if (!Ember.isPresent(marker)) {
        marker = L.marker(this.getLatLng(), { icon: this.markerIcon('assets/truck.png') });
        this.set('currentLocationMarker', marker);
      } else {
        this.set('currentLocationMarker', marker.setLatLng(this.getLatLng()));
      }

      marker.addTo(this.get('leafletMap')).update();

      return marker;
    },
    markerIcon: function markerIcon(iconPath) {
      return L.icon({
        iconUrl: iconPath
      });
    },
    addMarkers: function addMarkers(markers) {
      var _this4 = this;

      markers.forEach(function (marker) {
        marker.addTo(_this4.get('leafletMap'));
      });
    },
    removeMarkers: function removeMarkers() {
      var markers = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('currentMarkers');

      if (markers) {
        markers.forEach(function (marker) {
          marker.remove();
        });
      }
    },
    initCurrentLocation: function initCurrentLocation() {
      if (this.get('locationService') && this.get('locationService.lat')) {
        this.getCurrentLocationMarker().addTo(this.get('leafletMap'));
      }
    },
    centerMap: function centerMap() {
      if (this.get('center') && this.get('locationService.lat')) {
        this.initCurrentLocation();
        this.get('leafletMap').panTo(new L.LatLng(this.get('locationService.lat'), this.get('locationService.lng')));
        this.get('leafletMap').setZoom(this.get('zoom'));
        this.set('center', false);
      }
    }
  });
});