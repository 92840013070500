define('bottomline-execution/mixins/trip-stop-type', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    tripStopType: function tripStopType() {
      var tripId = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : this.get('TripID');
      var tripStopId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : this.get('TripStopID');
      var activityCode = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : this.get('ActivityCode');

      var endInventoryByActivityCode = activityCode == 'E';
      var endInventoryById = tripStopId == tripId + ':E';
      var endInventory = endInventoryByActivityCode || endInventoryById;

      return endInventory ? 'end-inventory' : 'trip-stop';
    }
  });
});