define('bottomline-execution/mirage/factories/truck-storage', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    max_truck_storage_qty: 1000,
    min_truck_storage_qty: 0
  });
});