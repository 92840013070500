define('bottomline-execution/models/be/customer', ['exports', 'bottomline-execution/models/customer', 'ember-data'], function (exports, _customer, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _customer.default.extend({
    siteId: attr('string'),

    site: Ember.computed('siteId', function () {
      return this.get('store').peekRecord('site', this.get('siteId'));
    }),

    generateDeliveryTicket: Ember.computed.or('_isSpecialLoadId', '_unrestrictedDeliveryTickets'),
    useFlowMeter: Ember.computed.and('_needsSpecialId', '_hasSpecialId'),
    canAddStopFromOrder: Ember.computed.or('_isSpecialLoadId', '_unrestrictedAddStopFromOrder'),

    _needsSpecialId: Ember.computed.alias('truckSettings.customer_type_with_flow_meter'),
    _hasSpecialId: Ember.computed.or('_isSpecialLoadId', '_isSpecialExtInvoiceDebtorId'),
    _isSpecialLoadId: Ember.computed.equal('site.LoadingID', '1000'),
    _isSpecialExtInvoiceDebtorId: Ember.computed.equal('site.ExtInvoiceDebtorID', '999')
  });
});