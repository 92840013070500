define('bottomline-execution/routes/pictures/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(em, data) {
      return this.store.find('picture', {
        TableKey: data.params['trip-stops.show']['trip_stop_id'], TableName: 'TripStop'
      });
    }
  });
});