define('bottomline-execution/controllers/shifts/adr-report', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    i18n: Ember.inject.service(),
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    authenticatedUser: Ember.computed.alias('session.session.content.authenticated'),
    scopedData: Ember.inject.service(),
    jsreport: Ember.inject.service(),
    internetConnection: Ember.inject.service(),

    currentInventories: Ember.A([]),
    doc: null,
    byCompartmentProduct: ['truck_storage_id', 'material_id'],

    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    selectedShift: Ember.computed(function () {
      var selectedShiftId = this.get('appState.selectedshift') || -1;
      return this.get('store').peekRecord('shift', selectedShiftId);
    }),

    shifts: Ember.computed('store', function () {
      return this.get('store').peekAll('shift');
    }),

    startedShift: Ember.computed('shifts.@each.state', function () {
      return this.get('shifts').findBy('state', 'started');
    }),

    adrReportTemplateName: Ember.computed(function () {
      return this.get('appState.shift.adr_report_template_name');
    }),

    startedTrip: function startedTrip() {
      return this.get('store').peekAll('trip').findBy('state', 'started');
    },
    mostRecentLoadTerminal: function mostRecentLoadTerminal(recentLoadTripStop) {
      // latest load stop should be based on the fetched trip-stop while starting the shift
      // or reloading app when shift
      var currentLoadStop = recentLoadTripStop;

      // GET the raw data
      console.info("check if trip is started, current load stop is:", recentLoadTripStop);
      var startedTrip = this.startedTrip();
      if (Ember.isPresent(startedTrip)) {
        console.info("started trip is present:", startedTrip);
        var finishedLoadStopInCurrentTrip = this.get('store').peekAll('trip-stop').find(function (tripStop) {
          return tripStop.get('state') === 'finished' && tripStop.get('isLoad') && Number(tripStop.get('TripID')) === Number(startedTrip.id);
        });
        console.info("using loaded trip from currently started trip:", finishedLoadStopInCurrentTrip);

        if (Ember.isPresent(finishedLoadStopInCurrentTrip)) {
          console.info("Setting var to return it");
          currentLoadStop = finishedLoadStopInCurrentTrip;
        }
      }

      console.info("return current Load Stop: ", currentLoadStop);
      return currentLoadStop;
    },


    adrDate: Ember.computed(function () {
      return (0, _moment.default)().format('L');
    }),
    adrTime: Ember.computed(function () {
      return (0, _moment.default)().format('LT');
    }),
    adrTruckId: Ember.computed(function () {
      return this.get('appState.currentTruck');
    }),
    adrTrailerId: Ember.computed(function () {
      return this.get('selectedShift.TrailerID');
    }),
    adrLicenseTruck: Ember.computed('appState.currentTruck', function () {
      return this.get('appState').getTruck().get('License') || '';
    }),
    adrLicenseTrailer: Ember.computed('appState.currentTrailer', function () {
      return this.get('appState').getTrailer().get('License') || '';
    }),
    adrHaulierName: Ember.computed('appState.currentTruck', function () {
      return this.get('currentTruck.haulier_description') || '';
    }),
    adrDriverName: Ember.computed('selectedShift', function () {
      return this.get('session.session.content.authenticated.user_display_name') || this.get('session.session.content.authenticated.user_name');
    }),
    currentTruck: Ember.computed('appState.currentTruck', function () {
      return this.get('store').peekRecord('truck', this.get('appState.currentTruck'));
    }),
    currentTrip: Ember.computed('appState.selectedTrip', function () {
      var selectedTripId = this.get('appState.selectedTrip') || -1;
      return this.get('store').peekRecord('trip', selectedTripId);
    }),
    currentTripStop: Ember.computed('appState.selectedTripStop', function () {
      var selectedTripStopId = this.get('appState.selectedTripStop') || -1;
      return this.get('store').peekRecord('trip-stop', selectedTripStopId);
    }),
    sortedTruckInventories: Ember.computed.sort('truckInventories', 'byCompartmentProduct'),
    truckInventories: Ember.computed(function () {
      if (this.get('appConfiguration.truckSettings.compartments')) {
        return this.get('startedShift.truck_inventories').filter(function (item) {
          return item.get('truck_storage_id') > 0;
        });
      } else {
        return this.get('startedShift.truck_inventories').filter(function (item) {
          return item.get('truck_storage_id') === 0;
        }).filter(function (item) {
          return Number(item.get('currentQty')) > 0;
        });
      }
    }),

    canGenerateADRReport: function canGenerateADRReport() {
      return Ember.isPresent(this.get('adrReportTemplateName'));
    },
    generateADRReport: function generateADRReport() {
      var adrReportTemplateName = this.get('adrReportTemplateName');

      var recentLoadStopId = -1;
      var recentLoadTripStopPayload = this.get('store').get('shiftMeta').recentLoadTripStop;
      if (Ember.isPresent(recentLoadTripStopPayload)) {
        recentLoadStopId = recentLoadTripStopPayload.trip_stop.id;
      }

      return this._jsreportsAdr(adrReportTemplateName, recentLoadStopId);
    },


    // generate ADR with jsreports
    // TODO: it will be refactored after we will switch to jsreports completely
    _jsreportsAdr: function _jsreportsAdr(reportTemplateName, recentLoadTripStopId) {
      var _this = this;

      var reportFileName = 'adr-report.pdf';

      var recentLoadTripStop = this.get('store').peekRecord('trip-stop', recentLoadTripStopId);

      var loadSite = this.mostRecentLoadTerminal(recentLoadTripStop).get('site');

      var loadSiteAddress = void 0;
      if (loadSite) {
        loadSiteAddress = loadSite.get('Name');
        loadSiteAddress += ' - ' + loadSite.get('fullAddress');

        var telephone = loadSite.get('telephoneNumbered');
        telephone ? loadSiteAddress += ', tel.: ' + telephone : null;

        loadSiteAddress = loadSiteAddress.replace(/^\s+/gm, '');
      }

      var truckId = this.get('adrTruckId');
      var truckLicense = this.get('adrLicenseTruck');
      var trailerLicense = this.get('adrLicenseTrailer');
      var haulierName = this.get('adrHaulierName');
      var driverName = this.get('adrDriverName');

      var areAllInventoriesEmpty = true;

      var truckInventories = this.get('sortedTruckInventories').reduce(function (result, truckInventory) {
        var truckInventoryData = {};

        truckInventoryData.compartmentId = truckInventory.get('truck_storage_id');
        truckInventoryData.product = truckInventory.get('material.MaterialNameShort');
        truckInventoryData.materialNameLong = truckInventory.get('material.MaterialNameLong');

        truckInventoryData.adrCode = truckInventory.get('materialAdr.materialAdrCode');

        truckInventoryData.currentQty = truckInventory.get('currentQty');

        truckInventoryData.currentQty > 0 ? areAllInventoriesEmpty = false : null;

        result.push(truckInventoryData);

        return result;
      }, []);

      var reportData = {
        truckId: truckId,
        truckLicense: truckLicense,
        trailerLicense: trailerLicense,
        loadSiteAddress: loadSiteAddress,
        haulierName: haulierName,
        driverName: driverName,
        truckInventories: truckInventories,
        areAllInventoriesEmpty: areAllInventoriesEmpty
      };

      return this.get('jsreport').postRequest(reportTemplateName, reportFileName, reportData).catch(function () {
        _this.get('toast').error('Cannot generate an ADR. Check your internet connection.');
      });
    },


    actions: {
      cancel: function cancel() {
        history.back();
      }
    }
  });
});