define('bottomline-execution/mirage/factories/otc-materials', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    materialId: '2323',
    materialOtcId: '34'
  });
});