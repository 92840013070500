define('bottomline-execution/mirage/scenarios/default', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    var truck = server.create('truck', 1, { user_id: 1 });
    var trips = server.createList('trip', 2, { state: 'started', truck_id: truck.id });
    var material = server.create('material', {
      material_name_short: 'Diesel', material_name_long: 'Diesel', material_density: 0.98
    });

    var truckStorage = server.create('truck-storage', { id: 0, truck_storage_id: 0 });
    server.create('truck-inventory', {
      material_id: material.id, current_qty: 0, truck_storage_id: truckStorage.id, truck_id: truck.id
    });
    var delivery = server.create('site', { name: 'BLS delivery' });
    var load = server.create('site', { name: 'BLS load' });

    trips.forEach(function (trip) {
      var tripStops = [];

      tripStops = tripStops.concat(server.create('trip-stop', {
        sitename: delivery.name, site_id: delivery, trip_id: trip.id, sequence_nb: 2,
        planned_qty: 100, storage_id: delivery.storage_id
      }));
      tripStops = tripStops.concat(server.create('trip-stop', {
        sitename: load.name, site_id: load, trip_id: trip.id, activity_code: 'L', sequence_nb: 1,
        planned_qty: 100, storage_id: load.storage_id
      }));
      tripStops.forEach(function (tripStop) {
        server.create('trip-stop-storage', {
          planned_qty: 100, trip_stop_id: tripStop.id, material_id: material.id,
          storage_id: tripStop.storage_id
        });
        server.create('trip-stop-truck-storage', {
          trip_stop_id: tripStop.id, material_id: material.id, truck_storage_id: truckStorage.id,
          storage_id: tripStop.storage_id
        });
      });
    });

    server.create('shift', { trip_ids: trips.map(function (trip) {
        return trip.id;
      }) });
  };
});