define('bottomline-execution/routes/trip-stops/authorize-site', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      cancel: function cancel() {
        this.transitionTo('trips');
      }
    }
  });
});