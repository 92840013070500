define('bottomline-execution/components/scanner-interface', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    epsonScanner: Ember.inject.service('scanner'),
    hpScanner: Ember.inject.service(),
    tagName: null,
    areScannerSettingsVisible: false,

    brands: ['hp', 'canon'],
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('testing')) {
        if (typeof escan !== 'undefined') {
          escan.GetWifiList(function (list) {
            if (list.length > 0) {
              _this.get('appState').set('scanner_ssid', list[0]);
            }

            _this.set('availableScanners', list);
          }, function () {
            _this.set('availableScanners', 'no scanners found');
          });
        } else {
          this.set('availableScanners', 'no scanners found');
        }
      }
    },
    onScanSuccess: function onScanSuccess(imageData) {
      alert('Scanned ' + imageData.length + ' bytes of data.');
    },

    actions: {
      testScanner: function testScanner() {
        if (this.get('appState.scanner_brand') === 'epson') {
          this.get('epsonScanner').scan(Ember.run.bind(this, this.onScanSuccess));
        } else if (this.get('appState.scanner_brand') === 'hp') {
          this.get('hpScanner').betterScanPage(function (res) {
            console.log(res);
          });
        } else if (this.get('appState.scanner_brand') === 'canon') {
          if (typeof cordova === "undefined") {
            alert('Not on mobile device, so assuming test');
          } else {
            cordova.plugins.ScangarooWrapper.scan(function (result) {
              alert(result);
            });
          }
        } else {
          alert('Select brand');
        }
      }
    }
  });
});