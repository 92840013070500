define('bottomline-execution/models/material', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var htmlSafe = Ember.String.htmlSafe;
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    MaterialNameShort: attr('string'),
    MaterialNameLong: attr('string'),
    ReplaceableYN: attr('boolean'),
    MaterialDensity: attr('number'),
    DeliveryCompatibility: attr('string'),
    foreground_color: attr('string'),
    background_color: attr('string'),
    meter_material_number: attr('string'),
    material_delivery_tolerance: attr('number'),
    style: Ember.computed('background_color,foreground_color', function () {
      var backgroundColor = this.get('background_color') || 'lightgrey';
      var foregroundColor = this.get('foreground_color') || '#595959';

      return htmlSafe('background-color: ' + backgroundColor + '; color: ' + foregroundColor);
    }),
    compatibleMaterials: Ember.computed(function () {
      return this.get('store').peekAll('material').filterBy('DeliveryCompatibility', this.get('id'));
    })
  });
});