define('bottomline-execution/components/editable-t', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend({
    session: Ember.inject.service(),
    i18n: Ember.inject.service(),
    localizationAjax: Ember.inject.service(),

    tagName: '',

    canEdit: Ember.computed.readOnly('session.session.content.authenticated.user_translator'),

    value: Ember.computed('key', function () {
      return this.get('i18n').t(this.get('key'));
    }),
    isMissing: Ember.computed('key', function () {
      return (/^(~)/.test(this.get('i18n').t(this.get('key')).toString())
      );
    }),
    save: function save() {
      var _this = this;

      var locale = this.get('i18n').locale;
      var data = {
        key: this.get('key'),
        value: this.get('value').toString(),
        namespace: 'deliveryplus'
      };
      this.set('key', '');
      this.get('localizationAjax').put('/localizations/' + locale, {
        contentType: 'application/json',
        data: JSON.stringify(data)
      }).then(function () {
        _this.get('i18n').addTranslations(locale, _defineProperty({}, data.key, data.value));
        _this.set('key', data.key);
      });
    },

    actions: {
      toggleEdit: function toggleEdit() {
        var __this = this;
        this.get('f7').prompt(this.get('key'), 'Translation to ' + this.get('i18n').locale, function (s) {
          if (s.length > 0) {
            __this.set('value', s);
            __this.save();
          }
        });
      }
    }
  });
});