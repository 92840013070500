define('bottomline-execution/serializers/trip-stop', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  Ember.Object.reopen({
    toNative: function toNative() {
      return Object.assign({}, this);
    },
    toJSON: function toJSON() {
      return JSON.stringify(this.toNative());
    }
  });

  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    session: Ember.inject.service('session'),
    authenticatedUser: Ember.computed.alias('session.session.content.authenticated'),
    isNewSerializerAPI: true,
    attrs: {
      site: { embedded: 'always' },
      order: { embedded: 'always' },
      trip_stop_storages: { embedded: 'always' },
      trip_stop_truck_storages: { embedded: 'always' },
      pictures: { embedded: 'always' },
      all_driver_remarks: { embedded: 'always' }
    },

    serialize: function serialize(snapshot) {
      if (snapshot.attr('forReport')) {
        return this.forReport(snapshot);
      } else {
        return this._super.apply(this, arguments);
      }
    },
    forReport: function forReport(snapshot) {
      var trip = snapshot.attr('TripID') ? this.store.peekRecord('trip', snapshot.attr('TripID')) : Ember.Object.create();
      var loadStop = trip.get('loadStop') || Ember.Object.create();
      var loadSite = loadStop.get('SiteID') ? this.store.peekRecord('site', loadStop.get('SiteID')) : Ember.Object.create();
      var truck = this.get('appState').getTruck() || Ember.Object.create();
      var trailer = this.get('appState').getTrailer() || Ember.Object.create();
      var resource = trip ? trip.get('resource') : Ember.Object.create();

      var tripStopTruckStorages = snapshot.hasMany('trip_stop_truck_storages').map(function (tsts) {
        tsts._internalModel._attributes['id'] = tsts.id;
        tsts._internalModel._attributes['tripStopId'] = tsts.attr('TripStopID');
        tsts._internalModel._attributes['tripId'] = tsts.attr('TripID');
        tsts._internalModel._attributes['storageID'] = tsts.attr('StorageID');
        tsts._internalModel._attributes['materialID'] = tsts.attr('MaterialID');
        tsts._internalModel._attributes['plannedQty'] = tsts.attr('plannedQty');
        tsts._internalModel._attributes['confirmedQty'] = tsts.attr('confirmedQty');
        tsts._internalModel._attributes['TruckStorageID'] = tsts.attr('TruckStorageID');

        var tstsRecord = tsts.record;
        if (tstsRecord.get('material')) {
          tsts._internalModel._attributes['material'] = tstsRecord.get('material').get('data');
        }

        return tsts._internalModel._attributes;
      });

      var tripStopStorages = snapshot.hasMany('trip_stop_storages').map(function (tss) {
        tss._internalModel._attributes['id'] = tss.id;
        tss._internalModel._attributes['materialID'] = tss.attr('MaterialID');
        tss._internalModel._attributes['plannedQty'] = tss.attr('plannedQty');
        tss._internalModel._attributes['orderQty'] = tss.attr('orderQty');
        tss._internalModel._attributes['confirmedQty'] = tss.attr('confirmedQty');
        tss._internalModel._attributes['meterQty'] = tss.attr('meterQty');
        tss._internalModel._attributes['MeterDeliveryInfo'] = tss.attr('MeterDeliveryInfo');

        var tssRecord = tss.record;
        if (tssRecord.get('material')) {
          tss._internalModel._attributes['material'] = tssRecord.get('material').get('data');
        }

        return tss._internalModel._attributes;
      });

      var orderModel = snapshot.belongsTo('order');
      var order = orderModel._attributes;
      order['orderRemarks'] = orderModel.hasMany('order_remarks').map(function (order_remark) {
        order_remark._internalModel._attributes['remark'] = order_remark.attr('Remark');
        order_remark._internalModel._attributes['target'] = order_remark.attr('Target');
        return order_remark._internalModel._attributes;
      });

      order['orderDetails'] = orderModel.hasMany('order_details').map(function (order_detail) {
        order_detail._internalModel._attributes['urgency'] = order_detail.attr('Urgency');
        order_detail._internalModel._attributes['tankFilledYn'] = order_detail.attr('TankFilledYN');
        return order_detail._internalModel._attributes;
      });

      var allRemarks = snapshot.record.get('allRemarks').map(function (remark) {
        remark._internalModel._attributes['remark'] = remark.data['body'];
        return remark._internalModel._attributes;
      });

      var reportData = {
        appState: Ember.getProperties(this.get('appState'), ['currentTruck', 'currentTrailer', 'language']),
        displayName: this.get('authenticatedUser.user_display_name') || this.get('authenticatedUser.user_name'),

        state: snapshot.attr('state'),
        siteID: snapshot.attr('SiteID'),
        tripID: snapshot.attr('TripID'),
        extOrderID: snapshot.attr('ExtOrderID'),
        orderID: snapshot.attr('OrderID'),
        orderRef: snapshot.attr('OrderRef'),
        confirmedQty: snapshot.attr('confirmedQty'),
        plannedQty: snapshot.attr('plannedQty'),
        driverRemarks: snapshot.attr('DriverRemarks'),
        allRemarks: allRemarks,
        confirmedArrivalDT: snapshot.attr('ConfirmedArrivalDT'),

        resource: resource.toJSON(),

        loadStop: loadStop.toJSON(),
        loadSite: loadSite.toJSON(),

        currentTruck: truck.toJSON(),
        currentTrailer: trailer.toJSON(),

        site: snapshot.belongsTo('site')._attributes,
        order: order,
        tripStopTruckStorages: tripStopTruckStorages,
        tripStopStorages: tripStopStorages
      };

      return reportData;
    }
  });
});