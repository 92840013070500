define('bottomline-execution/components/free-trip-map', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    locationService: Ember.inject.service('location'),
    assetMap: Ember.inject.service('asset-map'),

    classNames: ['map'],
    points: [],
    currentLocation: null,
    mapScrollable: true,
    zoom: 15,
    map: Ember.computed(function () {
      return this.$();
    }),
    markersChanged: Ember.observer('markers.@each.selected', function () {
      this.renderPoints();
      this.initCurrentLocation();
    }),
    gmap: Ember.computed(function () {
      if (typeof GMaps !== 'undefined' && this.get('map')) {
        return new GMaps({
          el: this.get('map')[0],
          height: '300px',
          width: '100%',
          lat: this.get('locationService.lat'),
          lng: this.get('locationService.lng'),
          zoom: this.get('zoom'),
          idle: Ember.run.bind(this, function (e) {
            if (this.get('isRequesting') === false) {
              this.get('set-is-requesting')(true);
              this.get('map-bounds')(e.getBounds().toUrlValue());
              this.get('center') ? this.set('center', false) : null;
            }
          })
        });
      }
    }),
    renderPoints: function renderPoints() {
      var _this = this;

      var selectedIconPath = 'assets/fillingstation-green.png';
      var defaultIconPath = 'assets/fillingstation.png';

      if (this.get('gmap')) {
        this.get('gmap').removeMarkers();
      }

      if (Ember.isEmpty(this.markers)) {
        return [];
      }

      var points = Ember.A(this.markers.map(function (point) {
        return {
          lat: point.get('latitude'),
          lng: point.get('longitude'),
          title: point.get('sitename'),
          optimized: true,
          zIndex: point.get('selected') ? 999 : 888,
          icon: point.get('selected') ? selectedIconPath : defaultIconPath,
          click: function click() {
            _this.get('select-point')(point);
            _this.renderPoints();
          }
        };
      })).filter(function (marker) {
        return !Ember.isEmpty(marker.lat);
      });

      this.get('gmap').addMarkers(points);
    },
    didRender: function didRender() {
      var _this2 = this;

      this.initCurrentLocation();

      Ember.run.later(function () {
        _this2.get('toggle-width')(true);
        _this2.get('set-is-requesting')(false);
      }, 2000);

      if (this.get('gmap')) {
        this.get('gmap').set('scrollwheel', this.get('mapScrollable'));
        this.get('locationService');
        this.renderPoints();

        var map = this.get('gmap').map;

        google.maps.event.trigger(map, 'resize');
      }
    },
    selectedMarker: function selectedMarker() {
      return this.markers.findBy('selected', true);
    },
    getLatLng: function getLatLng() {
      return new google.maps.LatLng(this.get('locationService.lat'), this.get('locationService.lng'));
    },
    getLocationMarker: function getLocationMarker() {
      if (Ember.isPresent(this.get('currentLocation'))) {
        this.set('currentLocation.position', this.getLatLng());

        return this.get('currentLocation');
      }

      this.set('currentLocation', new google.maps.Marker({
        position: this.getLatLng(),
        icon: { url: 'assets/truck.png' }
      }));

      return this.get('currentLocation');
    },
    initCurrentLocation: function initCurrentLocation() {
      var map = this.get('gmap').map;

      this.getLocationMarker().setMap(map);
    },
    centerMap: function centerMap() {
      if (this.get('center') && this.get('locationService.lat')) {
        this.initCurrentLocation();
        this.get('gmap').setCenter(this.get('locationService.lat'), this.get('locationService.lng'));
        this.get('gmap').setZoom(this.get('zoom'));
        this.set('center', false);
      }
    }
  });
});