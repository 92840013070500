define('bottomline-execution/controllers/shifts/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    activeTrip: null,
    activeShift: null,

    actions: {
      navigateToTripPage: function navigateToTripPage(shift) {
        if (shift.showQuestionsOnStart) {
          this.transitionToRoute('shift-questions');
        } else {
          this.transitionToRoute('trips.index', shift.id);
        }
      },
      setActiveTrip: function setActiveTrip(itemId) {
        this.set('activeTrip', this.store.peekRecord('trip', itemId));
      },
      setActiveShift: function setActiveShift(itemId) {
        this.set('activeShift', this.store.peekRecord('shift', itemId));
      },
      unsetActiveTrip: function unsetActiveTrip() {
        this.set('activeTrip', null);
      },
      onShiftTripNavigateClick: function onShiftTripNavigateClick(trip) {
        trip.set('isShown', true);
        this.transitionToRoute('trips.index', trip.get('shift.id'));
      }
    }
  });
});