define('bottomline-execution/utils/url-utils', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function stripTrailingSlash(str) {
    return str.replace(/\/$/, "");
  }

  function extractDomainName(url) {
    if (Ember.isEmpty(url)) return '';

    var matches = url.match(/^https?:\/\/([^/?#]+)(?:[/?#]|$)/i);
    return matches && matches[1];
  }

  function isIpAddress(address) {
    if (Ember.isEmpty(address)) return false;
    return Ember.isPresent(address.match(/^\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}[:\d]*$/));
  }

  exports.default = {
    stripTrailingSlash: stripTrailingSlash,
    extractDomainName: extractDomainName,
    isIpAddress: isIpAddress
  };
});