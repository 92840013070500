define('bottomline-execution/models/inventory', ['exports', 'ember-data', 'moment', 'bottomline-execution/models/base'], function (exports, _emberData, _moment, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr;
  exports.default = _base.default.extend({
    InventoryDT: attr('date'),
    inventoryQty: attr('number'),
    StorageID: attr('string'),

    isOutdated: Ember.computed('InventoryDT', function () {
      var outdateInterval = 240; // 4 hours
      var inventoryDateTimeUTC = (0, _moment.default)(new Date(this.get('InventoryDT')).toUTCString());
      var currentDateTimeUTC = (0, _moment.default)(new Date().toUTCString());

      return inventoryDateTimeUTC.isBefore(currentDateTimeUTC.subtract(outdateInterval, 'minutes'));
    }),

    siteStorage: Ember.computed('StorageID', function () {
      return this.get('store').peekRecord('storage', this.get('StorageID'));
    }),

    // StorageID is required to display it correctly on the unload page
    // it can be undefined because BE back-end API may not return it
    canBeUpdated: Ember.computed('StorageID', function () {
      return Ember.isPresent(this.get('StorageID'));
    })
  });
});