define('bottomline-execution/services/request-headers', ['exports', 'bottomline-execution/mixins/version'], function (exports, _version) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_version.default, {
    deviceInformation: Ember.inject.service(),
    session: Ember.inject.service(),

    headers: function headers() {
      var _this = this;

      var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref$authorize = _ref.authorize,
          authorize = _ref$authorize === undefined ? false : _ref$authorize;

      return new Ember.RSVP.Promise(function (resolve) {
        var headers = _this.defaultHeaders({ authorize: authorize });

        _this.get('deviceInformation').getMacAddress().then(function (macaddress) {
          headers['device-guid'] = macaddress;
          resolve(headers);
        }, function (_failure) {
          resolve(headers); // Do not set 'device-guid' if there is no MAC address
        });
      });
    },
    defaultHeaders: function defaultHeaders() {
      var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          _ref2$authorize = _ref2.authorize,
          authorize = _ref2$authorize === undefined ? false : _ref2$authorize;

      var session = this.get('session');
      var headers = { 'App-Version': this.get('appVersionNumber'),
        'Content-Type': 'application/json' };

      if (authorize) {
        var authorizerName = session.get('session.authenticator').split(':')[1];
        session.authorize('authorizer:' + authorizerName, function (headerName, headerValue) {
          headers[headerName] = headerValue;
        });
      }

      return headers;
    }
  });
});