define('bottomline-execution/controllers/trip-stops/show/index', ['exports', 'ember-data', 'bottomline-execution/mixins/pdf-document-opener', 'bottomline-execution/mixins/picture-browser', 'bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages', 'bottomline-execution/config/environment', 'bottomline-execution/utils/guid-util', 'bottomline-execution/utils/delivery-ticket-nb'], function (exports, _emberData, _pdfDocumentOpener, _pictureBrowser, _plannedOrConfirmedTripStopStorages, _environment, _guidUtil, _deliveryTicketNb) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend(_pdfDocumentOpener.default, _pictureBrowser.default, _plannedOrConfirmedTripStopStorages.default, {
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    androidIntentsAtx: Ember.inject.service(),
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    locationService: Ember.inject.service('location'),
    vehicleStorages: Ember.inject.service(),
    meterCoupling: Ember.inject.service(),
    acceptsNumberOfDeliveries: Ember.computed.oneWay('meterCoupling.acceptsNumberOfDeliveries'),
    meterDeliveriesData: [],

    isMeterDeliveriesDataPresent: Ember.computed('meterDeliveriesData', function () {
      return Ember.isPresent(this.get('meterDeliveriesData'));
    }),

    isProcessingDeliveries: Ember.computed.alias('meterCoupling.isProcessingDeliveries'),
    locationConfirmedId: null,

    showAtgInformation: Ember.computed.alias('currentTruckSettings.show_atg_information'),
    showPredip: Ember.computed.bool('currentTruckSettings.predip_enabled'),
    editablePredip: Ember.computed.equal('currentTruckSettings.predip_enabled', 'on_unload'),
    showPostdip: Ember.computed.bool('currentTruckSettings.postdip_enabled'),
    showDischargePlanButton: Ember.computed.alias('currentTruckSettings.dischargeplan_on_unload'),
    isOpeningDischargePlan: false,

    filterLoadTruckPlannedCompartments: false,
    filterLoadTrailerPlannedCompartments: false,
    resetLoadCompartmentFilters: function resetLoadCompartmentFilters() {
      this.set('filterLoadTruckPlannedCompartments', false);
      this.set('filterLoadTrailerPlannedCompartments', false);
    },


    filterUnloadTruckPlannedCompartments: true,
    filterUnloadTrailerPlannedCompartments: true,
    resetUnloadCompartmentFilters: function resetUnloadCompartmentFilters() {
      this.set('filterUnloadTruckPlannedCompartments', true);
      this.set('filterUnloadTrailerPlannedCompartments', true);
    },


    showTripStopStorages: Ember.computed.alias('appConfiguration.truckSettings.show_planned_materials'),
    showUnplannedStorages: false,
    showUnplannedTripStopStorages: false,
    offline: false,
    showInventory: true,

    currentTripStop: Ember.computed.alias('state.tripStop'),
    showLoad15LitersSwitch: Ember.computed('currentTruckSettings.temp_density_ltr15_load_enabled', function () {
      return this.get('currentTruckSettings.temp_density_ltr15_load_enabled') !== false;
    }),
    showDelivery15LitersSwitch: Ember.computed('currentTruckSettings.temp_density_ltr15_delivery_enabled', function () {
      return this.get('currentTruckSettings.temp_density_ltr15_delivery_enabled') !== false;
    }),
    showTemperatureDensity15Liters: Ember.computed.alias('model.showTemperatureDensity15Liters'),
    showTruckCompartments: Ember.computed('state.currentTruck', function () {
      return this.get('vehicleStorages').hasTruckStorages();
    }),
    showTrailerCompartments: Ember.computed('state.currentTrailer', function () {
      return this.get('vehicleStorages').hasTrailerStorages();
    }),
    currentTruck: Ember.computed.alias('state.currentTruck'),
    currentTruckSettings: Ember.computed.alias('appConfiguration.truckSettings'),

    showAllLoadTruckCompartments: Ember.computed('filterLoadTruckPlannedCompartments', 'loadTripStopStorage', function () {
      return !this.get('filterLoadTruckPlannedCompartments') || this.get('loadTripStopStorage.plannedQty') < 1;
    }),
    showAllLoadTrailerCompartments: Ember.computed('filterLoadTrailerPlannedCompartments', 'loadTripStopStorage', function () {
      return !this.get('filterLoadTrailerPlannedCompartments') || this.get('loadTripStopStorage.plannedQty') < 1;
    }),
    showAllUnloadTruckCompartments: Ember.computed('filterUnloadTruckPlannedCompartments', 'unloadTripStopStorage', function () {
      return !this.get('filterUnloadTruckPlannedCompartments') || this.get('unloadTripStopStorage.plannedQty') < 1;
    }),
    showAllUnloadTrailerCompartments: Ember.computed('filterUnloadTrailerPlannedCompartments', 'unloadTripStopStorage', function () {
      return !this.get('filterUnloadTrailerPlannedCompartments') || this.get('unloadTripStopStorage.plannedQty') < 1;
    }),
    isAtx: Ember.computed(function () {
      return _environment.default.APP.target === 'atx';
    }),
    visibleTripStopStorages: Ember.computed('model.trip_stop_storages.@each.storage', 'showUnplannedStorages', function () {
      var showUnplannedStorages = this.get('showUnplannedStorages');

      return this.plannedOrConfirmedTripStopStorages({ tripStop: this.get('model'),
        tripStopStorages: this.get('model.trip_stop_storages'),
        includeUnplannedStorages: showUnplannedStorages,
        storageGroupID: this.get('model.StorageGroupID')
      }).sortBy('storage.SequenceNb');
    }),
    hasRecentInventory: Ember.computed('visibleTripStopStorages', function () {
      return this.get('visibleTripStopStorages').any(function (tts) {
        return tts.get('storage.recent_inventory');
      });
    }),

    plannedTripStopStorages: Ember.computed.alias('model.plannedTripStopStorages'),
    currentTruckId: Ember.computed.alias('state.currentTruck'),
    showQuestions: Ember.computed.alias('state.questionaire'),
    state: Ember.computed(function () {
      return this.store.peekRecord('state', 1);
    }),
    currentTruckInventory: Ember.computed('currentTruck', function () {
      return this.get('model.trip.shift.truck_inventories').filterBy('truck_id', this.get('currentTruck')).sortBy('truck_storage_id');
    }),
    showDeliveryTicketInput: Ember.computed('appConfiguration.truckSettings.delivery_ticketnb_per_tank', 'appConfiguration.truckSettings.hide_delivery_ticket_input', function () {
      return !this.get('appConfiguration.truckSettings.delivery_ticketnb_per_tank') && !this.get('appConfiguration.truckSettings.hide_delivery_ticket_input');
    }),
    enableSiteLocationUpdate: Ember.computed('locationService.lat', 'appConfiguration.truckSettings.register_site_coordinates', function () {
      return Ember.isPresent(this.get('locationService.lat')) && this.get('appConfiguration.truckSettings.register_site_coordinates');
    }),
    enableTankLocationUpdate: Ember.computed('locationService.lat', 'appConfiguration.truckSettings.register_tank_coordinates', function () {
      return Ember.isPresent(this.get('locationService.lat')) && this.get('appConfiguration.truckSettings.register_tank_coordinates');
    }),

    inventories: Ember.computed('model.isUnload', 'showUnplannedStorages', 'appConfiguration.truckSettings.dip_unplanned_storages', function () {
      var _this = this;

      if (this.get('model.isUnload') && this.get('showUnplannedStorages') && this.get('appConfiguration.truckSettings.dip_unplanned_storages')) {
        var storageGroupId = this.get('model.StorageGroupID');
        var promise = this.get('model.site.storages').then(function (siteStorages) {
          return siteStorages.filter(function (storage) {
            return storage.get('StorageGroupID') != storageGroupId;
          }).reduce(function (inventories, storage) {
            var newInventory = _this.get('store').createRecord('inventory', {
              id: (0, _guidUtil.guidUtil)(),
              storage: storage,
              StorageID: storage.get('id'),
              InventoryDT: moment(),
              inventoryQty: 0,
              tripStopId: _this.get('model.id')
            });

            if (newInventory.get('canBeUpdated')) {
              storage.set('recent_inventory', newInventory);
              inventories.push(newInventory);
            }

            return inventories;
          }, []);
        });
        return _emberData.default.PromiseArray.create({ promise: promise });
      } else {
        return [];
      }
    }),

    canProcessDeliveries: Ember.computed('model.trip.shift.meterType', 'visibleTripStopStoragesMaterials.@each.meter_material_number', 'visibleTripStopStorages.@each.processDelivery', 'acceptsNumberOfDeliveries', function () {
      var deliveriesToProcess = this.get('visibleTripStopStorages').filter(function (storage) {
        return storage.get('processDelivery');
      });
      return Ember.isPresent(this.get('model.trip.shift.meterType')) && Ember.isPresent(deliveriesToProcess) && deliveriesToProcess.every(function (item) {
        return Ember.isPresent(item.get('material.meter_material_number'));
      }) && Number.isInteger(this.get('acceptsNumberOfDeliveries'));
    }),

    processDeliveriesTranslationKey: Ember.computed('acceptsNumberOfDeliveries', function () {
      var acceptsNumberOfDeliveries = this.get('acceptsNumberOfDeliveries');
      if (!Number.isInteger(acceptsNumberOfDeliveries)) {
        return 'initializing';
      } else if (acceptsNumberOfDeliveries === 0) {
        return 'get_deliveries';
      } else {
        return 'process_deliveries';
      }
    }),

    stopProcessDeliveriesTranslationKey: Ember.computed('acceptsNumberOfDeliveries', function () {
      var acceptsNumberOfDeliveries = this.get('acceptsNumberOfDeliveries');
      if (acceptsNumberOfDeliveries === 0) {
        return 'stop_get_deliveries';
      } else {
        return 'stop_process_deliveries';
      }
    }),

    visibleTripStopStoragesMaterials: Ember.computed('visibleTripStopStorages.@each.material', function () {
      return this.get('visibleTripStopStorages').mapBy('material');
    }),

    loadAsPlanned: function loadAsPlanned(truckInventory) {
      var tripStopTruckStorage = this.get('model.trip_stop_truck_storages').find(function (tsts) {
        return tsts.get('TruckStorageID') === truckInventory.get('truck_storage_id');
      });

      if (tripStopTruckStorage && tripStopTruckStorage.get('hasPlannedMaterial') && Ember.isEmpty(tripStopTruckStorage.get('confirmedQty'))) {
        tripStopTruckStorage.setConfirmedFromPlannedQty();
      }
    },
    _confirmTripStop: function _confirmTripStop() {
      var _this2 = this;

      var model = this.get('model');

      console.log('Confirming trip stop');
      this.get('f7').showPreloader();
      model.confirm().then(function () {
        console.log('Trip stop confirmed');

        console.log('Preparing transition');
        if (_environment.default.APP.target === 'atx') {
          _this2.get('androidIntentsAtx').stop(model.get('ActivityCode'));
        }

        var truckSettings = _this2.get('currentTruckSettings');

        if (model.get('isUnload')) {
          _this2.set('showUnplannedStorages', false);
        }

        console.log('Execute transition');
        _this2.get('f7').hidePreloader();
        if (model.get('isUnload') && truckSettings.get('authorize_trip_stop')) {
          model.unSignCompartments();
          _this2.transitionToRoute('trip-stops.show.authorize-delivery', model.get('TripID'), model.id);
        } else {
          _this2.transitionToRoute('trips');
        }

        return false;
      });
    },


    actions: {
      toggleShowTripStopStorages: function toggleShowTripStopStorages() {
        this.toggleProperty('showTripStopStorages');

        if (!this.get('showTripStopStorages')) {
          this.set('loadTripStopStorage', null);
          this.resetLoadCompartmentFilters();

          this.set('showUnplannedTripStopStorages', false);
        }

        return false;
      },
      toggleShowUnplannedTripStopStorages: function toggleShowUnplannedTripStopStorages() {
        this.toggleProperty('showUnplannedTripStopStorages');

        if (this.get('showUnplannedTripStopStorages')) {
          this.set('showTripStopStorages', true);
        }
      },
      toggleFilterLoadTruckPlannedCompartments: function toggleFilterLoadTruckPlannedCompartments() {
        this.toggleProperty('filterLoadTruckPlannedCompartments');
      },
      toggleFilterLoadTrailerPlannedCompartments: function toggleFilterLoadTrailerPlannedCompartments() {
        this.toggleProperty('filterLoadTrailerPlannedCompartments');
      },
      toggleFilterUnloadTruckPlannedCompartments: function toggleFilterUnloadTruckPlannedCompartments() {
        this.toggleProperty('filterUnloadTruckPlannedCompartments');
      },
      toggleFilterUnloadTrailerPlannedCompartments: function toggleFilterUnloadTrailerPlannedCompartments() {
        this.toggleProperty('filterUnloadTrailerPlannedCompartments');
      },
      toggleUnplannedStorages: function toggleUnplannedStorages() {
        if (this.get('isProcessingDeliveries')) {
          return false;
        }

        this.toggleProperty('showUnplannedStorages');
      },
      toggleDeliveryToProcess: function toggleDeliveryToProcess(tripStopStorageId) {
        var tripStopStorage = this.get('visibleTripStopStorages').find(function (storage) {
          return storage.get('id') == tripStopStorageId;
        });
        if (Ember.isPresent(tripStopStorage)) {
          tripStopStorage.toggleProperty('processDeliveryFlag');
        }
      },
      openDischargePlan: function openDischargePlan() {
        var _this3 = this;

        var tripStopId = this.get('model.id');
        var flagOpeningDischargePlan = function flagOpeningDischargePlan(opening) {
          _this3.set('isOpeningDischargePlan', opening);
        };
        this.openPDFByDescription('TripStop', 'dischargeplan', tripStopId, flagOpeningDischargePlan, 'trip-stop.dischargePlan.unavailable');
      },
      processDeliveries: function processDeliveries() {
        var _this4 = this;

        var deliveriesToProcess = this.get('visibleTripStopStorages').filter(function (storage) {
          return storage.get('processDelivery');
        });
        var deliveriesData = deliveriesToProcess.map(function (storage) {
          return { productType: String(storage.get('material.meter_material_number')),
            volume: String(storage.get('plannedQty')) };
        });

        var total = function total(value) {
          return value == 'null' ? 0 : value;
        };

        this.get('meterCoupling').connectAndProcessDeliveries(this.get('model'), deliveriesData).then(function (meterDeliveriesData) {
          _this4.set('meterDeliveriesData', meterDeliveriesData);
          meterDeliveriesData.forEach(function (deliveryData, index) {
            if (deliveryData.volume) {
              var meterDeliveryInfo = 'MeterId=' + deliveryData.meterId + ';CounterStart=' + total(deliveryData.totalVolumeStart) + ';CounterEnd=' + total(deliveryData.totalVolumeEnd) + ';Counter15Start=' + total(deliveryData.totalVolume15cStart) + ';Counter15End=' + total(deliveryData.totalVolume15cEnd);

              var deliveryStoragAttributes = { confirmedQty: deliveryData.volume,
                meterQty: deliveryData.volume,
                TicketNb: (0, _deliveryTicketNb.deliveryTicketNb)(_this4.get('appState.currentTruck'), deliveryData.meterIndex, deliveryData.ticketNb),
                MeterDeliveryInfo: meterDeliveryInfo };

              if (deliveryData.volume15c !== 'null') deliveryStoragAttributes.confirmed15Qty = deliveryData.volume15c;
              if (deliveryData.temperature !== 'null') deliveryStoragAttributes.Temperature = deliveryData.temperature;

              deliveriesToProcess.objectAt(index).setProperties(deliveryStoragAttributes);
            }
          });
        });

        return false;
      },
      stopProcessDeliveries: function stopProcessDeliveries() {
        this.get('meterCoupling').stopProcessDeliveries();

        return false;
      },
      confirm: function confirm() {
        var _this5 = this;

        var postDipsInvalidMessage = this.get('model.validations.warnings').filterBy('attribute', 'PostdipsOnUnloadValid').mapBy('message').join(',');

        if (Ember.isPresent(postDipsInvalidMessage)) {
          var formattedMessage = postDipsInvalidMessage.split(',').join('<p>');
          this.get('f7').modal({ title: this.get('i18n').t('postdip_validation.title'),
            text: this.get('i18n').t('postdip_validation.warning') + '<p>' + formattedMessage,
            verticalButtons: true,
            buttons: [{ text: this.get('i18n').t('postdip_validation.cancel') }, { text: this.get('i18n').t('postdip_validation.confirm'),
              bold: true,
              onClick: function onClick() {
                _this5._confirmTripStop();
              } }]
          });
        } else {
          this._confirmTripStop();
        }
      },
      cancel: function cancel() {
        this.get('model').cancel();

        if (_environment.default.APP.target === 'atx') {
          this.get('androidIntentsAtx').stop(this.get('model.ActivityCode'));
        }

        this.transitionToRoute('trips');
      },
      selectLoadTripStopStorage: function selectLoadTripStopStorage(id) {
        this.set('loadTripStopStorage', this.store.peekRecord('trip-stop-storage', id));
      },
      selectUnloadTripStopStorage: function selectUnloadTripStopStorage(id) {
        this.set('unloadTripStopStorage', this.store.peekRecord('trip-stop-storage', id));
        this.resetUnloadCompartmentFilters();
      },
      truckIsEmpty: function truckIsEmpty() {
        this.get('model.trip_stop_truck_storages').filter(function (tsts) {
          return tsts.get('confirmedQty') !== 0;
        }).setEach('confirmedQty', 0);
        this.get('model.trip_stop_storages').filter(function (tsts) {
          return tsts.get('confirmedQty') !== 0;
        }).setEach('confirmedQty', 0);

        this.get('model.truckInventories').setEach('currentQty', 0);
        this.get('model.trailerInventories').setEach('currentQty', 0);

        return false;
      },
      getLoads: function getLoads() {
        var loadsImported = 0;

        if (this.get('model.trip_stop_storages').invoke('getLoad')) {
          loadsImported = loadsImported + 1;
        }

        if (loadsImported === 0) {
          alert('Load information not available');
        }
      },
      loadAsPlanned: function loadAsPlanned() {
        var _this6 = this;

        this.get('model.truckInventories').forEach(function (inventory) {
          _this6.loadAsPlanned(inventory);
        });

        this.get('model.trailerInventories').forEach(function (inventory) {
          _this6.loadAsPlanned(inventory);
        });

        this.get('model.trip_stop_storages').invoke('updateConfirmedQty');
      },
      scanByTank: function scanByTank(tank) {
        alert(tank);
      },
      saveStorageCurrentLocation: function saveStorageCurrentLocation(storage) {
        var _get$getProperties = this.get('locationService').getProperties('lat', 'lng'),
            latitude = _get$getProperties.lat,
            longitude = _get$getProperties.lng;

        storage.setProperties({ latitude: latitude, longitude: longitude });
        storage.save();
      },
      saveStorageNewlocation: function saveStorageNewlocation(storage, latitude, longitude) {
        storage.setProperties({ latitude: latitude, longitude: longitude });
        return storage.save();
      },
      saveSiteNewlocation: function saveSiteNewlocation(tripStop, latitude, longitude) {
        var site = tripStop.get('site');
        site.setProperties({ latitude: latitude, longitude: longitude });
        tripStop.setProperties({ latitude: latitude, longitude: longitude });
        return site.save();
      },
      showLocationConfirmer: function showLocationConfirmer(id) {
        var _this7 = this;

        this.set('locationConfirmedId', null);
        setTimeout(function () {
          _this7.set('locationConfirmedId', id);
        }, 500);
      },
      cancelUpdateLocation: function cancelUpdateLocation() {
        this.set('locationConfirmedId', null);
      }
    }
  });
});