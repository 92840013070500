define('bottomline-execution/components/sening-interface', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    logger: Ember.inject.service('remote-logger'),
    emis: Ember.inject.service(),
    store: Ember.inject.service(),
    meterCounterPollster: Ember.inject.service(),
    materialCodes: Ember.A([]),
    tanks: Ember.A([]), // comes from component
    reaction: '',

    didInsertElement: function didInsertElement() {
      this.get('emis').init();
    },
    presetByProductCode: function presetByProductCode(materialId) {
      var materialOtc = this.get('store').peekRecord('otc-material', materialId);

      if (materialOtc) {
        return (Number(materialOtc.get('materialOtcId')) + 100 + '').substring(1, 3);
      } else {
        alert('Material not linked');
        this.get('logger').logInfo('Material ' + materialId + ' not linked.');

        return false;
      }
    },
    sendPresets: function sendPresets() {
      var _this = this;

      this.get('tanks').forEach(function (tank, index) {
        var productCode = _this.presetByProductCode(tank.get('MaterialID'));
        var msg = 'SET,METER,ORDERS,PRESET(' + index + '),PCode=' + productCode + ';Volume="' + tank.get('plannedQty') + '";PUnit=L';

        _this.get('emis').set('status', 'STEP 2/3: Setting presets for tank: ' + tank.get('StorageID'));

        if (Ember.isPresent(productCode)) {
          _this.get('emis').sendMessage("SET,METER,ORDERS,PRESET(" + index + "),PCode=" + productCode + ";Volume=\"" + tank.get('plannedQty') + "\";PUnit=L");
          _this.get('logger').logInfo('STEP 2: ' + msg + ' has been sent, ready to unload.');
        } else {
          _this.get('logger').logInfo('STEP 2: ' + msg + ' has not been sent, try linking ' + tank.get('MaterialID') + ' with a specific productCode');

          return false;
        }
      });

      setTimeout(function () {
        _this.get('emis').set('status', 'STEP 3/3: Setting ordercount');
        _this.setOrderCount();
        _this.get('meterCounterPollster').start(_this.get('trip-stop.id'));
      }, 1000);
    },
    getResult: function getResult(index) {
      var _this2 = this;

      var isReady = this.get('tanks').length - 1 < index;

      if (isReady) {
        this.get('logger').logInfo('ready reading');
        this.get('meterCounterPollster').stop();
        this.get('emis').set('status', 'IDLE');
      } else {
        var tank = this.get('tanks').toArray()[index];
        this.get('logger').logInfo('Getting result for tank ' + tank.get('StorageID'));
        this.get('emis').set('status', 'STEP ' + (index + 1) + '/' + this.get('tanks').length + ': Getting results');

        //Get order results.
        var msg = 'REQUEST,METER,ORDERS,RESULT(' + index + ')';
        this.get('logger').logInfo(msg);
        this.get('emis').sendMessage(msg).then(function (response) {
          _this2.get('logger').logInfo('Sening has got the message now responding: ' + response);

          var nextTank = index + 1;

          _this2.get('logger').logInfo("next" + nextTank);

          if (_this2.valueFor(response, "MODEID") === '15') {
            tank.set('confirmedQty', _this2.valueFor(response, "VT"));
            tank.set('confirmed15Qty', _this2.valueFor(response, "VC"));
            tank.set('meterQty', _this2.valueFor(response, "VT"));
            tank.set('meter15Qty', _this2.valueFor(response, "VC"));
          } else {
            tank.set('confirmedQty', _this2.valueFor(response, "VOLUME"));
            tank.set('confirmed15Qty', _this2.valueFor(response, "VT"));
            tank.set('meterQty', _this2.valueFor(response, "VOLUME"));
            tank.set('meter15Qty', _this2.valueFor(response, "VT"));
          }

          _this2.get('logger').logInfo(_this2.valueFor(response, "AVTEMP"));

          var mass = _this2.valueFor(response, "MASS");
          var volume = tank.get('confirmedQty');
          var meterDeliveryInfo = "";

          if (volume > 0) {
            if (_this2.valueFor(response, "MODEID") === '15') {
              meterDeliveryInfo = "METERINDEX:" + _this2.valueFor(response, "METERINDEX") + ";VOLUME:" + _this2.valueFor(response, "VC") + ";DATE:" + _this2.valueFor(response, "DATE") + ";STARTTIME:" + _this2.valueFor(response, "STARTTIME") + ";ENDTIME:" + _this2.valueFor(response, "ENDTIME") + ";METERID:" + _this2.valueFor(response, "METERID");
            } else {
              meterDeliveryInfo = "METERINDEX:" + _this2.valueFor(response, "METERINDEX") + ";VOLUME:" + _this2.valueFor(response, "VOLUME") + ";DATE:" + _this2.valueFor(response, "DATE") + ";STARTTIME:" + _this2.valueFor(response, "STARTTIME") + ";ENDTIME:" + _this2.valueFor(response, "ENDTIME") + ";METERID:" + _this2.valueFor(response, "METERID");
            }

            tank.set('MeterDeliveryInfo', meterDeliveryInfo);

            var density = mass / volume;

            tank.set('Density', Number(density).toFixed(4));
          }

          tank.setProperties({
            confirmedWeight: mass,
            meterWeight: mass,
            Temperature: _this2.valueFor(response, 'AVTEMP')
          });
          _this2.get('emis').set('status', 'STEP ' + (index + 1) + '/' + _this2.get('tanks').length + ': Getting results, done');
          _this2.getResult(nextTank);
        });
      }
    },
    setOrderCount: function setOrderCount() {
      var _this3 = this;

      this.get('logger').logInfo('STEP 3: Counting orders: ' + this.get('tanks').length);
      this.get('emis').sendMessage('SET,METER,ORDERS,OrderCount=' + this.get('tanks').length).then(function (response) {
        _this3.get('logger').logInfo(response);
        if (response.length === 0) {
          _this3.get('emis').set('status', 'STEP 3/3: Setting ordercount: no response');
          setTimeout(function () {
            _this3.get('emis').set('status', 'ERROR, bluetooth is ok, but no response.');
            _this3.get('logger').logInfo('ERROR, missing response, bluetooth is ok, but no communication from sening.');
          }, 5000);
        } else {
          _this3.get('emis').set('status', 'STEP 3/3: Setting ordercount: done');
          setTimeout(function () {
            _this3.get('emis').set('status', 'SUCCESS');
            _this3.get('logger').logInfo('SUCCESS, ordercount data is received');
          }, 5000);
        }
        _this3.get('tanks').forEach(function (tank, index) {
          _this3.get('emis').sendMessage('Request,Meter,Status(' + index + ')').then(function (response) {
            _this3.get('logger').logInfo(response);
          });
        });
      }, function (error) {
        alert('there was an error');
        setTimeout(function () {
          _this3.get('emis').set('status', 'ERROR: ' + error);
        }, 5000);
      });
    },
    valueFor: function valueFor(response, emisAttribute) {
      return this.get('emis').valueFor(response, emisAttribute);
    },
    setupForSending: function setupForSending() {
      var _this4 = this;

      var cmd = 'SET,METER,ORDERS,ReInit=123';
      var emisVersion = 'REQUEST,ADMIN,DEVICE,SWVersion';

      this.get('logger').logInfo('Step 0: Getting Emis version ' + emisVersion);
      this.get('emis').sendMessage(emisVersion).then(function (response) {
        _this4.get('logger').logInfo(response);
        setTimeout(function () {
          _this4.get('logger').logInfo('Step 1: ' + cmd);
          _this4.get('emis').sendMessage(cmd).then(function (response) {
            _this4.get('emis').set('status', 'STEP 1/3: Preparing unload');
            _this4.get('logger').logInfo(response);

            _this4.get('logger').logInfo('Communication with sening is up and initiated: ' + String(response));
            _this4.get('emis').set('status', 'STEP 1/3: Preparing unload: done');
            _this4.sendPresets();
          }, 5000);
        });
      });
    },

    actions: {
      sendQuantity: function sendQuantity() {
        this.setupForSending();
      },
      getQuantity: function getQuantity() {
        this.getResult(0);
      }
    }
  });
});