define('bottomline-execution/mirage/factories/trip-stop', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    sequence_nb: function sequence_nb(n) {
      return n;
    },

    activity_code: 'U',
    picture_count: 0,
    planned_qty: 1000,
    state: 'planned',
    storage_group_id: null,
    show_questions_on: 'finish',
    time_stamp: '1232',
    trip: null,
    trip_stop_truck_storages: [],
    trip_stop_storages: []
  });
});