define('bottomline-execution/routes/trip-stops/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(_ref) {
      var trip_stop_id = _ref.trip_stop_id;

      return this.store.peekRecord('trip-stop', trip_stop_id);
    }
  });
});