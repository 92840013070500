define("bottomline-execution/utils/test-image-util", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function testImage() {
    var images = ["iVBORw0KGgoAAAANSUhEUgAAAEAAAAAQCAIAAAAphe5+AAAASUlEQVR4nGKpYvNloCV4IfaOpuYz0dR0OoBRDww0GPXAQINRDww0YEx7coamFuQY6NHU/CEfA6MeGGgw6oGBBqMeGGgACAAA//8NQwW7nxQtiQAAAABJRU5ErkJggg==", "iVBORw0KGgoAAAANSUhEUgAAAEAAAAAQCAIAAAAphe5+AAAASUlEQVR4nGKpFz7OQEuwzTuUpuYz0dR0OoBRDww0GPXAQINRDww0YExQF6epBRzCHjQ1f8jHwKgHBhqMemCgwagHBhoAAgAA//+tzAPSsWq5EgAAAABJRU5ErkJggg==", "iVBORw0KGgoAAAANSUhEUgAAAEAAAAAQCAIAAAAphe5+AAAASUlEQVR4nGLheFXJQEsQozGXpuYz0dR0OoBRDww0GPXAQINRDww0YFH/epqmFnjoKdHU/CEfA6MeGGgw6oGBBqMeGGgACAAA//8WFAUwWcqqFQAAAABJRU5ErkJggg==", "iVBORw0KGgoAAAANSUhEUgAAAEAAAAAQCAIAAAAphe5+AAAASUlEQVR4nGLx8+9koCV4pMBOU/OZaGo6HcCoBwYajHpgoMGoBwYasDhw9NDUgksRQjQ1f8jHwKgHBhqMemCgwagHBhoAAgAA///lsARka8dM9wAAAABJRU5ErkJggg=="];

    return images[Math.floor(Math.random() * images.length)];
  }

  exports.testImage = testImage;
});