define('bottomline-execution/components/material-transfer-compartment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['material-transfer-compartment'],
    classNameBindings: ['viewType', 'isSelected'],
    viewType: 'source',
    selectedStorage: null,
    truckStorage: null,
    isSelected: Ember.computed('selectedStorage', 'truckStorage', function () {
      return this.get('selectedStorage') === this.get('truckStorage');
    })
  });
});