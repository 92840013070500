define('bottomline-execution/services/localization-ajax', ['exports', 'bottomline-execution/services/ajax', 'bottomline-execution/config/environment'], function (exports, _ajax, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    host: Ember.computed(function () {
      return _environment.default.i18n.endpoint;
    })
  });
});