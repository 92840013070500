define('bottomline-execution/components/details-end-inventory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'storage-compartments',
    tagName: 'div',

    appConfiguration: Ember.inject.service(),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    tripStopStorage: null
  });
});