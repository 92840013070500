define('bottomline-execution/routes/trip-stops/show/index', ['exports', 'bottomline-execution/mixins/planned-or-confirmed-trip-stop-storages', 'bottomline-execution/mixins/trip-stop-type'], function (exports, _plannedOrConfirmedTripStopStorages, _tripStopType) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_plannedOrConfirmedTripStopStorages.default, _tripStopType.default, {
    model: function model(_, transition) {
      var tripId = transition.params['trips.show'].trip_id;
      var tripStopId = transition.params['trip-stops.show'].trip_stop_id;

      var recordType = this.tripStopType(tripId, tripStopId);

      return this.get('store').peekRecord(recordType, tripStopId);
    },
    setupController: function setupController(controller, model) {
      if (model.get('isLoad')) {
        model.get('trip_stop_storages').forEach(function (tripStopStorage) {
          tripStopStorage.calculateTotalCompartmentQty();
        });
      } else {
        controller.resetUnloadCompartmentFilters();

        var unloadTripStopStorage = this._tripStopStorages(model).sortBy('storage.SequenceNb').get('firstObject');
        controller.set('unloadTripStopStorage', unloadTripStopStorage);
      }

      this._super(controller, model);
    },
    _tripStopStorages: function _tripStopStorages(tripStop) {
      var plannedOrConfirmedTripStopStorages = this.plannedOrConfirmedTripStopStorages({ tripStop: tripStop,
        tripStopStorages: tripStop.get('trip_stop_storages'),
        storageGroupID: tripStop.get('StorageGroupID'),
        includeUnplannedStorages: false
      });

      if (Ember.isPresent(plannedOrConfirmedTripStopStorages)) {
        return plannedOrConfirmedTripStopStorages;
      } else {
        return tripStop.get('trip_stop_storages');
      }
    }
  });
});