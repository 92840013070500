define('bottomline-execution/components/material-transfer', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    scopedData: Ember.inject.service(),
    toast: Ember.inject.service(),
    i18n: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    vehicleStorages: Ember.inject.service(),

    returnToTripIndex: "",

    classNames: ['views', 'material-transfer'],
    tripStop: null,
    trip: Ember.computed.alias('tripStop.trip'),

    isSourceFromTrailer: false,
    isTargetFromTrailer: false,

    selectedSource: null,
    selectedTarget: null,

    transferAmount: null,

    hasSourceAndTarget: Ember.computed.and('selectedSource', 'selectedTarget'),

    maxTargetFreeVolume: Ember.computed('selectedSource', 'selectedTarget', 'selectedTarget.truckInventory.currentQty', function () {
      var selectedSource = this.get('selectedSource');
      var selectedTarget = this.get('selectedTarget');
      if (selectedSource && selectedTarget) {
        var targetCurrentQty = selectedTarget.get('truckInventory.currentQty');
        var targetMaxQty = selectedTarget.get('truckInventory.maxQty');

        var freeVolume = void 0;
        if (targetCurrentQty >= 0) {
          freeVolume = targetMaxQty - targetCurrentQty;
        } else {
          freeVolume = targetMaxQty;
        }

        if (freeVolume > 0) {
          return freeVolume;
        }
      }

      return 0;
    }),

    maxTargetFreeVolumeInclAllowableExcess: Ember.computed('maxTargetFreeVolume', function () {
      var allowableExcessCoefficient = 1.02;
      return this.get('maxTargetFreeVolume') * allowableExcessCoefficient;
    }),

    hintOfFreeSpace: Ember.computed('transferAmount', 'maxTargetFreeVolume', function () {
      var maxTargetFreeVolume = this.get('maxTargetFreeVolume');
      var transferAmount = this.get('transferAmount');

      return maxTargetFreeVolume - transferAmount;
    }),

    hintOfSourceAvailableQty: Ember.computed('selectedSource.truckInventory.currentQty', 'transferAmount', function () {
      return this.get('selectedSource.truckInventory.currentQty') - this.get('transferAmount');
    }),

    isValidTransferAmount: Ember.computed('transferAmount', 'maxTargetFreeVolumeInclAllowableExcess', function () {
      var maxTargetFreeVolumeInclAllowableExcess = parseInt(this.get('maxTargetFreeVolumeInclAllowableExcess'));
      var transferAmount = parseInt(this.get('transferAmount'));
      var sourceCurrentQty = parseInt(this.get('selectedSource.truckInventory.currentQty'));

      return transferAmount > 0 && transferAmount <= sourceCurrentQty && transferAmount <= maxTargetFreeVolumeInclAllowableExcess;
    }),

    truckCompartments: Ember.computed('tripStop', function () {
      return this.get('vehicleStorages').truckStorages();
    }),

    trailerCompartments: Ember.computed('tripStop', function () {
      return this.get('vehicleStorages').trailerStorages();
    }),

    hasTruckAndTrailerCompartments: Ember.computed.and('truckCompartments.length', 'trailerCompartments.length'),

    hasTruckStorages: Ember.computed('appState.currentTruck', function () {
      return this.get('vehicleStorages').hasTruckStorages();
    }),

    hasTrailerStorages: Ember.computed('appState.currentTrailer', function () {
      return this.get('vehicleStorages').hasTrailerStorages();
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this._prepareNewMaterialTransferStop();
    },
    willDestroyElement: function willDestroyElement() {
      var tripStop = this.get('tripStop');

      if (tripStop.get('isNew')) {
        tripStop.unloadRecord();
      }
    },
    tripStopTruckStorageByTruckStorage: function tripStopTruckStorageByTruckStorage(truckStorage) {
      var truckInventory = truckStorage.get('truckInventory');
      var newTripStop = this.get('tripStop');
      var trip = newTripStop.get('trip');

      var tripStopTruckStorage = newTripStop.get('trip_stop_truck_storages').find(function (item) {
        return item.get('TruckStorageID') === truckInventory.get('truck_storage_id');
      });

      return tripStopTruckStorage || newTripStop.get('trip_stop_truck_storages').createRecord({
        id: trip.createGuid('from material transfer'),
        TripID: Number(trip.get('id')),
        ActivityCode: newTripStop.get('ActivityCode'),
        trip: trip,
        TripStopID: newTripStop.get('id'),
        TruckID: truckInventory.get('truck_id'),
        MaterialID: truckInventory.get('material_id'),
        confirmedQty: 0,
        TruckStorageID: truckInventory.get('truck_storage_id'),
        plannedQty: 0
      });
    },
    tripStopStorageByTruckStorage: function tripStopStorageByTruckStorage(truckStorage) {
      var truckInventory = truckStorage.get('truckInventory');
      var newTripStop = this.get('tripStop');
      var trip = newTripStop.get('trip');

      var tripStopStorage = newTripStop.get('trip_stop_storages').find(function (item) {
        return item.get('TruckStorageID') === truckInventory.get('truck_storage_id');
      });

      return tripStopStorage || newTripStop.get('trip_stop_storages').createRecord({
        id: trip.createGuid(),
        TripID: Number(trip.get('id')),
        trip: trip,
        TripStopID: newTripStop.get('id'),
        MaterialID: truckInventory.get('material_id'),
        confirmedQty: 0,
        plannedQty: 0
      });
    },
    setAmount: function setAmount(amount) {
      var maxTargetFreeVolumeInclAllowableExcess = this.get('maxTargetFreeVolumeInclAllowableExcess');

      this.set('transferAmount', parseInt(amount > maxTargetFreeVolumeInclAllowableExcess ? maxTargetFreeVolumeInclAllowableExcess : amount));
    },


    actions: {
      cancel: function cancel() {
        this.get('trip').calculateTruckInventoryPerCompartment();
        this.get('tripStop').unloadRecord();
        this.get('returnToTripIndex')();
      },
      selectSource: function selectSource(truckStorage) {
        this.send('clearSelectedTarget');
        this.set('selectedSource', truckStorage);
        this.set('transferAmount', null);
      },
      selectTarget: function selectTarget(truckStorage) {
        var selectedSource = this.get('selectedSource');
        var selectedTarget = this.get('selectedTarget');

        if (selectedSource === truckStorage) {
          return this.get('toast').error('Select different compartments');
        }

        this.set('selectedTarget', selectedTarget !== truckStorage ? truckStorage : null);
      },
      clearSelectedSource: function clearSelectedSource() {
        this.set('selectedSource', null);
        this.set('selectedTarget', null);
        this.set('transferAmount', null);
      },
      clearSelectedTarget: function clearSelectedTarget() {
        this.set('selectedTarget', null);
        this.set('transferAmount', null);
      },
      saveMaterialTransition: function saveMaterialTransition() {
        var _this = this;

        var _getProperties = this.getProperties('selectedSource', 'selectedTarget'),
            selectedSource = _getProperties.selectedSource,
            selectedTarget = _getProperties.selectedTarget;

        var transferAmount = Number(this.get('transferAmount'));
        var isValidTransferAmount = this.get('isValidTransferAmount');

        if (isValidTransferAmount && selectedSource && selectedTarget) {
          var tripStop = this.get('tripStop');
          var newSourceAmount = -transferAmount;
          var newTargetAmount = +transferAmount;
          this.set('transferAmount', null);

          var oldTargetAmount = selectedTarget.get('truckInventory.currentQty');

          selectedSource.set('currentQty', newSourceAmount);
          selectedTarget.set('currentQty', newTargetAmount);

          var sourceTripStopTruckStorage = this.tripStopTruckStorageByTruckStorage(selectedSource);
          var targetTripStopTruckStorage = this.tripStopTruckStorageByTruckStorage(selectedTarget);

          sourceTripStopTruckStorage.set('confirmedQty', newSourceAmount);

          var sourceTripStopStorage = this.tripStopStorageByTruckStorage(selectedSource);
          var targetTripStopStorage = this.tripStopStorageByTruckStorage(selectedTarget);

          if (sourceTripStopTruckStorage.get('MaterialID') != targetTripStopTruckStorage.get('MaterialID')) {
            sourceTripStopStorage.set('confirmedQty', oldTargetAmount);
            targetTripStopStorage.set('confirmedQty', -1 * oldTargetAmount);
          }

          targetTripStopTruckStorage.setProperties({
            confirmedQty: newTargetAmount,
            MaterialID: sourceTripStopTruckStorage.get('MaterialID')
          });

          selectedTarget.get('truckInventory').set('material_id', sourceTripStopTruckStorage.get('MaterialID'));

          tripStop.start();
          tripStop.get('trip.trip_stops').pushObject(tripStop);

          this.get('f7').showPreloader();

          tripStop.save().then(function () {
            return tripStop.confirm().then(function () {
              _this.get('toast').info(_this.get('i18n').t('material_transfer_success'));
            });
          }).catch(function (error) {
            console.error(error);
            _this.get('toast').error(_this.get('i18n').t('material_transfer_error'));
          }).finally(function () {
            _this.get('f7').hidePreloader();
            _this.get('returnToTripIndex')();
          });
        }
      },
      setAmountCompletely: function setAmountCompletely(amount, source) {
        if (this.get('selectedSource') === source) {
          this.setAmount(amount);
        }
      },
      updateTransferAmount: function updateTransferAmount(amount) {
        if (amount) {
          this.setAmount(amount);
        }
      }
    },

    _prepareNewMaterialTransferStop: function _prepareNewMaterialTransferStop() {
      var startedTrip = this.get('startedTrip');

      var newTripStop = this.get('store').createRecord('trip-stop', {
        TripID: startedTrip.get('id'),
        trip: startedTrip,
        SequenceNb: startedTrip.getMaxTripStopSequenceNb(),
        ActivityCode: 'M',
        TruckID: startedTrip.get('TruckID'),
        ResourceID: startedTrip.get('ResourceID'),
        plannedQty: 0,
        state: 'finished',
        ConfirmedSequenceNb: startedTrip.getMaxTripStopSequenceNb(),
        ConfirmedArrivalDT: new Date(),
        ConfirmedDepartureDT: new Date()
      });

      this.set('tripStop', newTripStop);
    }
  });
});