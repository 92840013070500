define('bottomline-execution/mirage/factories/trip-stop-storage', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    confirmed_qty: null,
    material_id: 1,
    planned_qty: 0,
    trip_id: null,
    trip_stop_id: null
  });
});