define('bottomline-execution/utils/file-to-b64-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  // path param is a file reader entry object nativeURL property like this:
  // file:///storage/emulated/0/Android/data/eu.bottomline.Execution/files/ember-912eb41f-b1a9-4a33-8f8c-109d272681af_TripStop_3767002_png__-1.jpeg
  function fileToB64Util(filePath) {
    return new Ember.RSVP.Promise(function (resolve, reject) {
      window.resolveLocalFileSystemURL(filePath, success, error);

      function success(fileEntry) {
        var fileReader = new FileReader();

        fileReader.onloadend = function (event) {
          resolve(event.target.result);
        };

        fileEntry.file(function (file) {
          return fileReader.readAsDataURL(file);
        });
      }

      function error(error) {
        reject(error);
      }
    });
  }

  exports.fileToB64Util = fileToB64Util;
});