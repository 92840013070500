define('bottomline-execution/mirage/config', ['exports', 'bottomline-execution/config/environment', 'bottomline-execution/mirage/localizations'], function (exports, _environment, _localizations) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    _environment.default.i18n.endpoint = _environment.default.host + '/' + _environment.default.api;

    this.urlPrefix = _environment.default.host;
    this.namespace = _environment.default.api;
    this.timing = 0;

    this.passthrough('/truck_positions', ['get', 'post', 'options']);
    this.passthrough('//maps.googleapis.com/maps/api/js');

    this.get('/pictures');
    this.get('/extra_times');
    this.get('/trucks');
    this.get('/trailers');
    this.get('/otc_materials');
    this.get('/adr_materials');
    this.get('/material_sellers');
    this.get('/materials');

    this.get(_environment.default.host + '/settings.json', function () {
      return {};
    });

    this.get('/localizations', function () {
      return _localizations.default;
    });
    this.get('/trips');
    this.get('/shifts', function (db) {
      return {
        shifts: db.shifts.all().models.map(function (shift) {
          return Object.assign({}, shift, { truck_inventories: db.truckInventories.all().models });
        }),
        meta: {
          total_pages: 1,
          shiftEvents: [{ name: 'start', options: { from: ['planned', 'paused'], to: 'started' } }, { name: 'finish', options: { from: ['started'], to: 'finished' } }],
          tripEvents: [{ name: 'start', options: { from: ['planned', 'paused'], to: 'started' } }, { name: 'finish', options: { from: ['started'], to: 'finished' } }],
          tripStopEvents: [{ name: 'start', options: { from: ['planned', 'paused'], to: 'started' } }, { name: 'finish', options: { from: ['started'], to: 'finished' } }]
        }
      };
    });

    this.get('/shifts/:id');
    this.get('/trips/:id');

    this.post('/users/sign_in', function (db, request) {
      var payload = JSON.parse(request.requestBody.split('=').reverse()[0]);

      return {
        user_name: payload.user.user_name, user_display_name: payload.user.user_display_name, user_email: 'trucker', user_token: 'oeleo',
        user_role: 'driver'
      };
    });

    this.post('truck_settings', function (db, request) {
      var settings = JSON.parse(request.requestBody).truck_settings;

      return { truck_settings: Object.assign({}, settings, { id: Date.now() }) };
    });

    this.post('shifts/:id/update_state', function (db, req) {
      return { shift: db.shifts.all().update(req.params.id, { state: req.params.state }) };
    });
  };
});