define('bottomline-execution/services/ember-leaflet', ['exports', 'ember-leaflet/services/ember-leaflet'], function (exports, _emberLeaflet) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _emberLeaflet.default;
    }
  });
});