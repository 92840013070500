define('bottomline-execution/components/trips/create-special-activity', ['exports', 'bottomline-execution/utils/guid-util'], function (exports, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),

    elapsedTime: '-',
    iId: null,
    specialActivities: Ember.computed('parentController.addActivity', function () {
      return this.get('store').peekAll('extra-time');
    }),

    newTripStop: Ember.computed('model', function () {
      var started = this.get('store').peekAll('trip-stop').filterBy('state', 'started').findBy('TripID', this.parentController.get('startedTrip.id'));

      if (Ember.isPresent(started)) {
        return started;
      }

      var planned = this.get('store').peekAll('trip-stop').filterBy('state', 'planned').findBy('TripID', this.parentController.get('startedTrip.id'));

      if (Ember.isPresent(planned)) {
        return planned;
      }

      return this.get('model.trip_stops').createRecord({
        id: (0, _guidUtil.guidUtil)(),
        TripID: this.get('model.id'),
        TruckID: this.get('model.TruckID'),
        ResourceID: this.get('model.ResourceID'),
        state: 'planned',
        PlannedArrivalDT: new Date(),
        PlannedDepartureDT: new Date(),
        ActivityCode: 'T'
      });
    }),

    willDestroyElement: function willDestroyElement() {
      if (this.get('newTripStop.isNew')) {
        this.get('newTripStop').unloadRecord();
      }

      this.get('parentController').set('addActivity', false);
    },


    actions: {
      cancel: function cancel() {
        this.get('newTripStop').unloadRecord();
        this.get('parentController').set('addActivity', false);
      },
      updateState: function updateState(state) {
        if (state.state === 'started') {
          this.get('newTripStop').setProperties({
            SequenceNb: this.get('model').getMaxTripStopSequenceNb() + 800,
            ConfirmedArrivalDT: new Date(),
            state: 'started'
          });
        } else if (state.state === 'finished') {
          this.get('newTripStop').setProperties({
            ConfirmedSequenceNb: this.get('model').getMaxTripStopSequenceNb(),
            ConfirmedDepartureDT: new Date(),
            state: 'finished'
          });
          this.get('newTripStop').confirm();
          this.get('parentController').set('addActivity', false);
        }
      }
    }
  });
});