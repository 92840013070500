define('bottomline-execution/controllers/trips/material', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    tripsController: Ember.inject.controller('trips/index'),

    tripStop: Ember.computed('tripsController.selectedTripStop', function () {
      var model = this.get('parentController').get('model');

      if (model.id) {
        return model;
      }

      return this.get('tripsController.selectedTripStop');
    }),

    tripStopInventory: Ember.computed('tripStop', 'model', 'tripStop.trip.shift.truck_inventories', function () {
      var _this = this;
      var tripStop = this.get('tripStop');
      if (tripStop) {
        var tripStopStorages = tripStop.get('trip_stop_storages');
        return this.groupBy(tripStopStorages, 'MaterialID', ['plannedQty']).map(function (item) {
          var material = item.get('items').get('firstObject').get('material');
          var currentTruckInventory = _this.currentTruckInventory().findBy('group', item.group);
          var qtyInTruck = 0;

          if (currentTruckInventory) {
            qtyInTruck = currentTruckInventory.totalcurrentQty;
          } else {
            qtyInTruck = 0;
          }

          item.set('material', material);
          item.set('qtyInTruck', qtyInTruck);

          return item;
        });
      } else {
        return this.groupBy(_this.get('model'), 'material_id', ['currentQty']).map(function (item) {
          return item;
        });
      }
    }),

    currentTruckInventory: function currentTruckInventory() {
      var inventories = this.get('tripStop').get('trip').get('shift').get('truck_inventories');

      return this.groupBy(inventories, 'material_id', ['currentQty']);
    },


    tripStopMaterials: Ember.computed('tripStop', function () {
      if (this.get('tripStop')) {
        return this.get('tripStop').get('trip_stop_storages').getEach('MaterialID');
      }
    }),

    groupBy: function groupBy(list, group, totals) {
      var groupedResult = Ember.A([]);
      var groups = list.getEach(group).uniq();

      groups.forEach(function (item) {
        var currentGroup = new Ember.Object();

        var itemsForGroup = list.filterBy(group, item);

        currentGroup.set('items', itemsForGroup);
        currentGroup.set('group', item);
        totals.forEach(function (total) {
          currentGroup.set('total' + total, itemsForGroup.reduce(function (prev, item) {
            return prev + parseInt(item.get(total));
          }, 0));
        });

        if (itemsForGroup.length > 0) {
          groupedResult.push(currentGroup);
        }
      });

      return groupedResult;
    }
  });
});