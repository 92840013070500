define('bottomline-execution/components/site-load-data', ['exports', 'bottomline-execution/mixins/storages-loader'], function (exports, _storagesLoader) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_storagesLoader.default, {
    store: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    selectedTripStop: null,
    tripStopStorages: Ember.computed.alias('selectedTripStop.trip_stop_storages'),
    allSiteStorages: Ember.computed.alias('selectedTripStop.site.storages'),
    areDetailsShown: false,
    isAtgInfoLoading: false,

    sortedTripStopStorages: Ember.computed('tripStopStorages', function () {
      var tripStopStorages = this.get('tripStopStorages');
      return Ember.isPresent(tripStopStorages) ? tripStopStorages.sortBy('storage.SequenceNb') : null;
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('internetConnection.isGoodQuality')) {
        this.updateAtgInformation();
      }
    },
    updateAtgInformation: function updateAtgInformation() {
      var _this = this;

      this.set('isAtgInfoLoading', true);
      this._loadRecentInventories(this.allSiteStorages).finally(function () {
        _this.set('isAtgInfoLoading', false);
      });
    },


    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('areDetailsShown');
      }
    }
  });
});