define('bottomline-execution/services/vehicle-storages', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    truckStorages: function truckStorages() {
      return this._vehicleStorages('truck');
    },
    trailerStorages: function trailerStorages() {
      return this._vehicleStorages('trailer');
    },
    hasTruckAndTrailerStorages: function hasTruckAndTrailerStorages() {
      return this.truckStorages().length && this.trailerStorages().length;
    },
    hasTruckStorages: function hasTruckStorages() {
      return this.truckStorages().length > 0;
    },
    hasTrailerStorages: function hasTrailerStorages() {
      return this.trailerStorages().length > 0;
    },
    _vehicleStorages: function _vehicleStorages() {
      var vehicle = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'truck';

      return this.get('store').peekAll('truck-storage').filter(function (truckStorage) {
        return truckStorage.get('vehicle_type') === vehicle;
      }).sortBy('truck_storage_id').reverse();
    }
  });
});