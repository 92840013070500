define('bottomline-execution/controllers/truck-settings', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    internetConnection: Ember.inject.service(),

    actions: {
      return: function _return() {
        this.get('internetConnection.isGoodQuality') && this.transitionToRoute('shifts.index');
      }
    }
  });
});