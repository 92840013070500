define('bottomline-execution/models/be/trip-stop', ['exports', 'bottomline-execution/models/trip-stop'], function (exports, _tripStop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _tripStop.default.extend({
    plannedMaterials: Ember.computed('plannedTripStopStorages', function () {
      return this.get('plannedTripStopStorages').map(function (tripStopStorage) {
        return tripStopStorage.get('material');
      }).uniq();
    })
  });
});