define('bottomline-execution/instance-initializers/ember-devtools', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    initialize: function initialize(appInstance) {
      var devToolsConfig = _environment.default['ember-devtools'] || {};
      var enabled = devToolsConfig.enabled;
      if (enabled === undefined) {
        enabled = /(development|test)/.test(_environment.default.environment);
      }
      if (!enabled) return;
      var service = 'service:ember-devtools';
      var devTools = appInstance.lookup ? appInstance.lookup(service)
      // backwards compatibility < 2.1
      : appInstance.container.lookup(service);
      if (devToolsConfig.global === true) {
        devTools.globalize();
      } else if (devToolsConfig.global) {
        window[devToolsConfig.global] = devTools;
      }
    }
  };
});