define('bottomline-execution/utils/storage-id-for-material-id-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function storageIdForMaterialIDUtil(id, storages) {
    var storageId = void 0;

    var storage = storages.findBy('MaterialID', id);
    if (Ember.isPresent(storage)) {
      storageId = Number(storage.id);
    }

    return storageId;
  }

  exports.storageIdForMaterialIDUtil = storageIdForMaterialIDUtil;
});