define('bottomline-execution/serializers/trip-stop-storage', ['exports', 'ember-data', 'active-model-adapter'], function (exports, _emberData, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    isNewSerializerAPI: true,
    attrs: {
      storage: { embedded: 'always' },
      confirmed15Qty: { key: 'Confirmed15Qty' }
    },

    serialize: function serialize(snapshot, options) {
      if (options.purpose === 'persistPredip') return this._serializeToPersistPredip(snapshot);
      return this._super.apply(this, arguments);
    },
    _serializeToPersistPredip: function _serializeToPersistPredip(snapshot) {
      return {
        trip_stop_storage_id: snapshot.id,
        start_inventory_qty: snapshot.attr('startInventoryQty')
      };
    }
  });
});