define('bottomline-execution/serializers/bx/start-inventory', ['exports', 'bottomline-execution/utils/guid-util'], function (exports, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    store: null,
    tripId: null,
    startInventory: null,
    startInventoryStopId: Ember.computed('tripId', function () {
      return this.get('tripId') + ':S';
    }),
    tripStopStorages: null,

    build: function build(tripId, startInventory, tripStopStorages) {
      this.set('tripId', tripId);
      this.set('startInventory', startInventory.inventories);
      this.set('tripStopStorages', tripStopStorages);

      this._unloadObsoleteStartInventoryAssociations('trip-stop-storage');
      this._unloadObsoleteStartInventoryAssociations('trip-stop-truck-storage');

      var startStopStorages = this._buildTripStopStorages();
      var startStopTruckStorages = this._buildTripStopTruckStorages();

      // Analogous to createStartInventoryStop() in the trip model
      var startStop = {
        id: this.get('startInventoryStopId'),
        type: 'trip-stop',
        attributes: {
          ActivityCode: 'S',
          ConfirmedDepartureDT: startInventory.timestamp,
          SequenceNb: -1,
          state: 'finished',
          TripID: this.get('tripId')
        },
        relationships: {
          'trip_stop_storages': {
            data: startStopStorages.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return { type: type, id: id };
            })
          },
          'trip_stop_truck_storages': {
            data: startStopTruckStorages.map(function (_ref2) {
              var type = _ref2.type,
                  id = _ref2.id;
              return { type: type, id: id };
            })
          }
        }
      };

      return { tripStop: startStop,
        tripStopStorages: { data: startStopStorages },
        tripStopTruckStorages: { data: startStopTruckStorages } };
    },
    _unloadObsoleteStartInventoryAssociations: function _unloadObsoleteStartInventoryAssociations(model) {
      this.get('store').peekAll(model).filterBy('TripStopID', this.get('startInventoryStopId')).invoke('unloadRecord');
    },


    // Analogous to the trip stop storages part of setStartInventory() in the trip model
    _buildTripStopStorages: function _buildTripStopStorages() {
      var _this = this;

      var startInventories = Ember.A([]).concat(this.get('startInventory')).mapBy('compartment_inventories').flatten();

      var startInventoryMaterialIds = startInventories.mapBy('product_id');

      var tripStopsMaterialIds = this.get('tripStopStorages').mapBy('data').flatten().mapBy('attributes').mapBy('MaterialID');

      var materialIds = [].concat(startInventoryMaterialIds, tripStopsMaterialIds).compact();

      var tripStopStorages = materialIds.map(function (materialId) {
        var materialQtyAtStart = startInventories.filterBy('product_id', materialId).reduce(function (qty, inv) {
          return qty += inv.volume_in_liters;
        }, 0);
        return {
          id: _this.get('startInventoryStopId') + '_' + materialId,
          type: 'trip-stop-storage',
          attributes: {
            confirmedQty: materialQtyAtStart,
            MaterialID: materialId,
            TripID: _this.get('tripId'),
            TripStopID: _this.get('startInventoryStopId')
          }
        };
      });

      return tripStopStorages;
    },


    // Analogous to the trip stop truck storages part of setStartInventory() in the trip model
    _buildTripStopTruckStorages: function _buildTripStopTruckStorages() {
      var _this2 = this;

      var tripStopTruckStorages = this.get('startInventory').map(function (vehicle) {
        return vehicle.compartment_inventories.map(function (inventory) {
          return {
            id: (0, _guidUtil.guidUtil)(),
            type: 'trip-stop-truck-storage',
            attributes: {
              confirmedQty: inventory.volume_in_liters,
              MaterialID: inventory.product_id,
              TripID: _this2.get('tripId'),
              TripStopID: _this2.get('startInventoryStopId'),
              TruckID: vehicle.vehicle_id,
              TruckStorageID: Number(inventory.number)
            }
          };
        });
      }).flatten();

      return tripStopTruckStorages;
    }
  });
});