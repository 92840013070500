define('bottomline-execution/components/odometer-field', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ATX_ENDPOINT = 'content://com.groeneveldictsolutions.android.atxapi.provider.VehicleInfoProvider/STATUS';

  exports.default = Ember.Component.extend({
    tagName: 'li',
    odometerValue: '',

    isAtx: Ember.computed(function () {
      return _environment.default.APP.target === 'atx' && typeof plugins !== 'undefined';
    }),

    didInsertElement: function didInsertElement() {
      if (this.get('isAtx')) {
        this.getMileageFromAtx();
      }
    },
    getMileageFromAtx: function getMileageFromAtx() {
      var _this = this;

      plugins.contentproviderplugin.query({ contentUri: ATX_ENDPOINT }, function (data) {
        _this.set('odometerValue', Number(data[0].mileage));
      });
    },

    actions: {
      getMileage: function getMileage() {
        this.getMileageFromAtx();
      }
    }
  });
});