define('bottomline-execution/controllers/trip-stops/change-terminal', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    i18n: Ember.inject.service(),
    toast: Ember.inject.service(),

    currentLoadStop: null,

    changeTerminal: function changeTerminal(terminal) {
      var _this = this;

      var currentLoadStop = this.get('currentLoadStop');
      var loadStopProperties = currentLoadStop.getProperties('ActivityCode', 'BarrelCount', 'ConfirmedArrivalDT', 'ConfirmedDepartureDT', 'ConfirmedSequenceNb', 'confirmedQty', 'DriverRemarks', 'ExtOrderID', 'ExtraTimeId', 'Odometer', 'OrderID', 'plannedQty', 'ResourceID', 'SequenceNb', 'state', 'StorageGroupID', 'TruckID');

      Object.assign(loadStopProperties, { 'SiteID': terminal.id });

      var trip = currentLoadStop.get('trip');

      var newLoadStop = trip.get('trip_stops').createRecord(loadStopProperties);

      newLoadStop.save().then(function () {
        currentLoadStop.destroyRecord().then(function () {
          _this.transitionToRoute('trips');
        }).catch(function (error) {
          _this.get('toast').info(_this.get('i18n').t('change_terminal.failure') + error);
        });
      }).catch(function (error) {
        _this.get('toast').info(_this.get('i18n').t('change_terminal.failure') + error);
      });
    },


    actions: {
      selectTerminal: function selectTerminal(terminal) {
        this.set('model.selectedTerminal', terminal);
      },
      changeTerminal: function changeTerminal(terminal) {
        var _this2 = this;

        this.get('f7').confirm(this.get('i18n').t('change_terminal_dialog.warning') + ' ' + terminal.get('Name'), this.get('i18n').t('change_terminal_dialog.title'), Ember.run.bind(this, function () {
          _this2.changeTerminal(terminal);
        }));
      }
    }
  });
});