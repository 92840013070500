define('bottomline-execution/services/jsreport', ['exports', 'bottomline-execution/utils/data-uri-to-blob-util'], function (exports, _dataUriToBlobUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cordovaPlatform: Ember.inject.service('ember-cordova/platform'),
    ajax: Ember.inject.service(),

    postRequest: function postRequest(reportTemplateName, reportFileName, reportData) {
      var _this = this;

      var request = this.get('ajax').post('/reports', {
        data: JSON.stringify({
          template_name: reportTemplateName,
          file_name: reportFileName,
          data: reportData
        })
      });

      return request.then(function (response) {
        var blob = (0, _dataUriToBlobUtil.dataURItoBlobUtil)(response.file);
        _this._createFile(blob, reportFileName);
        return blob;
      });
    },
    _createFile: function _createFile(blob, fileName) {
      var _this2 = this;

      var fileReader = new FileReader(); // reads the given blobresult

      fileReader.onloadend = function (e) {
        if (_this2.get('cordovaPlatform.isCordova')) {
          cordova.plugins.fileOpener2.openBase64(fileName.split('.')[0], '.pdf', e.currentTarget.result.split('base64')[1], 'application/pdf');
        } else {
          var windowUrl = window.URL || window.webkitURL;
          var link = document.createElement('a');
          link.href = windowUrl.createObjectURL(blob);
          link.download = fileName;
          link.click();
          windowUrl.revokeObjectURL(link);
        }
      };

      fileReader.readAsDataURL(blob);
    }
  });
});