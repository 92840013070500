define("bottomline-execution/mirage/localizations", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "en": {
      "login": "Login",
      "logout": "Logout",
      "login_failed": "Login failed",
      "truck": "Truck1",
      "sites": "Sites",
      "change_truck": "change truck",
      "sync": {
        "warning": "You will lose all unsaved data, are you sure to proceed?",
        "title": "Pull changes from server"
      },
      "username": "Username",
      "password": "Password",
      "please_sign_in": "Please sign in",
      "settings": "Settings",
      "truck_settings": "Truck settings",
      "domain": "Domain",
      "language": "Language",
      "save": "Save",
      "shifts": "Shifts",
      "finish": "Finish",
      "finished": "Finished",
      "detail_information": "Details",
      "details_trailer": "Details trailer",
      "details_truck": "Details truck",
      "tanks": "tanks",
      "fill_it": "fill",
      "tank": "tank",
      "pause": "Pause",
      "start": "Start",
      "restart": "Restart",
      "map": "Map",
      "trip_stops_for_shift": "Tripstops for selected shift",
      "trip_stop_storages": "Tripstop storages",
      "trip": "Trip",
      "ticket": "Ticket",
      "report": "Report",
      "site": "Site",
      "details": "Details",
      "planned": "Planned",
      "remarks": "Remarks",
      "no_remarks": "No remarks",
      "material_on_truck": "Material on truck",
      "select_trip_stop": "Select trip-stop",
      "actions": "Actions",
      "add_stop_from_site": "Add trip-stop from site",
      "materials": "Materials",
      "materials_in_weight": "Material in weight",
      "save_location_to_site": "Save current location",
      "form_incomplete": "Form invalid",
      "view_pictures": "View pictures",
      "confirm": "Confirm",
      "cancel": "Cancel",
      "take_picture": "Take picture",
      "extra_information": "Extra information",
      "use_weight": "Use weight",
      "register_at_15_d": "Register liters (15)",
      "delivery": "Delivery",
      "delivery_truck_information": "Delivery truck information",
      "storages": "Tanks",
      "add_trip_stop": "Rit-stop toevoegen",
      "load": {
        "title": "Load"
      },
      "end_inventory": {
        "title": "End inventory management"
      },
      "edit_truck": {
        "title": "Select truck"
      },
      "unload": {
        "title": "Unload",
        "detail_information": "Details"
      },
      "column": {
        "SiteName": "Site name",
        "compartiments": "Compartiments",
        "TruckID": "Truck ID",
        "TrailerID": "Trailer ID",
        "ResourceID": "Driver",
        "ExtSiteID": "Site ID",
        "OrderID": "Order ID",
        "ActualInventory": "Actual inventory",
        "CurrentInventory": "Current inventory",
        "Telephone": "Phone",
        "Address": "Address",
        "delivery_ticket_number": "Delivery ticket number",
        "InventoryDT": "Inventory dt",
        "UsableStorageQty": "Useable qty",
        "InventoryQty": "Current qty",
        "AvailableQty": "Available qty",
        "Name": "Naam",
        "PlannedDeliveryQty": "Planned",
        "PlannedQty": "Planned",
        "planned": "Planned",
        "start": "At start",
        "ConfirmedQty": "Confirmed",
        "Confirmed15Qty": "Confirmed 15d",
        "Status": "Status",
        "ConfirmedDeliveryQty": "Confirmed",
        "confirmed_volume": "Confirmed volume",
        "total_load": "Total load",
        "Material": "Material",
        "material": "Material",
        "CurrentQty": "Current",
        "Site": "Site",
        "driver_remarks": "Driver remarks",
        "load_id": "Load id",
        "odometer": "Odometer",
        "Temperature": "Temperature",
        "Density": "Density",
        "confirmed15Qty": "Confirmed (15)",
        "confirmed_weight": "Confirmed weight",
        "StartInventoryQty": "Start inventory",
        "AvailableStorageQty": "Max storage",
        "Compartments": "Compartments"
      },
      "truck_setting": {
        "compartments": "Compartments",
        "load_by_weight": "Load by weight",
        "settings": "Truck settings",
        "adding_extra_trip_stops": "Adding extra trip stops",
        "save": "Save",
        "cancel": "Cancel",
        "add_special_activity": "Adding special activity",
        "questionaire": "Asking questions"
      },
      "description_language": "English",
      "description": "Description",
      "add": "Add",
      "add_language": "Add new language",
      "clear": "Clear",
      "detail": "Detail",
      "truck_is_empty": "Empty truck",
      "empty_compartments": "Empty compartments",
      "correction": "Correct by compartments",
      "notify": "Notification",
      "questions": {
        "continue": "Continue",
        "title": "Ask questions",
        "save": "Save",
        "reason_not_confirmed": "Reason"
      }
    }
  };
});