define('bottomline-execution/services/meter-counter-pollster', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    logger: Ember.inject.service('remote-logger'),
    emis: Ember.inject.service(),
    store: Ember.inject.service(),
    interval: 50000,
    timerPid: '',

    meterCount: Ember.computed.alias('emis.meterCount'),

    stop: function stop() {
      window.clearInterval(this.get('timerPid')); // NOTE:  Stops the pollster
    },
    start: function start(tripStopId) {
      // NOTE: Starts the pollster, i.e. executes the `onPoll` function every interval.
      this.set('tripStopId', tripStopId);
      this.stop();
      this.getResult(0);
      this.set('timerPid', window.setInterval(Ember.run.bind(this, this.onPoll), this.get('interval')));
    },
    onPoll: function onPoll() {
      this.getResult(0);
    },
    getResult: function getResult(index) {
      var _this = this;

      setTimeout(function () {
        var isReady = index === Number(_this.get('meterCount'));

        if (!isReady) {
          _this.get('logger').logInfo('starting poll ' + index + ' / ' + _this.get('meterCount') + '.');
          _this.get('emis').sendMessage('REQUEST,METER,DATA(' + index + ')').then(function (response) {
            // NOTE: Read the totalizers from meter.
            if (response.length > 2) {
              var meterCounter = _this.get('store').peekAll('meter-counter').filter(function (mc) {
                return mc.get('TotalQty') === Number(_this.valueFor(response, "TOTVT")) && mc.get('MeterId') === Number(_this.meterNumber(response)) && mc.get('TripStopId') === _this.get('tripStopId');
              }).toArray()[0];

              if (Ember.isBlank(meterCounter)) {
                var record = _this.get('store').createRecord('meter-counter', { // NOTE: if counters is empty get counter row from emis.
                  TripStopId: _this.get('tripStopId'),
                  MeterId: Number(_this.meterNumber(response)),
                  MeterName: '',
                  MeterSerial: '',
                  TotalQty: _this.valueFor(response, "TOTVT"),
                  Total15Qty: _this.valueFor(response, "TOTVC"),
                  TotalWeight: _this.valueFor(response, "TOTMASS"),
                  ReportDt: new Date()
                });

                record.save();
              }
            }
          });

          _this.getResult(index + 1);
        }
      }, 10000);
    },
    isMeterNotBusy: function isMeterNotBusy() {
      var isReady = true;

      //Check if meter is not busy.
      //REPORT,METER,STATUS(0),LASTERROR="000:Ready to receive next order";MODE="READY"

      return isReady;
    },
    valueFor: function valueFor(response, emisAttribute) {
      return this.get('emis').valueFor(response, emisAttribute);
    },
    meterNumber: function meterNumber(response) {
      return this.get('emis').meterNumber(response);
    }
  });
});