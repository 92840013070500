define('bottomline-execution/models/load', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    Deg15Qty: attr('number'),
    ActualQty: attr('number'),
    Density: attr('number'),
    Temperature: attr('number'),
    ExtOrderId: attr('string'),
    CompartmentCode: attr('string'),
    TicketNb: attr('string'),
    MaterialOwnerNb: attr('string'),
    StorageId: attr('string')
  });
});