define('bottomline-execution/components/truck-inventory', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    scopedData: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    store: Ember.inject.service(),
    vehicleStorages: Ember.inject.service(),

    truckInventoriesSorted: ['truck_storage_id'],
    trailerInventoriesSorted: ['truck_storage_id'],
    sortedTruckInventory: Ember.computed.sort('truckInventories', 'truckInventoriesSorted'),
    sortedTrailerInventory: Ember.computed.sort('trailerInventories', 'trailerInventoriesSorted'),

    hasTruckStorages: Ember.computed('appState.currentTruck', function () {
      return this.get('vehicleStorages').hasTruckStorages();
    }),

    hasTrailerStorages: Ember.computed('appState.currentTrailer', function () {
      return this.get('vehicleStorages').hasTrailerStorages();
    }),
    truckInventories: Ember.computed('shift', function () {
      var state = this.get('appState');
      var truckId = state.get('currentTruck');
      var allTruckInventoriesUnsorted = this.get('shift.truck_inventories') || [];

      var truckInventoryFromTruck = this.get('scopedData').vehicleInventoryByCompartment(allTruckInventoriesUnsorted, [truckId], null);

      return truckInventoryFromTruck;
    }),
    trailerInventories: Ember.computed('shift', function () {
      var state = this.get('appState');
      var trailerId = state.get('currentTrailer');
      var allTruckInventoriesUnsorted = this.get('shift.truck_inventories') || [];

      var truckInventoryFromTrailer = this.get('scopedData').vehicleInventoryByCompartment(allTruckInventoriesUnsorted, [trailerId], null);

      return truckInventoryFromTrailer;
    })
  });
});