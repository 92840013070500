define('bottomline-execution/services/picture-saver', ['exports', '@sentry/browser', 'bottomline-execution/utils/save-b64-to-file-util'], function (exports, _browser, _saveB64ToFileUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    session: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    pictureAttributeNamesForFile: ['BE_PictureGUID', 'TableName', 'TableKey', 'Description', 'PictureType', 'StorageID'],

    _postPictureToApi: function _postPictureToApi(pictureRecord) {
      var _this = this;

      try {
        var imageDataPresent = !Ember.isEmpty(pictureRecord.get('imageData'));
        var imageDataAddedInAdapter = pictureRecord.dataSource == 'file_on_disk';

        if (imageDataPresent || imageDataAddedInAdapter) {
          return pictureRecord.save().catch(function (error) {
            _this._deletePictureFileByGuid(pictureRecord.get('BE_PictureGUID'));
            _this._reportToSentry(error, pictureRecord);
          });
        } else {
          pictureRecord.unloadRecord();
          this._reportToSentry('Picture record missing data', pictureRecord);
        }
      } catch (e) {
        _browser.captureMessage('action: \'_postPictureToApi error while saving image\', error: ' + e);
      }
    },
    _reportToSentry: function _reportToSentry(message, pictureRecord) {
      _browser.withScope(function (scope) {
        scope.setTag('be', 'zero-byte image');
        scope.setContext('picture', pictureRecord);
        scope.setContext('picture (stringified)', { picture: JSON.stringify(pictureRecord) });
        scope.setContext('imageData', { present: !Ember.isEmpty(pictureRecord.get('imageData')),
          source: pictureRecord.dataSource });
        _browser.captureException(new Error(message));
      });
    },
    _pictureFileNameForRecord: function _pictureFileNameForRecord(pictureRecord) {
      var fileContentType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'data';

      return this._pictureFileNameForGuid(pictureRecord.get('BE_PictureGUID'), fileContentType);
    },
    _pictureFileNameForGuid: function _pictureFileNameForGuid(baseName) {
      var fileContentType = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'data';

      switch (fileContentType) {
        case 'data':
          return baseName + '.jpeg';
        case 'metadata':
          return baseName + '.json';
      }
    },
    _savePictureToFile: function _savePictureToFile(pictureRecord) {
      var syncDirectoryPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cordova.file.externalDataDirectory;

      return Ember.RSVP.all([this._savePictureDataToFile(pictureRecord, syncDirectoryPath), this._savePictureMetadataToFile(pictureRecord, syncDirectoryPath)]);
    },
    _savePictureDataToFile: function _savePictureDataToFile(pictureRecord, syncDirectoryPath) {
      var pictureFileName = this._pictureFileNameForRecord(pictureRecord);
      return (0, _saveB64ToFileUtil.saveB64ToFileUtil)(pictureRecord.get('imageData'), pictureFileName, syncDirectoryPath);
    },
    _savePictureMetadataToFile: function _savePictureMetadataToFile(pictureRecord, syncDirectoryPath) {
      var pictureMetadata = pictureRecord.getProperties(this.get('pictureAttributeNamesForFile'));
      var pictureFileName = this._pictureFileNameForRecord(pictureRecord, 'metadata');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        return window.resolveLocalFileSystemURL(syncDirectoryPath, function (directory) {
          directory.getFile(pictureFileName, { create: true }, function (file) {
            file.createWriter(function (fileWriter) {
              fileWriter.onwriteend = function (_event) {
                resolve(pictureFileName);
              };
              fileWriter.write(pictureMetadata);
            }, function () {
              reject(null);
            });
          });
        });
      });
    },
    _readPictureMetadataFromFile: function _readPictureMetadataFromFile(pictureGuid) {
      var _this2 = this;

      var syncDirectoryPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cordova.file.externalDataDirectory;

      var pictureFileName = this._pictureFileNameForGuid(pictureGuid, 'metadata');

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _this2._findFileByName(pictureFileName, syncDirectoryPath).then(function (fileEntry) {
          fileEntry.file(function (file) {
            var reader = new FileReader();
            reader.onloadend = function () {
              var pictureMetadata = JSON.parse(this.result);
              resolve(pictureMetadata);
            };
            reader.readAsText(file);
          });
        }, function (error) {
          _browser.captureMessage('action: \'_readPictureMetadataFromFile\', error: ' + error);
          reject(error);
        });
      });
    },
    _unsyncedImagesFiles: function _unsyncedImagesFiles() {
      var syncDirectoryPath = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : cordova.file.externalDataDirectory;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.resolveLocalFileSystemURL(syncDirectoryPath, function (directory) {
          var dirReader = directory.createReader();
          var entries = [];

          var getEntries = function getEntries() {
            dirReader.readEntries(function (results) {
              if (results.length) {
                for (var i = 0; i < results.length; i++) {
                  if (results[i].nativeURL.endsWith('.jpeg')) {
                    entries.push({ name: results[i].name, nativeURL: results[i].nativeURL });
                  }
                }

                getEntries();
              } else {
                resolve(entries);
              }
            }, function (error) {
              _browser.captureMessage('action: \'_unsyncedImagesFiles -> getEntries\', error: ' + error);
              reject(error);
            });
          };
          getEntries();
        }, function (error) {
          _browser.captureMessage('action: \'_unsyncedImagesFiles\', error: ' + error);
          reject(error);
        });
      });
    },
    _deletePictureFileByGuid: function _deletePictureFileByGuid(pictureGuid) {
      var syncDirectoryPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cordova.file.externalDataDirectory;

      var pictureDataFileName = this._pictureFileNameForGuid(pictureGuid);
      this._deleteFile(pictureDataFileName, syncDirectoryPath);

      var pictureMetadataFileName = this._pictureFileNameForGuid(pictureGuid, 'metadata');
      this._deleteFile(pictureMetadataFileName, syncDirectoryPath);
    },
    _deleteFile: function _deleteFile(fileName) {
      var syncDirectoryPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cordova.file.externalDataDirectory;

      return window.resolveLocalFileSystemURL(syncDirectoryPath, function (directory) {
        directory.getFile(fileName, { create: false }, function (fileEntry) {
          fileEntry.remove(function (_file) {
            // Sentry.captureMessage(`action: '_deleteFile', file_removed: ${fileName}`);
          }, function (error) {
            _browser.captureMessage('action: \'_deleteFile\', error: ' + error);
          }, function () {
            _browser.captureMessage('action: \'_deleteFile\', file_not_exist: ' + fileName);
          });
        });
      });
    },
    _syncPicture: function _syncPicture(pictureRecord) {
      var _this3 = this;

      return this._postPictureToApi(pictureRecord).then(function (savedPictureRecord) {
        if (Ember.isPresent(savedPictureRecord)) {
          _this3._deletePictureFileByGuid(savedPictureRecord.get('BE_PictureGUID'));
        }
      });
    },
    findPictureFileByGuid: function findPictureFileByGuid(pictureGuid) {
      var syncDirectoryPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cordova.file.externalDataDirectory;

      var pictureFileName = this._pictureFileNameForGuid(pictureGuid);
      return this._findFileByName(pictureFileName, syncDirectoryPath);
    },
    _findFileByName: function _findFileByName(fileName) {
      var syncDirectoryPath = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : cordova.file.externalDataDirectory;

      var fileURL = '' + syncDirectoryPath + fileName;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        window.resolveLocalFileSystemURL(fileURL, function (fileEntry) {
          resolve(fileEntry);
        }, function (error) {
          reject(error);
        });
      });
    },
    save: function save(pictureRecord) {
      var _this4 = this;

      if (window.cordova) {
        return new Ember.RSVP.Promise(function (resolve) {
          document.addEventListener('deviceready', function () {
            resolve(_this4._savePictureToFile(pictureRecord).then(function () {
              if (_this4.get('session.isAuthenticated') && _this4.get('internetConnection.online')) {
                _this4._syncPicture(pictureRecord);
              }
            }));
          }, false);
        });
      } else {
        return this._postPictureToApi(pictureRecord).then(function (savedPictureRecord) {
          return savedPictureRecord;
        });
      }
    },
    syncUnsavedPictures: function syncUnsavedPictures() {
      var _this5 = this;

      if (!this.get('internetConnection.online')) {
        return null;
      }

      if (window.cordova) {
        document.addEventListener('deviceready', function () {
          _this5._unsyncedImagesFiles().then(function (fileEntries) {
            fileEntries.forEach(function (entry) {
              var pictureGuid = entry.name.split('.')[0];
              var pictureRecord = _this5.get('store').peekAll('picture').findBy('BE_PictureGUID', pictureGuid);

              if (Ember.isPresent(pictureRecord)) {
                if (pictureRecord.get('isNew')) {
                  _this5._syncPicture(pictureRecord);
                } else if (pictureRecord.get('isLoaded')) {
                  _this5._deletePictureFileByGuid(pictureRecord.get('BE_PictureGUID'));
                }
              } else {
                _this5._readPictureMetadataFromFile(pictureGuid).then(function (pictureMetadata) {
                  pictureMetadata.dataSource = 'file_on_disk';
                  var newPictureRecord = _this5.get('store').createRecord('picture', pictureMetadata);
                  _this5._syncPicture(newPictureRecord);
                });
              }
            });
          });
        }, false);
      } else {
        this.get('store').peekAll('picture').filterBy('isNew', true).forEach(function (picture) {
          return _this5._postPictureToApi(picture);
        });
      }
    }
  });
});