define('bottomline-execution/mirage/factories/trip-stop-truck-storage', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    material_id: 1,
    confirmed_qty: 100,
    truck_storage_id: 1,
    trip_stop_id: null,
    trip_id: null
  });
});