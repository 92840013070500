define('bottomline-execution/components/trip-stop-truck-storage-unload', ['exports', 'bottomline-execution/utils/guid-util'], function (exports, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    i18n: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),

    classNames: 'storage-compartment',
    tagName: 'td',
    classNameBindings: ['isHighlightedCompartment', 'isHiddenCompartment'],

    /*
      If the compartment for which this component is instantiated, is not planned
      for the selected tank, there is no trip stop truck storage yet. The UI element
      that updates 'the confirmed quantity' needs a settable property.
       Therefore the confirmed quantity is set and maintained separately as the
      'operativeConfirmedQty'. Upon actual confirmation, the trip stop truck storage
      is either retrieved or created and the quantity is applied to it.
    */
    operativeConfirmedQty: undefined,

    init: function init() {
      this._super.apply(this, arguments);
      this.setTripStopStorageDependentProperties();
    },
    setTripStopStorageDependentProperties: function setTripStopStorageDependentProperties() {
      this.set('lock', null);
      /*
        Which trip stop truck storage is applicable depends on the selected trip
        stop storage. When a different trip stop storage is selected, the separately
        maintained 'operativeConfirmedQty' needs to be updated.
      */
      this.set('operativeConfirmedQty', this.get('tripStopTruckStorage.confirmedQty'));
    },


    isHighlightedCompartment: Ember.computed.alias('filterStrategy'),
    isHiddenCompartment: Ember.computed.not('showCompartment'),
    showCompartment: Ember.computed('showAllCompartments', 'tripStopStorage', function () {
      if (this.get('showAllCompartments')) {
        return true;
      } else {
        return this.get('filterStrategy');
      }
    }),
    filterStrategy: Ember.computed('defaultFilterStrategy', 'tripStopStorage', function () {
      var defaultFilterStrategy = this.get('defaultFilterStrategy').toLowerCase();
      var site = this.get('site');

      if (defaultFilterStrategy === 'material id' || site.get('useFlowMeter')) {
        return this.get('filterOnMaterialID');
      } else if (defaultFilterStrategy === 'planned tank') {
        return this.get('filterOnPlannedTank');
      }
    }),
    defaultFilterStrategy: Ember.computed.alias('truckSettings.default_compartment_filter_strategy'),
    filterOnMaterialID: Ember.computed.alias('materialSameAsInSelectedTripStopStorage'),
    filterOnPlannedTank: Ember.computed('belongsToSelectedTripStopStorage', 'materialSameAsInSelectedTripStopStorage', function () {
      return this.get('belongsToSelectedTripStopStorage') && this.get('materialSameAsInSelectedTripStopStorage') && (this.get('hasPlannedQuantity') || this.get('hasConfirmedQuantity'));
    }),
    belongsToSelectedTripStopStorage: Ember.computed('tripStopStorage.StorageID', 'tripStopTruckStorage.StorageID', function () {
      return Number(this.get('tripStopStorage.StorageID')) === Number(this.get('tripStopTruckStorage.StorageID'));
    }),
    hasPlannedQuantity: Ember.computed('tripStopTruckStorage.plannedQty', function () {
      return this.get('tripStopTruckStorage.plannedQty') > 0;
    }),
    hasConfirmedQuantity: Ember.computed('tripStopTruckStorage.plannedQty', function () {
      return this.get('tripStopTruckStorage.confirmedQty') > 0;
    }),
    site: Ember.computed.alias('trip-stop.site'),
    showPlannedQuantity: Ember.computed('plannedQty', 'truckSettings.hide_planned_qty_on_delivery', function () {
      return this.get('plannedQty') && !this.get('truckSettings.hide_planned_qty_on_delivery');
    }),
    showLock: Ember.computed.not('materialSameAsInSelectedTripStopStorage'),
    materialSameAsInSelectedTripStopStorage: Ember.computed('tripStopStorage.MaterialID', 'truckInventory.material_id', function () {
      return this.get('tripStopStorage.MaterialID') === this.get('truckInventory.material_id');
    }),
    isLocked: Ember.computed('lock', function () {
      if (this.get('lock') === null) {
        this.set('lock', this.get('defaultLockSetting'));
      }
      return this.get('lock');
    }),
    lock: null,
    defaultLockSetting: Ember.computed.not('materialSameAsInSelectedTripStopStorage'),

    allTripStopTruckStorages: Ember.computed('trip-stop', 'tripStopTruckStorage', function () {
      var trip = this.get('trip-stop.trip');
      return trip.get('trip_stops').map(function (ts) {
        return ts.get('trip_stop_truck_storages').toArray();
      }).flatten().filterBy('TruckStorageID', this.get('truckInventory.TruckStorageID'));
    }),
    unloadedTripStopTruckStorages: Ember.computed('trip-stop', 'tripStopStorage', function () {
      return this.get('trip-stop.trip_stop_truck_storages').rejectBy('StorageID', this.get('tripStopStorage.StorageID')).filterBy('TruckStorageID', this.get('truckInventory.TruckStorageID')).filterBy('hasConfirmedMaterial').sortBy('tripStopStorage.storage.SequenceNb');
    }),

    tripStopTruckStorage: Ember.computed('tripStopStorage', 'trip-stop.trip_stop_truck_storages.[]', function () {
      var storageId = this.get('tripStopStorage.StorageID');
      var truckStorageId = this.get('truckInventory.TruckStorageID');

      return this.get('trip-stop.trip_stop_truck_storages').filterBy('TruckStorageID', truckStorageId).findBy('StorageID', storageId);
    }),
    findOrCreateTripStopTruckStorage: function findOrCreateTripStopTruckStorage() {
      var tripStopTruckStorage = this.get('tripStopTruckStorage');
      if (Ember.isPresent(tripStopTruckStorage)) return tripStopTruckStorage;

      return this.createTripStopTruckStorage();
    },
    createTripStopTruckStorage: function createTripStopTruckStorage() {
      var tripStop = this.get('trip-stop');
      var tripStopStorage = this.get('tripStopStorage');
      var truckInventory = this.get('truckInventory');

      return this.get('trip-stop.trip_stop_truck_storages').createRecord({
        id: (0, _guidUtil.guidUtil)(),
        ActivityCode: tripStop.get('ActivityCode'),
        confirmedQty: null,
        confirmed15Qty: null,
        MaterialID: truckInventory.get('material_id'),
        plannedQty: 0,
        StorageID: tripStopStorage.get('StorageID'),
        TripID: tripStop.get('TripID'),
        TripStopID: tripStop.get('id'),
        TripStopStorageID: tripStopStorage.get('id'),
        TruckID: truckInventory.get('truck_id'),
        TruckStorageID: truckInventory.get('TruckStorageID')
      });
    },

    plannedQty: Ember.computed('tripStopStorage', function () {
      if (this.get('tripStopStorage.StorageID') == this.get('tripStopTruckStorage.StorageID')) {
        return this.get('tripStopTruckStorage.plannedQty');
      } else {
        var truckStorageId = this.get('truckInventory.TruckStorageID');
        return this.get('trip-stop.trip_stop_truck_storages').filterBy('TruckStorageID', truckStorageId).reduce(function (sum, tsts) {
          return sum + tsts.get('plannedQty');
        }, 0);
      }
    }),
    totalConfirmedQty: Ember.computed('allTripStopTruckStorages.@each.confirmedQty', function () {
      return this.get('allTripStopTruckStorages').map(function (item) {
        if (item.get('trip_stop.isUnload')) {
          return -1 * item.get('confirmedQty');
        } else {
          return item.get('confirmedQty');
        }
      }).reduce(function (previousValue, currentValue) {
        return Number(previousValue) + Number(currentValue);
      }, 0);
    }),

    tripStopStorageChanged: Ember.observer('tripStopStorage', function () {
      this.setTripStopStorageDependentProperties();
    }),

    actions: {
      toggleLock: function toggleLock() {
        this.toggleProperty('lock');
      },
      confirmQty: function confirmQty(qty) {
        var tripStopTruckStorage = this.findOrCreateTripStopTruckStorage();
        var tripStopStorage = this.get('tripStopStorage');

        if (Ember.isPresent(qty)) {
          tripStopTruckStorage.set('confirmedQty', qty);
          this.set('operativeConfirmedQty', qty);
        } else {
          tripStopTruckStorage.set('confirmedQty', this.get('operativeConfirmedQty'));
        }

        tripStopTruckStorage.linkTripStopStorage(tripStopStorage);
        tripStopStorage.updateConfirmedQty();
      }
    }
  });
});