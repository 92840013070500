define('bottomline-execution/routes/emis-debug', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    emis: Ember.inject.service(),

    model: function model() {
      return this.get('emis');
    },
    setupController: function setupController(controller) {
      controller.set('messages', this.store.peekAll('sening-queue'));
      controller.set('model', this.get('emis'));
    }
  });
});