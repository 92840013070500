define('bottomline-execution/services/emis', ['exports', 'ember-concurrency'], function (exports, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    logger: Ember.inject.service('remote-logger'),

    status: 'IDLE',
    meters: Ember.A([]),
    meterCount: 0,
    emisRequest: (0, _emberConcurrency.taskGroup)().enqueue(),
    batch: Ember.A([]),
    lastResult: '',
    connected: false,
    requesting: false,
    address: null,
    deferred: null,

    init: function init() {
      var _this = this;

      if (this.get('appConfiguration.truckSettings.sening_enabled')) {
        document.addEventListener('deviceready', function () {
          if (typeof bluetoothSerial === 'undefined') {
            alert('no serial bluetooth plugin found, please update apk');
            return;
          }

          escan.EnableBT(function () {
            setTimeout(function () {
              bluetoothSerial.list(Ember.run.bind(_this, _this.getSerialBtAdapter), Ember.run.bind(_this, _this.getSerialBtAdapterFailed));
            }, 3000);
          });
        });
      }
    },
    setMeterDetails: function setMeterDetails() {
      var _this2 = this;

      var meterCount = 'REQUEST,METER,SETUP,MeterCount';

      this.sendMessage(meterCount).then(function (response) {
        _this2.isBusy();
        _this2.set('meterCount', _this2.valueFor(response, 'METERCOUNT')[0]);

        for (var i = 0; i < Number(_this2.get('meterCount')); i++) {
          _this2.sendMessage('REQUEST,METER,Device(' + i + ')').then(function (response) {
            return response;
          });
        }
      });
    },
    isBusy: function isBusy() {
      this.sendMessage("REQUEST,METER,STATUS,Mode").then(function (response) {
        return console.log(response);
      });
    },
    reInit: function reInit() {
      this.init();
    },
    setBtList: function setBtList() {
      bluetoothSerial.list(Ember.run.bind(this, this.getSerialBtAdapter), Ember.run.bind(this, this.getSerialBtAdapterFailed));
    },
    sendRawMessage: function sendRawMessage(msg) {
      var _this3 = this;

      this.deferred = Ember.RSVP.defer();
      this.connectDeviceAndRun(this.get('address'), function () {
        var combinedmsg = msg.split("|").map(function (message) {
          return message;
        }).join('');

        _this3.writeRead(combinedmsg);
      }, this.connectFailure);

      return this.deferred.promise;
    },
    sendMessage: function sendMessage(msg) {
      var _this4 = this;

      this.deferred = Ember.RSVP.defer();
      this.connectDeviceAndRun(this.get('address'), function () {
        var msgWithChecksum = msg.split("|").map(function (message) {
          return _this4.messageWithCheckSum(message);
        }).join('');

        _this4.writeRead(msgWithChecksum);
      }, this.connectFailure);

      return this.deferred.promise;
    },
    getReport: function getReport() {
      var _this5 = this;

      setTimeout(function () {
        bluetoothSerial.read(Ember.run.bind(_this5, _this5.readSuccess), Ember.run.bind(_this5, _this5.readFailed));
      }, 1000);
    },
    writeRead: function writeRead(msg) {
      if (typeof bluetoothSerial !== 'undefined') {
        bluetoothSerial.write(msg, Ember.run.bind(this, this.getReport, msg), Ember.run.bind(this, this.messageFailed));
        this.set('requesting', true);
      }
    },
    readSuccess: function readSuccess(result) {
      var _this6 = this;

      this.set('lastResult', result);
      this.set('connected', true);
      this.set('requesting', false);
      setTimeout(function () {
        return _this6.deferred.resolve(result);
      }, 2000);

      return result;
    },
    readFailed: function readFailed(error) {
      var _this7 = this;

      console.log('Read failed:');
      console.log(error);
      setTimeout(function () {
        return _this7.deferred.reject(error);
      }, 2000);
      this.set('requesting', false);
      this.getReport();

      return error;
    },
    messageSuccess: function messageSuccess() {
      this.set('connected', true);
    },
    messageFailed: function messageFailed(error) {
      console.log('Message failed:');
      console.log(error);
      this.set('connected', false);
      alert('Commmunication with truck level registration failed: ' + error);
    },
    getSerialBtAdapter: function getSerialBtAdapter(list) {
      var devices = Ember.A(list);
      var device = devices.findBy('name', 'Serial Adapter');

      this.set('address', device.address);
      this.connectDevice(device.address);
    },
    connectDevice: function connectDevice(address) {
      if (typeof bluetoothSerial === 'undefined') {
        alert('no serial bluetooth plugin found, please update apk');
        return;
      }

      bluetoothSerial.connect(address, Ember.run.bind(this, this.connectSuccess), Ember.run.bind(this, this.connectFailure));
    },
    connectDeviceAndRun: function connectDeviceAndRun(address, fnSuccess, fnError) {
      if (this.get('connected') === false && typeof bluetoothSerial !== 'undefined') {
        bluetoothSerial.connect(address, Ember.run.bind(this, fnSuccess), Ember.run.bind(this, fnError));
      } else {
        fnSuccess();
      }
    },
    connectSuccess: function connectSuccess() {
      var _this8 = this;

      var initMsg = 'SET,ADMIN,PROTOCOL,Ping="COM-Init';

      this.set('connected', true);
      this.sendMessage(initMsg).then(function (response) {
        if (response.length > 0) {
          _this8.set('status', 'IDLE, Multiflow connected');
          _this8.get('logger').logInfo("Multiflow is connected");
          _this8.setMeterDetails();
        } else {
          _this8.set('status', 'IDLE, Multiflow not connected, check cable or dipswitch settings');

          setTimeout(Ember.run.bind(_this8, _this8.connectSuccess), 30000);
        }
      });
    },
    connectFailure: function connectFailure() {
      var _this9 = this;

      this.set('connected', false);

      setTimeout(function () {
        return _this9.connectDevice(_this9.get('address'));
      }, 1000);
    },
    getSerialBtAdapterFailed: function getSerialBtAdapterFailed(error) {
      alert('Devices not found, make sure device is close and switched on: ' + error);
    },
    messageWithCheckSum: function messageWithCheckSum(incoming) {
      // bcc is checksum
      var bcc = 0;
      var message = [String.fromCharCode('02'), incoming, String.fromCharCode('03')];

      for (var i = 0, len = message.join('').length; i < len; i++) {
        bcc = bcc ^ i + message.join('')[i].charCodeAt(0);
      }

      message.push(bcc.toString(16));
      return message.join('');
    },
    meterNumber: function meterNumber(response) {
      if (!Ember.isPresent(response)) {
        return '';
      }

      var possibleMeterNumbers = response.replace(/\(|\)/g, ';').split(';').filter(function (e) {
        return e.length === 1;
      });

      return possibleMeterNumbers.length === 0 ? '' : possibleMeterNumbers[0];
    },
    valueFor: function valueFor(response, emisAttribute) {
      if (Ember.isPresent(response)) {
        var emisVal = response.replace(/;|\D,/g, ';_').split(';').filter(function (e) {
          return e.indexOf('_' + emisAttribute) > -1;
        })[0];

        if (Ember.isPresent(emisVal)) {
          return emisVal.replace(/_|"|\s/g, '').split('=')[1];
        } else {
          return '';
        }
      } else {
        return '';
      }
    }
  });
});