define('bottomline-execution/components/f7-views', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: 'views',
    classNameBindings: 'invertedWidth',
    marginLeft: -400,
    width: 400,
    previousViewWidth: null,
    previousLeftViewWidth: null,
    invertedWidthDidChange: Ember.observer('invertedWidth', function () {
      var view = this.$();
      var leftView = this.$().find('.view-left');
      var mainView = this.$().find('.view-main');

      if (this.get('invertedWidth') === true) {
        if (this.disableLeft) {
          leftView.addClass('is-disabled');
        }

        view.css({ 'margin-left': this.get('marginLeft') + 'px' });

        if (this.get('marginLeft') === 0) {
          this.set('previousViewWidth', view.css('width'));
          this.set('previousLeftViewWidth', leftView.css('width'));

          leftView.css({ 'width': view.width() - mainView.width() - this.get('width') + 'px' });
          view.css({ 'width': view.width() + this.get('width') + 'px' });
        }
      } else {
        if (this.disableLeft) {
          leftView.removeClass('is-disabled');
        }

        view.css({ 'margin-left': '0px' });

        if (this.get('marginLeft') === 0) {
          view.css({ 'width': this.get('previousViewWidth') });
          leftView.css({ 'width': this.get('previousLeftViewWidth') });
        }
      }
    })
  });
});