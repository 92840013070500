define('bottomline-execution/components/action-button', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'a',
    attributeBindings: ['href'],
    href: Ember.computed('router', function () {
      var url = this.get('router').generate(this.get('target'), this.get('trip.id'));
      return url;
    }),
    classNames: ['button', 'button-fill', 'button-lg', 'v-spaced'],
    classNameBindings: ['disabled'],
    disabled: Ember.computed('enabled', function () {
      return Ember.isPresent(this.get('enabled')) && !this.get('enabled');
    }),

    appInstance: Ember.computed(function () {
      return Ember.getOwner(this);
    }),
    router: Ember.computed('appInstance', function () {
      return this.get('appInstance').lookup('router:main');
    })
  });
});