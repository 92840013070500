define('bottomline-execution/mirage/fixtures/contacts', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    id: 1,
    name: 'Zelda'
  }, {
    id: 2,
    name: 'Link'
  }];
});