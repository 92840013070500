define('bottomline-execution/helpers/is-boolean', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isBoolean = isBoolean;
  function isBoolean(values) {
    return !!values[0] == values[0];
  }

  exports.default = Ember.Helper.helper(isBoolean);
});