define('bottomline-execution/components/f7-navbar', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['navbar'],

    didInsertElement: function didInsertElement() {
      this.$('.center').css('opacity', '0');
      var _this = this;
      setTimeout(function () {
        _this.get('f7').sizeNavbars();
        _this.$('.center').css('opacity', '1');
      }, 0);
    }
  });
});