define('bottomline-execution/components/toggle-show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    incrementMediator: 0,
    title: '',
    translate: true,
    classNames: ['card'],
    padContent: true,
    hide: null,
    showContent: true,

    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    toggleName: Ember.computed(function () {
      return 'toggleShow' + this.get('title').classify();
    }),
    showContentChanged: Ember.on('init', Ember.observer('incrementMediator', 'hide', function () {
      var togglePropertyState = this.get('appState').get(this.get('toggleName'));
      var hide = this.get('hide');

      this.set('showContent', hide === null ? togglePropertyState : false);
    })),

    actions: {
      toggle: function toggle() {
        var _this = this;

        this.get('appState').toggleProperty(this.get('toggleName'));
        this.get('appState').save().then(function () {
          _this.incrementProperty('incrementMediator');
        });
      }
    }
  });
});