define('bottomline-execution/utils/form-data-util', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  function buildFormData(formData, data, parentKey) {
    if (data && (typeof data === 'undefined' ? 'undefined' : _typeof(data)) === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(function (key) {
        buildFormData(formData, data[key], parentKey ? parentKey + '[' + key + ']' : key);
      });
    } else {
      var value = data == null ? '' : data;

      formData.append(parentKey, value);
    }
  }

  function formDataBuilder(data) {
    var formData = new FormData();

    buildFormData(formData, data);

    return formData;
  }

  exports.formDataBuilder = formDataBuilder;
});