define('bottomline-execution/controllers/edit-truck', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    store: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    truckSelector: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    sortedTrucks: Ember.computed('model.@each.id', function () {
      return this.get('model').sortBy('id');
    }),

    currentTruck: Ember.computed.alias('appState.currentTruck'),

    actions: {
      select: function select(truck) {
        var _this = this;

        this.get('truckSelector').select(truck).finally(function () {
          _this.transitionToRoute('/').then(function () {
            return window.location.reload();
          });
        });
      }
    }
  });
});