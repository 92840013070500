define('bottomline-execution/authorizers/auth0', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    authorize: function authorize(session_data, callback) {
      callback('Authorization', 'Bearer ' + session_data.access_token);
    }
  });
});