define('bottomline-execution/services/truck-selector', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    customerConfiguration: Ember.inject.service(),
    cordovaPlatform: Ember.inject.service('ember-cordova/platform'),

    select: function select(truck) {
      return this._setCurrentTruck(truck.get('id'));
    },
    resetCurrentTruck: function resetCurrentTruck() {
      return this._setCurrentTruck(null);
    },
    _setCurrentTruck: function _setCurrentTruck(truckId) {
      var appState = this.get('appState');

      appState.set('currentTruck', truckId);
      appState.set('currentTrailer', null);

      appState.save();

      return this._updateTabletConfiguration(truckId);
    },
    _updateTabletConfiguration: function _updateTabletConfiguration(truckId) {
      if (!this.get('cordovaPlatform.isCordova') || truckId == null) return Ember.RSVP.Promise.resolve();

      return this.get('customerConfiguration').updateTabletConfiguration({ truck_id: truckId }).catch(function (error) {
        console.error('Failed to update configuration service', error);
      });
    }
  });
});