define('bottomline-execution/services/device-information', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    cordovaPlatform: Ember.inject.service('ember-cordova/platform'),

    getMacAddress: function getMacAddress() {
      var getMacAddressFunction = void 0;

      if (this.get('cordovaPlatform.isCordova')) {
        getMacAddressFunction = this._getRealMacAddress;
      } else {
        getMacAddressFunction = function getMacAddressFunction(_, reject) {
          reject('Not running on a device');
        };
      }

      return new Ember.RSVP.Promise(getMacAddressFunction);
    },
    _getRealMacAddress: function _getRealMacAddress(resolve, reject) {
      window.MacAddress.getMacAddress(function (macAddress) {
        resolve(macAddress);
      }, function (failure) {
        reject(failure);
      });
    }
  });
});