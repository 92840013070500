define('bottomline-execution/routes/questions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return this._getAnswers();
    },
    redirect: function redirect(model) {
      if (Ember.isBlank(model)) {
        this.transitionTo('trips');
      }
    },
    setupController: function setupController(controller, model) {
      controller.set('answers', model);
    },
    _getAnswers: function _getAnswers() {
      var store = this.get('store');
      var tripStop = this.modelFor('trip-stops.show');

      return store.query('question', { trip_stop_id: tripStop.get('id') }).then(function (questions) {
        return questions.map(function (question) {
          var answer = question.get('answer');

          if (answer.get('id')) {
            return answer;
          }

          return store.createRecord('answer', { trip_stop: tripStop, question: question });
        });
      });
    }
  });
});