define('bottomline-execution/services/android-intents-scan', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    scan: function scan() {
      this._scanIntent();
    },
    _scanIntent: function _scanIntent(description, kind) {
      window.plugins.webintent.startActivity({
        address: 'nl.scangaroo.scanimage/nl.scangaroo.scanimage.ScanImageActivity',
        action: 'android.intent.action.GET_CONTENT',
        extras: {
          resolution: '150dpi',
          mode: 'color',
          output: '/mnt/sdcard/DCIM',
          size: 'Auto'
        }
      }, function (e) {
        console.log('Intent succeeded: ' + e + ', ' + kind + ' ' + description);
      }, function (e) {
        console.log('Intent failed: ' + e + ', ' + kind + ' ' + description);
      });
    }
  });
});