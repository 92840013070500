define("bottomline-execution/utils/delivery-ticket-nb", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  function deliveryTicketNb(truckId, meterIndex, ticketNb) {
    return "" + truckId + String(new Date().getFullYear()).substring(2) + (Number(meterIndex) + 1) + String(ticketNb).slice(-4);
  }

  exports.deliveryTicketNb = deliveryTicketNb;
});