define('bottomline-execution/routes/application', ['exports', 'moment', 'ember-simple-auth/mixins/application-route-mixin', 'bottomline-execution/mixins/wait-app-state', 'bottomline-execution/config/environment', '@sentry/browser'], function (exports, _moment, _applicationRouteMixin, _waitAppState, _environment, _browser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ATX_USERS_ENDPOINT = 'content://com.groeneveldictsolutions.android.atxapi.provider.UserInfoProvider/USERS';

  exports.default = Ember.Route.extend(_applicationRouteMixin.default, _waitAppState.default, {
    androidPermissions: Ember.inject.service(),
    ajax: Ember.inject.service(),
    truckSettingsAjax: Ember.inject.service(),
    i18n: Ember.inject.service(),
    hpScanner: Ember.inject.service(),
    internetConnection: Ember.inject.service(),
    session: Ember.inject.service(),
    localization: Ember.inject.service(),

    state: Ember.computed.alias('controller.model'),

    isAtx: function isAtx() {
      return _environment.default.APP.target !== 'atx';
    },
    beforeModel: function beforeModel() {
      var _this = this;

      setTimeout(function () {
        if (_this.get('internetConnection.offline')) {
          _this.get('f7').alert('Cannot retrieve data', 'Your device appears to be offline, check your internet connection', function () {
            document.location.reload();
          });
          return;
        }
      }, 30000);

      this.get('f7').showPreloader();
      return this.get('localization').load().then(function () {
        return _this._loadDataIfAuthenticated();
      }).finally(function () {
        _this.get('f7').hidePreloader();
      });
    },
    model: function model() {
      var _this2 = this;

      return this.get('store').findAll('state').then(function () {
        var state = _this2.get('store').peekRecord('state', 1);

        if (!state) {
          state = _this2.get('store').createRecord('state', { id: 1, domain: _environment.default.host, language: _environment.default.i18n.defaultLocale });
          state.save();
        }

        _browser.setTag('alias', state.get('alias'));
        if (Ember.isPresent(state.get('currentTruck'))) {
          _browser.setTag('currentTruck', state.get('currentTruck'));
        }

        var language = state.get('language');

        if (Ember.isPresent(language)) {
          _this2.set('i18n.locale', language);
        }

        _moment.default.locale(language || _environment.default.i18n.defaultLocale);

        if (_this2.get('session.isAuthenticated')) {
          _browser.setUser({
            userData: _this2.get('session.session.content.authenticated'),
            applicationSettings: state.toJSON(),
            appVersion: _environment.default.APP.version
          });
        } else {
          _browser.setUser({
            userData: "Not authenticated",
            applicationSettings: state.toJSON(),
            appVersion: _environment.default.APP.version
          });
        }

        return state;
      });
    },
    afterModel: function afterModel(model) {
      var _this3 = this;

      this._confirmOrRequestPermissions();

      if (this.get('session.isAuthenticated')) {
        this.get('truckSettingsAjax').loadSettings(model.get('currentTruck'));
      }
      this._authenticateIfAtx().then(function (data) {
        if (data.length > 0) {
          _this3._loadDataIfAuthenticated().then(function () {
            _this3.transitionTo(data);
          });
        }
      }, function () {
        return _this3.transitionTo('login');
      });
    },
    _confirmOrRequestPermissions: function _confirmOrRequestPermissions() {
      this.get('androidPermissions').confirmPermission('EXTERNAL_STORAGE');
    },
    sessionInvalidated: function sessionInvalidated() {
      if (_environment.default.environment === 'test') {
        return;
      }

      if (!!window.cordova || window.location.port === '4200') {
        document.location = 'index.html';
      } else {
        document.location = '';
      }
    },


    routeAfterAuthentication: 'edit-truck',

    _authenticateIfAtx: function _authenticateIfAtx() {
      var _this4 = this;

      var deferred = Ember.RSVP.defer();

      if (this.get('session.isAuthenticated') && _environment.default.APP.target === 'atx') {
        console.log("while starting it's authenticated, first make sure to log out");
        // this.get('session').invalidate();
      }

      if (_environment.default.APP.target !== 'atx') {
        deferred.resolve('');

        return deferred.promise;
      }

      if (typeof plugins === 'undefined') {
        deferred.reject();

        return deferred.promise;
      }

      if (plugins == null || plugins.contentproviderplugin == null) {
        this.set('errorMessage', this.get('i18n').t('plugin_not_found'));
        deferred.reject();

        return deferred.promise;
      }

      console.log('trying to auto login');
      plugins.contentproviderplugin.query({ contentUri: ATX_USERS_ENDPOINT }, function (data) {
        console.log(data);
        // NOTE: Yes. Every driver with an ATX has the same password. I cried too.
        _this4.get('session').authenticate('authenticator:devise', data[0].user_code, 'schenk1234').then(function () {
          var currentTruck = _this4.get('state.currentTruck');
          var truckDefined = currentTruck && currentTruck.length > 0;
          var targetRoute = truckDefined ? 'shifts' : 'edit-truck';

          _this4.set('state.scanner_brand', 'canon');

          console.info("Truck found: ", currentTruck);
          console.info("Navigating: ", targetRoute);

          return deferred.resolve(targetRoute);
        }).catch(function (error) {
          // logging in with the ATX provided credentials failed.
          _this4.set('errorMessage', _this4.get('i18n').t(error));
          deferred.reject();
        });
      }, function (error) {
        _this4.set('errorMessage', _this4.get('i18n').t(error.info));
        deferred.reject();
      });

      return deferred.promise;
    },
    _loadDataIfAuthenticated: function _loadDataIfAuthenticated() {
      var store = this.get('store');

      if (this.get('session.isAuthenticated')) {
        return this.waitForAppState().then(function (_) {
          return Ember.RSVP.all([store.findAll('material', { reload: true }), store.findAll('material-seller', { reload: true }), store.findAll('adr-material', { reload: true }), store.findAll('otc-material', { reload: true }), store.findAll('truck', { reload: true }), store.findAll('trailer', { reload: true }), store.findAll('extra-time', { reload: true })]);
        });
      }

      return Ember.RSVP.Resolve;
    },


    actions: {
      willTransition: function willTransition() {
        Ember.$('.map').remove();
      },
      invalidateSession: function invalidateSession() {
        this.get('session').invalidate();
        this.transitionTo('dashboard');
      },
      closePanel: function closePanel(pos) {
        this.get('f7').closePanel(pos);
      },
      openPanel: function openPanel(pos) {
        this.get('f7').openPanel(pos);
      }
    }
  });
});