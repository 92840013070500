define('bottomline-execution/mirage/factories/truck-inventory', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    id: function id(i) {
      return 'inventory-' + i;
    },

    current_qty: 0,
    material_id: '',

    min_qty: null,
    max_qty: null,
    truck_storage_id: 0
  });
});