define('bottomline-execution/mirage/factories/trailer', ['exports', 'ember-cli-mirage'], function (exports, _emberCliMirage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    id: function id(i) {
      return 'trailer-' + i;
    }
  });
});