define('bottomline-execution/components/material-design-icon', ['exports', 'ember-cli-material-design-icons/components/material-design-icon'], function (exports, _materialDesignIcon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _materialDesignIcon.default;
    }
  });
});