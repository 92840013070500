define('bottomline-execution/mixins/group-by', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    groupBy: function groupBy(list, group, totals) {
      var groups = Ember.A(Ember.A(list).getEach(group)).uniq();

      return groups.reduce(function (result, item) {
        var itemsForGroup = list.filterBy(group, item);
        var currentGroup = new Ember.Object({ items: itemsForGroup, group: item });

        totals.forEach(function (total) {
          var totalVal = itemsForGroup.reduce(function (prev, item) {
            return (isNaN(prev) ? 0 : parseInt(prev, 10)) + parseInt(item.get(total) || 0, 10);
          }, 0);

          currentGroup.set('total' + total, totalVal);
        });

        if (itemsForGroup.length > 0) {
          return result.concat([currentGroup]);
        }

        return result;
      }, []);
    }
  });
});