define('bottomline-execution/components/problem-atg-sites', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ATGProblemTSS: null,
    areDetailsShown: false,

    actions: {
      toggleDetails: function toggleDetails() {
        this.toggleProperty('areDetailsShown');
      }
    }
  });
});