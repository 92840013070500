define('bottomline-execution/components/standalone-option-switch', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ['standalone-option-switch'],
    clickAction: null,
    value: null,
    label: null,

    showLabel: Ember.computed('label', function () {
      return this.get('label') !== false;
    }),

    click: function click() {
      if (this.attrs['clickAction']) {
        var _attrs;

        (_attrs = this.attrs)['clickAction'].apply(_attrs, arguments);
      } else {
        this.toggleProperty('value');
      }

      return false;
    }
  });
});