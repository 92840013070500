define('bottomline-execution/components/f7-page-content', ['exports', 'bottomline-execution/components/f7-page-content/f7-pull-to-refresh', 'bottomline-execution/components/f7-page-content/f7-infinite-scroll'], function (exports, _f7PullToRefresh, _f7InfiniteScroll) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_f7PullToRefresh.default, _f7InfiniteScroll.default, {
    classNames: ['page-content'],
    hasInfiniteScroll: false,
    hasPullToRefresh: false
  });
});