define('bottomline-execution/mixins/raw-app-state', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    useBxBackend: Ember.computed(function () {
      return this._appState().backend_type === 'bx';
    }).volatile(),

    _appState: function _appState() {
      if (localStorage.state) {
        return JSON.parse(localStorage.state).state.records[1];
      } else {
        return new Ember.Object();
      }
    }
  });
});