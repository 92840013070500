define('bottomline-execution/mixins/pdf-document-opener', ['exports', 'bottomline-execution/config/environment', 'bottomline-execution/utils/guid-util'], function (exports, _environment, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    ajax: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    documentsEndpoint: Ember.computed('appState.domain', function () {
      return this.get('appState.domain') + '/' + _environment.default.api + '/documents';
    }),

    availablePDFDocuments: function availablePDFDocuments(tableName, tableKey) {
      var url = this.get('documentsEndpoint') + '?TableName=' + tableName + '&TableKey=' + tableKey;
      return this.get('ajax').request(url).then(function (result) {
        return result['documents'];
      });
    },
    openPDFByDocumentId: function openPDFByDocumentId(id) {
      var signalOpening = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var warningKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      var url = this.get('documentsEndpoint') + '/' + id;
      this._openPDFDocument(url, signalOpening, warningKey);
    },
    openPDFByDescription: function openPDFByDescription(tableName, description, tableKey) {
      var signalOpening = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var warningKey = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;

      var url = this.get('documentsEndpoint') + '?TableName=' + tableName + '&Description=' + description + '&TableKey=' + tableKey;
      this._openPDFDocument(url, signalOpening, warningKey);
    },
    _openPDFDocument: function _openPDFDocument(url) {
      var _this = this;

      var signalOpening = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var warningKey = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;

      if (signalOpening) signalOpening(true);

      var fileName = (0, _guidUtil.guidUtil)() + '.pdf';
      var filePath = cordova.file.externalDataDirectory + fileName;

      var fileTransfer = new FileTransfer();
      fileTransfer.download(encodeURI(url), filePath, function (fileEntry) {
        Ember.run.later(function () {
          if (signalOpening) signalOpening(false);
        }, 2000);
        cordova.plugins.fileOpener2.open(fileEntry.toInternalURL(), 'application/pdf');
      }, function (error) {
        if (signalOpening) signalOpening(false);
        console.warn('Error retrieving document from backend:', error);
        if (Ember.isPresent(warningKey)) {
          _this.get('f7').alert(_this.get('i18n').t(warningKey), '');
        } else {
          _this.get('f7').alert('Cannot open file (' + error.http_status + ')', 'Warning code: ' + error.code);
        }
      }, null, {
        headers: this.get('ajax.headers')
      });
    }
  });
});