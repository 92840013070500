define('bottomline-execution/components/trip-stop-storage-line', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: 'tr',
    classNames: ['trip-stop-storage'],
    classNameBindings: ['isSelected'],

    compartments: Ember.computed.alias('truckSettings.compartments'),
    showTemperatureDensity15Liters: Ember.computed.alias('model.showTemperatureDensity15Liters'),
    loadTicketnbPerTank: Ember.computed.alias('truckSettings.load_ticketnb_per_tank'),
    isSelected: Ember.computed('activeTripStopStorage', function () {
      return this.get('activeTripStopStorage.id') === this.get('tripStopStorage.id');
    }),

    click: function click() {
      if (this.get('compartments')) {
        var tripStopStorageId = this.get('tripStopStorage.id') || -1;
        this.get('parentController').send('selectLoadTripStopStorage', tripStopStorageId);
      }
    },
    willInsertElement: function willInsertElement() {
      this.get('tripStopStorage').calculateTotalCompartmentQty();
    }
  });
});