define('bottomline-execution/components/edit-language', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    i18n: Ember.inject.service(),
    isEditing: false,
    tagName: 'tr',
    value: Ember.computed('language', function () {
      return this.get('language.title');
    }),
    actions: {
      editMode: function editMode() {
        this.toggleProperty('isEditing');
      },
      saveLocale: function saveLocale() {
        var _this = this;

        return Ember.$.ajax({
          url: _environment.default.i18n.endpoint + ('/' + this.get('language').id),
          type: 'PUT',
          dataType: 'json',
          data: { namespace: 'deliveryplus', key: 'description_language', value: this.get('value') }
        }).then(function () {
          _this.set('language.title', _this.get('value'));
          _this.toggleProperty('isEditing');
        });
      },
      deleteLocale: function deleteLocale() {
        var _this2 = this;

        this.get('f7').confirm('Are you sure?', 'Delete language: ' + this.get('language').id, function () {
          return Ember.$.ajax({
            url: _environment.default.i18n.endpoint + ('/' + _this2.get('language').id),
            type: 'DELETE',
            dataType: 'json',
            data: { namespace: 'deliveryplus' }
          }).then(function () {
            return _this2.get('languages').removeObject(_this2.get('language'));
          });
        });
      }
    }
  });
});