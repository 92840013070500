define('bottomline-execution/components/f7-text-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'li',
    hasErrors: Ember.computed('hasError', function () {
      if (this.get('hasError')) {
        return 'has-error';
      } else {
        return '';
      }
    })
  });
});