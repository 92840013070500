define('bottomline-execution/controllers/trips/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    trips: Ember.inject.controller(),

    points: Ember.computed('model', function () {
      return this.get('model').get('trip_stops').reject(function (tripStop) {
        return tripStop.get('latitude') === null;
      });
    }),

    actions: {
      select: function select(tripStop) {
        this.get('model').get('trip_stops').setEach('selected', false);
        tripStop.set('selected', true);

        return false;
      }
    }
  });
});