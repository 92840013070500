define('bottomline-execution/authenticators/devise', ['exports', 'ember-simple-auth/authenticators/devise', 'bottomline-execution/config/environment', 'bottomline-execution/mixins/session-management'], function (exports, _devise, _environment, _sessionManagement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _devise.default.extend(_sessionManagement.default, {
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    identificationAttributeName: _environment.default.devise.identificationAttributeName,
    tokenAttributeName: _environment.default.devise.tokenAttributeName,
    serverTokenEndpoint: Ember.computed(_environment.default.devise.identificationAttributeName, function () {
      return this.get('host') + '/' + _environment.default.api + '/users/sign_in';
    }).volatile(),

    // Configure Devise to reject the promise in a way that allows
    // showing an error message when the domain is not set in the app
    rejectWithResponse: true,

    host: Ember.computed(_environment.default.devise.identificationAttributeName, function () {
      return this.get('appState.domain');
    }).volatile(),

    authenticate: function authenticate(identification, password) {
      var _this = this;

      return new Promise(function (resolve, reject) {
        var useResponse = _this.get('rejectWithResponse');

        var _getProperties = _this.getProperties('resourceName', 'identificationAttributeName', 'tokenAttributeName'),
            resourceName = _getProperties.resourceName,
            identificationAttributeName = _getProperties.identificationAttributeName,
            tokenAttributeName = _getProperties.tokenAttributeName;

        var url = '' + _this.get('serverTokenEndpoint');
        var options = {};
        if (_this.get('appState.domain')) {
          options = { url: url };
        }
        var data = {};
        data[resourceName] = { password: password };
        data[resourceName][identificationAttributeName] = identification;

        _this.makeRequest(data, options).then(function (response) {
          if (response.ok) {
            response.json().then(function (json) {
              if (_this._validate(json)) {
                var _resourceName = _this.get('resourceName');
                var _json = json[_resourceName] ? json[_resourceName] : json;
                Ember.run(null, resolve, _json);
              } else {
                Ember.run(null, reject, 'Check that server response includes ' + tokenAttributeName + ' and ' + identificationAttributeName);
              }
            });
          } else {
            if (useResponse) {
              Ember.run(null, reject, response);
            } else {
              response.json().then(function (json) {
                return Ember.run(null, reject, json);
              });
            }
          }
        }).catch(function (error) {
          return Ember.run(null, reject, error);
        });
      });
    }
  });
});