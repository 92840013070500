define('bottomline-execution/services/picture-unloader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),

    unloadSavedPictures: function unloadSavedPictures(tripStopIds) {
      var allPictures = this.get('store').peekAll('picture');

      var savedPictures = allPictures.filterBy('isNew', false);

      var savedPicturesToUnload = savedPictures.filter(function (picture) {
        return !tripStopIds.includes(picture.get('TableKey'));
      });

      savedPicturesToUnload.invoke('unloadRecord');
    }
  });
});