define('bottomline-execution/components/shift-item', ['exports', 'bottomline-execution/mixins/trip-sorter'], function (exports, _tripSorter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_tripSorter.default, {
    appConfiguration: Ember.inject.service(),
    tagName: '',

    selectedShift: Ember.computed.alias('parentController.selectedShift'),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),
    isSelected: Ember.computed('selectedShift', function () {
      return this.get('selectedShift') === this.get('shift.id');
    }),

    trips: Ember.computed.alias('shift.trips')
  });
});