define('bottomline-execution/controllers/login', ['exports', 'fetch', 'bottomline-execution/mixins/authenticate'], function (exports, _fetch, _authenticate2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Controller.extend(_authenticate2.default, {
    i18n: Ember.inject.service(),
    session: Ember.inject.service(),
    truckSettingsAjax: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    truckSelector: Ember.inject.service(),
    bxBackend: Ember.computed.equal('appState.backend_type', 'bx'),
    authenticator: Ember.computed('bxBackend', function () {
      if (this.get('bxBackend')) return 'auth0';
      return 'devise';
    }),
    toast: Ember.inject.service(),

    _authenticate: function _authenticate(userName, password) {
      var _this = this;

      var authenticator = 'authenticator:' + this.get('authenticator');
      this.get('session').authenticate(authenticator, userName, password).then(function () {
        _this.get('truckSelector').resetCurrentTruck();
      }, function (error) {
        var errorMessage = 'unexpected_authentication_error';

        if (error instanceof TypeError) {
          switch (error.message) {
            case 'Network request failed':
              errorMessage = 'domain_unreachable';
              break;
          }
        } else if (error instanceof _fetch.Response) {
          switch (error.status) {
            case 401:
              errorMessage = 'login_failed';
              break;
            case 404:
              errorMessage = 'domain_not_set';
              break;
            default:
              errorMessage = error.status + ' ' + error.statusText;
          }
        }

        _this.set('errorMessage', _this.get('i18n').t(errorMessage));
      }).catch(function (error) {
        // display an error when the authenticator raises an error
        _this.set('errorMessage', _this.get('i18n').t(error));
      });
    },


    actions: {
      updateLanguage: function updateLanguage(value) {
        var state = this.get('model');
        state.set('language', value);
        this.set('i18n.locale', value);
        state.save();
      },
      authenticate: function authenticate() {
        var _getProperties = this.getProperties('identification', 'password'),
            identification = _getProperties.identification,
            password = _getProperties.password;

        var _identification$split = identification.split('#'),
            _identification$split2 = _slicedToArray(_identification$split, 2),
            userName = _identification$split2[0],
            configAlias = _identification$split2[1];

        this.authenticateWith(configAlias, userName, password);
      }
    }
  });
});