define('bottomline-execution/serializers/bx/trip', ['exports', 'bottomline-execution/serializers/trip'], function (exports, _trip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _trip.default.extend({
    store: Ember.inject.service(),

    mappedTripStatus: { published: 'planned', started: 'started', finished: 'finished' },
    tripStopSerializer: Ember.computed(function () {
      return this.get('store').serializerFor('trip-stop');
    }),

    normalizeResponse: function normalizeResponse(store, primaryModelClass, payload, _id, _requestType) {
      if (Ember.isEmpty(payload)) return { data: [] };
      return this._super.apply(this, arguments);
    },
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _mapTrip = this._mapTrip(payload),
          normalizedTrip = _mapTrip.normalizedTrip,
          normalizedTripStops = _mapTrip.normalizedTripStops;

      return this._buildNormalizedResponse(normalizedTrip, normalizedTripStops);
    },
    normalizeQueryResponse: function normalizeQueryResponse(store, primaryModelClass, payload, _id, _requestType) {
      var _this = this;

      var includedTripStops = [];

      var normalizedTrips = payload.map(function (json) {
        var _mapTrip2 = _this._mapTrip(json),
            normalizedTrip = _mapTrip2.normalizedTrip,
            normalizedTripStops = _mapTrip2.normalizedTripStops;

        includedTripStops.push(normalizedTripStops);
        return normalizedTrip;
      });

      return this._buildNormalizedResponse(normalizedTrips, includedTripStops);
    },
    _buildNormalizedResponse: function _buildNormalizedResponse(normalizedTrips, includedTripStops) {
      includedTripStops = [].concat(includedTripStops);

      var tripStopData = includedTripStops.flatMap(function (t) {
        return t.data;
      });
      var tripStopSideLoadedData = includedTripStops.flatMap(function (t) {
        return t.included;
      });
      var included = [].concat(tripStopData, tripStopSideLoadedData);

      return { data: normalizedTrips, included: included };
    },
    _mapTrip: function _mapTrip(json) {
      var normalizedTripStops = this.get('tripStopSerializer').normalizeEmbeddedStops(json.id, json.stops, { inventories: json.start_inventory,
        timestamp: json.executed_start_time }, json.end_inventory);
      var normalizedTrip = {
        id: json.id,
        type: 'trip',
        attributes: {
          ConfirmedEndDT: json.executed_end_time,
          ConfirmedStartDT: json.executed_start_time,
          OdometerStart: json.odometer_start,
          PlannedStartDT: json.planned_start_time,
          SequenceNb: json.sequence_nr,
          state: this.get('mappedTripStatus')[json.status.toLowerCase()]
        },
        relationships: {
          'trip_stops': {
            data: normalizedTripStops.data.map(function (_ref) {
              var type = _ref.type,
                  id = _ref.id;
              return { type: type, id: id };
            })
          }
        }
      };

      return { normalizedTrip: normalizedTrip, normalizedTripStops: normalizedTripStops };
    },
    normalizeUpdateRecordResponse: function normalizeUpdateRecordResponse(store, primaryModelClass, payload, id, _requestType) {
      return { data: { id: id } };
    },
    serialize: function serialize(snapshot) {
      return { odometer: snapshot.attr('OdometerStart') || 0 };
    }
  });
});