define('bottomline-execution/models/trip-stop-truck-storage', ['exports', 'ember-data', 'bottomline-execution/models/base'], function (exports, _emberData, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = _base.default.extend({
    appConfiguration: Ember.inject.service(),
    truckSettings: Ember.computed.alias('appConfiguration.truckSettings'),

    TripStopStorageID: attr('number'),
    StorageID: attr('number'),
    TruckStorageID: attr('number'),
    MaterialID: attr('string'),
    ActivityCode: attr('string'),
    TripStopID: attr('string'),
    confirmedQty: attr('number', { defaultValue: 0 }),
    confirmed15Qty: attr('number', { defaultValue: 0 }),
    Density: attr('number'),
    Temperature: attr('number'),
    plannedQty: attr('number'),
    TripID: attr('number'),
    LoadID: attr('string'),
    TruckID: attr('string'),

    storage: belongsTo('storage', { async: true }),
    // material: belongsTo('material', { async: true }),

    truckInventory: Ember.computed('TruckStorageID', function () {
      if (Ember.isBlank(this.get('TruckStorageID'))) {
        return null;
      }
      return this.get('trip_stop.trip.shift.truck_inventories').findBy('truck_storage_id', this.get('TruckStorageID'));
    }),
    truckStorage: Ember.computed('truckInventory.TruckStorageID', function () {
      var truckStorageID = this.get('truckInventory.TruckStorageID') || -1;
      return this.store.peekRecord('truck-storage', truckStorageID);
    }),
    tripStopStorage: Ember.computed('TripStopStorageID', function () {
      var tripStopStorageID = this.get('TripStopStorageID') || -1;
      return this.store.peekRecord('trip-stop-storage', tripStopStorageID);
    }),
    trip_stop: Ember.computed('TripStopID', function () {
      if (this.get('TripStopID')) {
        return this.store.peekRecord('trip-stop', this.get('TripStopID'));
      }
    }),
    isLoad: Ember.computed.alias('trip_stop.isLoad'),
    material: Ember.computed('MaterialID', function () {
      if (this.get('MaterialID')) {
        return this.store.peekRecord('material', this.get('MaterialID'));
      }
    }),
    totalConfirmedQty: Ember.computed('storage', 'confirmedQty', function () {
      return Number(this.get('confirmedQty')) + Number(this.get('storage.currentQty'));
    }),

    hasMaterial: Ember.computed('MaterialID', function () {
      return Ember.isPresent(this.get('MaterialID'));
    }),
    hasPlannedMaterial: Ember.computed('hasMaterial', 'hasPlannedQty', function () {
      return this.get('hasMaterial') && this.get('hasPlannedQty');
    }),
    hasConfirmedMaterial: Ember.computed('hasMaterial', 'hasConfirmedQty', function () {
      return this.get('hasMaterial') && this.get('hasConfirmedQty');
    }),
    hasPlannedQty: Ember.computed('plannedQty', function () {
      return this.get('plannedQty') > 0 || this.get('plannedQty') === 0;
    }),
    hasConfirmedQty: Ember.computed('confirmedQty', 'isLoad', function () {
      var use15QtyForLoad = this.get('isLoad') && this.get('truckSettings.temp_density_ltr15_load_enabled');
      var use15QtyForUnload = this.get('truckSettings.temp_density_ltr15_delivery_enabled');

      var quantity = void 0;
      if (use15QtyForLoad || use15QtyForUnload) {
        quantity = this.get('confirmed15Qty');
      } else {
        quantity = this.get('confirmedQty');
      }

      return quantity > 0 || quantity === 0;
    }),

    confirmedActualTo15QtyRatio: function confirmedActualTo15QtyRatio() {
      var qty = parseFloat(this.get('confirmedQty'));
      var qty15 = parseFloat(this.get('confirmed15Qty'));

      return qty15 / qty;
    },
    deriveConfirmed15Qty: function deriveConfirmed15Qty(loadStopCompartment) {
      return this.get('confirmedQty') * loadStopCompartment.confirmedActualTo15QtyRatio();
    },
    empty: function empty() {
      this.set('confirmedQty', 0);
    },
    linkTripStopStorage: function linkTripStopStorage(tripStopStorage) {
      this.setProperties({
        StorageID: tripStopStorage.get('StorageID'),
        MaterialID: tripStopStorage.get('MaterialID'),
        TripStopStorageID: tripStopStorage.get('id')
      });
    },
    setConfirmedFromPlannedQty: function setConfirmedFromPlannedQty() {
      this.set('confirmedQty', this.get('plannedQty'));
    }
  });
});