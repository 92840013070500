define('bottomline-execution/helpers/increment', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.increment = increment;
  function increment(params) {
    return parseInt(params) + 1;
  }

  exports.default = Ember.Helper.helper(increment);
});