define('bottomline-execution/mixins/storages-loader', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    loadAllSiteStorages: function loadAllSiteStorages() {
      var siteIds = this.getAllSiteIds();
      return this.loadSiteStorages(siteIds);
    },
    loadSiteStorages: function loadSiteStorages(siteIds) {
      var _this = this;

      return this.store.query('storage', { filter_key: 'SiteID', filter_values: siteIds }).then(function (storages) {
        _this._associateStorages(storages, 'trip-stop-storage');
        _this._associateStorages(storages, 'trip-stop-truck-storage');

        return _this._loadRecentInventories(storages);
      });
    },
    getAllSiteIds: function getAllSiteIds() {
      return this.store.peekAll('site').map(function (s) {
        return s.get('id');
      });
    },
    _associateStorages: function _associateStorages(storages, modelName) {
      var _this2 = this;

      storages.forEach(function (storage) {
        var modelInstances = _this2.store.peekAll(modelName).filterBy('StorageID', parseInt(storage.id));
        modelInstances.forEach(function (modelInstance) {
          modelInstance.set('storage', storage);
        });
      });
    },
    _loadRecentInventories: function _loadRecentInventories(storages) {
      var _this3 = this;

      var storageIds = storages.map(function (s) {
        return s.get('id');
      });

      return this.store.query('inventory', { filter_key: 'StorageID', filter_values: storageIds }).then(function (inventories) {
        inventories.forEach(function (inventory) {
          var now = new Date();
          var storage = _this3.store.peekRecord('storage', inventory.get('StorageID'));
          storage.updateInventory(inventory, now);
        });
      });
    }
  });
});