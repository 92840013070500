define('bottomline-execution/services/ajax', ['exports', 'ember-ajax/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    requestHeaders: Ember.inject.service(),
    session: Ember.inject.service(),

    host: Ember.computed('appState.domain', function () {
      return this.get('appState.domain') + '/' + this.get('appState.apiPath');
    }),
    headers: Ember.computed('session.isAuthenticated', function () {
      var authorize = this.get('session.isAuthenticated');
      return this.get('requestHeaders').defaultHeaders({ authorize: authorize });
    }),
    contentType: 'application/json; charset=utf-8'
  });
});