define('bottomline-execution/components/barrel-count-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    barrelCount: null,
    labelKey: 'barrel_count'
  });
});