define('bottomline-execution/services/android-logging', ['exports', 'moment', 'bottomline-execution/config/environment', 'bottomline-execution/mixins/raw-app-state'], function (exports, _moment, _environment, _rawAppState) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(_rawAppState.default, {
    currentLog: '',

    init: function init() {
      var _this = this;

      if (!this._loggingEnabled()) return;

      setTimeout(function () {
        document.addEventListener('deviceready', _this._writeLogToFile.bind(_this), false);
      }, this._deviceTimeOut());
    },
    _loggingEnabled: function _loggingEnabled() {
      return this._appState().android_logging && typeof NativeLogs !== 'undefined' && _environment.default.environment !== 'test';
    },
    _clearLog: function _clearLog() {
      var _this2 = this;

      NativeLogs.clearLog();
      setTimeout(function () {
        _this2._writeLogToFile();
      }, 60000);
    },
    _deviceTimeOut: function _deviceTimeOut() {
      return _environment.default.APP.target !== 'atx' ? 10000 : 0; // NOTE: atx needs more time
    },
    _writeLogToFile: function _writeLogToFile() {
      try {
        window.requestFileSystem(LocalFileSystem.PERSISTENT, 0, this._gotFS.bind(this), this._fail);
      } catch (e) {
        console.log('LocalFileSystem is not defined. Logging disabled.');
      }
    },
    _gotFS: function _gotFS(fileSystem) {
      var _this3 = this;

      fileSystem.root.getDirectory('be-logs', { create: true, exclusive: false }, function (dir) {
        dir.getFile([(0, _moment.default)().format('dddd'), 'txt'].join('.'), {
          create: true, exclusive: false
        }, _this3._gotFileEntry.bind(_this3), _this3._fail);
      }, this._fail);
    },
    _gotFileEntry: function _gotFileEntry(fileEntry) {
      var _this4 = this;

      fileEntry.getMetadata(function (m) {
        fileEntry.createWriter(_this4._gotFileWriter.bind(_this4, m.modificationTime), _this4._fail);
      });
    },
    _gotFileWriter: function _gotFileWriter(modifiedAt, writer) {
      var isAppend = (0, _moment.default)(modifiedAt).format('YYYYMMDD') === (0, _moment.default)().format('YYYYMMDD');

      writer.onwriteend = this._clearLog.bind(this);

      if (isAppend) {
        try {
          writer.seek(writer.length);
        } catch (e) {
          console.log("File doesn't exist. Creating new file.");
        }
      }

      NativeLogs.getLog(100, false, function (log) {
        writer.write(log);
      });
    },
    _fail: function _fail(error) {
      console.log(error.code);
    }
  });
});