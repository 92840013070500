define('bottomline-execution/initializers/shared', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(container) {
    var sharedStore = Ember.Object.create({ languages: Ember.A() });

    container.registerOptionsForType('globals', { instantiate: false, singleton: true });
    container.register('globals:shared', sharedStore);
    container.inject('controller', 'shared', 'globals:shared');
    container.inject('service', 'shared', 'globals:shared');
  }

  exports.default = {
    name: 'shared',
    initialize: initialize
  };
});