define("bottomline-execution/utils/array-util", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function areTwoArraysIntersecting(array1, array2) {
    return array1.filter(function (item) {
      return array2.includes(item);
    }).length > 0;
    // reduce((a, b) => a.filter(i => b.includes(i)))
  }

  function mergeIntersectingArrays(arrays) {
    arrays = [].concat(_toConsumableArray(arrays));
    var merged = false;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = arrays.entries()[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _ref = _step.value;

        var _ref2 = _slicedToArray(_ref, 2);

        var index = _ref2[0];
        var value = _ref2[1];

        if (index === arrays.length - 1) break;

        if (areTwoArraysIntersecting(value, arrays[index + 1])) {
          // NOTE: filter() can be replaced by Ember-specific uniq(), but since it is a utility then perhaps just raw js is more appropriate here
          arrays[index] = [].concat(_toConsumableArray(value), _toConsumableArray(arrays[index + 1])).filter(function (x, i, a) {
            return a.indexOf(x) == i;
          });
          arrays.splice(index + 1, 1);
          merged = true;
          break;
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    if (merged) {
      return mergeIntersectingArrays(arrays);
    }

    return arrays;
  }

  exports.areTwoArraysIntersecting = areTwoArraysIntersecting;
  exports.mergeIntersectingArrays = mergeIntersectingArrays;
});