define('bottomline-execution/controllers/trip-stops/show/authorize-site', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      cancel: function cancel() {
        var tripStop = this.get('model');
        tripStop.rollbackAttributes();
        tripStop.unSignCompartments();
        this.transitionToRoute('trips.index');
      },
      toTripStop: function toTripStop() {
        var tripStop = this.get('model');

        this.transitionToRoute('trip-stops.show.index', tripStop.get('TripID'), tripStop.get('id'));
      }
    }
  });
});