define('bottomline-execution/mixins/state-transition', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  /*
    Although 'state machine' is a rather big word for this mixin, it best
    conveys the intent of it: Provide models Shift and Trip to manage their
    state transitions.
  
    This mixin depends on methods in the including objects. These dependencies
    can be recognized by their reference to 'model'.
  */

  var Transition = Ember.Object.extend({
    model: null,
    action: null,
    state: null,
    enabled: Ember.computed('model', 'model.{canStart,canFinish}', function () {
      switch (this.get('state')) {
        case 'started':
          return this.get('model.canStart');
        case 'finished':
          return this.get('model.canFinish');
        default:
          return true;
      }
    }),
    disabled: Ember.computed.not('enabled'),
    isHidden: Ember.computed('model', 'model.hasStartedElements', function () {
      switch (this.get('state')) {
        case 'planned':
          return this.get('model.hasStartedElements');
        case 'finished':
          return !this.get('model.hasStartedElements');
        default:
          return false;
      }
    })
  });

  exports.default = Ember.Mixin.create({
    transitions: Ember.computed(function () {
      return {
        planned: [Transition.create({
          model: this,
          action: 'start',
          state: 'started'
        })],
        started: [Transition.create({
          model: this,
          action: 'cancel',
          state: 'planned'
        }), Transition.create({
          model: this,
          action: 'finish',
          state: 'finished'
        })],
        finished: [Transition.create({
          model: this,
          action: 'restart',
          state: 'started'
        })]
      };
    })
  });
});