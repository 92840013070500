define('bottomline-execution/adapters/bx/truck', ['exports', 'bottomline-execution/adapters/bx/base-adapter', 'ember-ajax/errors'], function (exports, _baseAdapter, _errors) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _baseAdapter.default.extend({
    session: Ember.inject.service(),
    driverId: Ember.computed('session.session.authenticated.driver_id', function () {
      return this.get('session.session.authenticated.driver_id');
    }),

    findAll: function findAll() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        var ajax = _this.get('ajaxService');
        var truckService = _this.get('truckService');

        var getAvailableTrucks = ajax.request(truckService + '/trucks?driver=' + _this.get('driverId'));
        var createVehiclesList = function createVehiclesList(vehicleIds) {
          return ajax.post(truckService + '/vehicles/list', { data: vehicleIds });
        };
        var getVehicleList = function getVehicleList(uuid) {
          return ajax.request(truckService + '/vehicles/list/' + uuid);
        };

        return getAvailableTrucks.then(function (compoundVehicles) {
          var drivableVehicleIds = compoundVehicles.map(function (compound) {
            return compound.drivable_vehicle_id;
          });
          var trailerIds = compoundVehicles.map(function (compound) {
            return compound.trailers;
          }).flatten();
          var vehicleIds = [].concat(drivableVehicleIds, trailerIds);

          return createVehiclesList(vehicleIds).then(function (listId) {
            return getVehicleList(listId).then(function (vehicles) {
              resolve({ compoundVehicles: compoundVehicles, vehicles: vehicles });
            }).catch(function (error) {
              reject(error);
            });
          }).catch(function (error) {
            reject(error);
          });
        }).catch(function (error) {
          var errorMessage = error.message;

          if (error instanceof _errors.UnauthorizedError) {
            errorMessage = 'Unauthorized';
          }

          console.log('Error retrieving trucks: ' + errorMessage);
          reject(errorMessage);
        });
      });
    }
  });
});