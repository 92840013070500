define('bottomline-execution/mixins/adapter-options', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    adapterAction: function adapterAction(action) {
      if (!this.get('appState.useBxBackend')) return {};
      return { adapterOptions: { action: action } };
    }
  });
});