define('bottomline-execution/routes/edit-truck', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    toast: Ember.inject.service(),

    model: function model() {
      var _this = this;

      return this.store.findAll('truck', { reload: true }).catch(function (error) {
        if (error == 'Unauthorized') {
          _this.transitionTo('dashboard');
          _this.get('toast').error("Unauthorized");
        }
      });
    },
    setupController: function setupController(controller, model) {
      controller.set('model', model);

      if (model.get('length') === 1) {
        var currentTruck = model.get('firstObject');
        controller.send('select', currentTruck);
      }
    }
  });
});