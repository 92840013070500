define('bottomline-execution/services/form-multipart', ['exports', 'bottomline-execution/services/ajax'], function (exports, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ajax.default.extend({
    requestHeaders: Ember.inject.service(),

    // INFO: When such header set here explicitly it does not send data as mulipart form data.
    // headers['content-type'] = 'multipart/form-data';
    // These params { processData: false, contentType: false } in the request method call fixes this problem.
    // Since ember-ajax is a wrapper over JQuery.ajax this worked well:
    // https://stackoverflow.com/a/39716628/237646
    headers: Ember.computed('session.isAuthenticated', function () {
      var authorize = this.get('session.isAuthenticated');
      var formMultipartHeaders = this.get('requestHeaders').defaultHeaders({ authorize: authorize });
      delete formMultipartHeaders['Content-Type'];

      return formMultipartHeaders;
    })
  });
});