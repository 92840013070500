define('bottomline-execution/models/picture', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    assetMap: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    url: attr('string'),
    imageData: attr('string'),
    TableName: attr('string'),
    TableKey: attr('string'),
    Description: attr('string'),
    PictureType: attr('string'),
    StorageID: attr('number'),
    PictureAsJSON: attr('string'),
    BE_PictureGUID: attr('string'),

    tripStop: belongsTo('trip-stop'),

    fullUrl: Ember.computed(function () {
      if (Ember.isPresent(this.get('url'))) {
        return '' + this.get('appState.domain') + this.get('url');
      } else {
        return this.get('assetMap').resolve('/assets/loader.gif');
      }
    })
  });
});