define('bottomline-execution/models/truck-storage', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr;
  exports.default = Model.extend({
    scopedData: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    maxTruckStorageQty: attr('string'),
    minTruckStorageQty: attr('string'),
    TruckStorageID: attr('number'),
    truck_id: attr('string'),
    vehicle_type: attr('string'),

    truckInventory: Ember.computed('TruckStorageID', function () {
      var _this = this;

      var shift = this.get('appState.shift');
      return this.get('scopedData').vehicleInventoryByCompartment(shift.get('truck_inventories'), [this.get('truck_id')], null).find(function (truckInventory) {
        return Number(truckInventory.get('truck_storage_id')) === Number(_this.get('TruckStorageID'));
      });
    })
  });
});