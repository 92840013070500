define('bottomline-execution/models/order-detail', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Model = _emberData.default.Model,
      attr = _emberData.default.attr,
      belongsTo = _emberData.default.belongsTo;
  exports.default = Model.extend({
    appConfiguration: Ember.inject.service(),
    locationService: Ember.inject.service('location'),

    OrderID: attr('string'),
    Name: attr('string'),
    MaterialID: attr('string'),
    StorageGroupID: attr('string'),
    SiteID: attr('string'),
    OrderDt: attr('date'),
    EndDT: attr('date'),
    OrderStatus: attr('string'),
    MaterialNameLong: attr('string'),
    MaterialNameShort: attr('string'),
    MinOrderQty: attr('string'),
    OrderQty: attr('string'),
    OrderExtOrderId: attr('string'),
    OrderExtOrderType: attr('string'),
    OrderUrgencyYn: attr('string'),
    LoadingID: attr('string'),
    DeliveryCompatibility: attr('string'),
    Urgency: attr('string'),
    TankFilledYN: attr('boolean'),
    latitude: attr('string'),
    longitude: attr('string'),
    site: belongsTo('site', { async: false }),

    customer: Ember.computed.alias('site.customer'),
    material: Ember.computed('MaterialID', function () {
      return this.store.peekRecord('material', this.get('MaterialID'));
    }),
    distance: Ember.computed('latitude', 'longitude', 'locationService.lng', 'locationService.lat', function () {
      if (this.get('locationService.lat')) {
        var lat1 = Number(this.get('latitude'));
        var lon1 = Number(this.get('longitude'));
        var lat2 = this.get('locationService.lat');
        var lon2 = this.get('locationService.lng');
        var p = 0.017453292519943295; // Math.PI / 180
        var approximity = 0.5 - Math.cos((lat2 - lat1) * p) / 2 + Math.cos(lat1 * p) * Math.cos(lat2 * p) * (1 - Math.cos((lon2 - lon1) * p)) / 2;

        return Math.round(12742 * Math.asin(Math.sqrt(approximity))); // 2 * R; R = 6371 km
      }
    })
  });
});