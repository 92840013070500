define('bottomline-execution/mixins/version', ['exports', 'bottomline-execution/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Mixin.create({
    applicationVersion: Ember.computed('ENV.APP.version', function () {
      /*
      Renaming this function to 'appVersion' will trigger an Ember warning
      (when using it in templates) related to a similarly-named helper
      */
      return _environment.default.APP.version.split('+')[0];
    }),
    appVersionNumber: Ember.computed('ENV.APP.version', function () {
      var regex = /\d{2}.\d{2}.\d{2}/;
      return _environment.default.APP.version.match(regex)[0];
    })
  });
});