define('bottomline-execution/serializers/bx/truck-inventory', ['exports', 'active-model-adapter'], function (exports, _activeModelAdapter) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _activeModelAdapter.ActiveModelSerializer.extend({
    normalizeResponse: function normalizeResponse(truckInventoriesJson) {
      var _this = this;

      var mappedTruckInventories = truckInventoriesJson.map(function (json) {
        var vehicleId = json.vehicle_id;
        var inventoriesJson = json.compartment_inventories;

        return inventoriesJson.map(function (json) {
          var truckStorageId = parseInt(json.number);
          return {
            id: vehicleId + '_' + truckStorageId,
            type: 'truck-inventory',
            attributes: {
              currentQty: json.volume_in_liters,
              material_id: json.product_id,
              maxQty: _this._truckStorageMaxQty(vehicleId, truckStorageId),
              truck_id: vehicleId,
              truck_storage_id: truckStorageId
            }
          };
        });
      }).flatten();

      return { data: mappedTruckInventories };
    },
    _truckStorageMaxQty: function _truckStorageMaxQty(vehicleId, truckStorageId) {
      return this.get('store').peekAll('truck-storage').filterBy('truck_id', vehicleId).findBy('TruckStorageID', truckStorageId).get('maxTruckStorageQty') * 1000;
    }
  });
});