define('bottomline-execution/components/f7-side-panel', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: null,
    side: 'right',
    actions: {
      open: function open() {
        this.get('f7').openPanel(this.get('side'));
      },
      close: function close() {
        this.get('f7').closePanel(this.get('side'));
      }
    }
  });
});