define('bottomline-execution/services/scanner', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    retries: 0,

    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),

    scan: function scan(successFunc) {
      var _this = this;

      if (!escan) {
        alert('No scanner found');
        return;
      }

      this.set('retries', 0);

      var ssid = this.get('appState.scanner_ssid');
      var pass = this.get('appState.scanner_password');

      setTimeout(function () {
        WifiWizard.startScan(function () {
          // scan successful
          escan.ConnectToWifi(function (success) {
            console.log(ssid + ': ' + success);
            setTimeout(function () {
              _this.scanPaper(successFunc);
            }, 2000);
          }, function (fail) {
            alert('Cannot connect to ' + ssid + ', check settings');
            console.log(fail);
          }, ssid, pass);
        }, function () {
          // scan failed
          console.log('Cannot scan wifi networks');
        });

        escan.Done(function () {
          console.log('Done with scanning, reconnecting to original network.');
        });
      }, 300);
    },
    scanPaper: function scanPaper(successFunc) {
      var _this2 = this;

      console.log('Connecting to "' + this.get('appState.scanner_ssid') + '", attempt: ' + this.get('retries'));
      escan.Scan(function (result) {
        successFunc(result);
      }, function (noscan) {
        console.log(noscan);
        _this2.set('retries', _this2.get('retries') + 1);
        if (_this2.get('retries') > 30) {
          console.log('Did not scan, number of retries exceeded.');
          return;
        }

        setTimeout(function () {
          return _this2.scanPaper(successFunc);
        }, 2000);
      });
    }
  });
});