define('bottomline-execution/serializers/bx/inventory-correction', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    build: function build(tripId) {
      // Analogous to createCorrectionStop() in the trip model
      var correctionStop = {
        id: tripId + ':N',
        type: 'inventory-correction',
        attributes: {
          ActivityCode: 'N',
          ConfirmedSequenceNb: 999,
          SequenceNb: 999,
          state: 'finished'
        }
      };

      return { tripStop: correctionStop };
    }
  });
});