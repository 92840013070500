define('bottomline-execution/services/location', ['exports', 'bottomline-execution/config/environment', 'bottomline-execution/utils/guid-util'], function (exports, _environment, _guidUtil) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  exports.default = Ember.Service.extend({
    store: Ember.inject.service(),
    appConfiguration: Ember.inject.service(),
    appState: Ember.computed.alias('appConfiguration.state'),
    currentLocation: null,
    lat: null,
    lng: null,
    accuracy: null,
    altitudeAccuracy: null,
    ownerCode: null,
    truckId: null,
    trackingEnabled: true,

    _setup: Ember.on('init', function () {
      var _this = this;

      this.set('trackingEnabled', true);
      setTimeout(function () {
        var backgroundModeOn = typeof cordova !== 'undefined' && Ember.isPresent(cordova.plugins) && Ember.isPresent(cordova.plugins.backgroundMode);

        if (backgroundModeOn) {
          cordova.plugins.backgroundMode.setDefaults({
            title: 'Bottomline Execution',
            text: 'smarter ways',
            icon: "icon"
          });

          document.addEventListener('deviceready', Ember.run.bind(_this, _this.startTracking), false);
        } else {
          _this.startTracking();
        }
      }, this.deviceTimeOut());
    }),

    deviceTimeOut: function deviceTimeOut() {
      return _environment.default.APP.target !== 'atx' ? 10000 : 0; // NOTE: atx needs more time
    },
    onResume: function onResume() {
      cordova.plugins.backgroundMode.disable();
    },
    onPause: function onPause() {
      setTimeout(function () {
        if (Ember.isPresent(cordova)) {
          cordova.plugins.backgroundMode.enable();
        }
      }, 5000);
    },
    startTracking: function startTracking() {
      document.addEventListener('pause', Ember.run.bind(this, this.onPause), false);
      document.addEventListener('resume', Ember.run.bind(this, this.onResume), false);

      if (this.get('trackingEnabled') && _environment.default.environment !== 'test') {
        if (Ember.isPresent(window.cordova) && Ember.isPresent(window.plugin) && _typeof(window.plugin.locationServices) === "object" && _environment.default.APP.target !== 'atx') {
          window.plugin.locationServices.geolocation.getCurrentPosition(Ember.run.bind(this, this.geolocationSuccess), Ember.run.bind(this, this.geolocationError), this.geolocationConfig);
        } else {
          navigator.geolocation.getCurrentPosition(Ember.run.bind(this, this.geolocationSuccess), Ember.run.bind(this, this.geolocationError), this.geolocationConfig);
        }

        this.set('trackingEnabled', false);
      }
    },
    geolocationSuccess: function geolocationSuccess(location) {
      var currentTruck = this.get('appState').getTruck();
      if (Ember.isEmpty(currentTruck)) return;

      this.set('currentLocation', this.get('store').createRecord('truck-position', {
        id: (0, _guidUtil.guidUtil)(),
        ownerCode: this.get('ownerCode'),
        truckId: this.get('truckId'),
        haulierId: currentTruck.get('HaulierID'),
        latitude: location.coords.latitude,
        longitude: location.coords.longitude,
        speed: location.coords.speed,
        changeDt: new Date(location.timestamp)
      }));
      this.set('lng', location.coords.longitude);
      this.set('lat', location.coords.latitude);

      setTimeout(Ember.run.bind(this, function () {
        this.set('trackingEnabled', true);
        this.startTracking();
      }), 60000);
    },
    geolocationError: function geolocationError() {
      this.resetWithDefaultValues();
      setTimeout(Ember.run.bind(this, function () {
        this.set('trackingEnabled', true);
        this.startTracking();
      }), 60000);
    },

    geolocationConfig: {
      enableHighAccuracy: true,
      timeout: 50000,
      maximumAge: 0
    },
    resetWithDefaultValues: function resetWithDefaultValues() {
      this.setProperties({
        currentLocation: null,
        lng: null,
        lat: null
      });
    }
  });
});