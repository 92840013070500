define('bottomline-execution/transforms/float', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(value) {
      if (value) {
        return value.toString();
      }

      // guess we will use float in feature
      // redefine this
      // if not just remove this file and use string type in model
      // return Ember.create({ x: value[0], y: value[1] });
    }
  });
});