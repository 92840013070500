define('bottomline-execution/mixins/authenticate', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Mixin.create({
    customerConfiguration: Ember.inject.service(),

    authenticateWith: function authenticateWith(configAlias) {
      var _this = this;

      for (var _len = arguments.length, authArgs = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
        authArgs[_key - 1] = arguments[_key];
      }

      this.set('errorMessage', null);
      this.get('f7').showPreloader();

      this.get('customerConfiguration').loadConfiguration(configAlias).then(function (result) {
        if (result != 'ok') {
          _this.get('toast').warning(_this.get('i18n').t(result));
        }

        _this._ensureDomain();
        _this._authenticate.apply(_this, _toConsumableArray(authArgs));
      }, function (error) {
        _this.set('errorMessage', _this.get('i18n').t(error));
      }).finally(function () {
        _this.get('f7').hidePreloader();
      });
    },
    _ensureDomain: function _ensureDomain() {
      if (Ember.isBlank(this.get('appState.domain'))) {
        this.get('appState').set('domain', window.location.origin);
      }
    }
  });
});