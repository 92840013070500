define('bottomline-execution/serializers/bx/trip-stop-truck-storage', ['exports', 'bottomline-execution/serializers/trip-stop-truck-storage'], function (exports, _tripStopTruckStorage) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = _tripStopTruckStorage.default.extend({
    normalizeResponse: function normalizeResponse(tripStopId, tripStopStorages, plannedActionsJson, executedActionsJson) {
      var _this = this;

      var plannedCompartmentIds = this._mergeCompartmentIds(plannedActionsJson, {});
      var compartmentIds = this._mergeCompartmentIds(executedActionsJson, plannedCompartmentIds);

      var compartmentDescriptors = Object.keys(compartmentIds).reduce(function (collector, vehicleId) {
        compartmentIds[vehicleId].forEach(function (compartmentId) {
          return collector.push([vehicleId, compartmentId]);
        });
        return collector;
      }, []);

      var mappedTripStopTruckStorages = compartmentDescriptors.map(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            vehicleId = _ref2[0],
            compartmentId = _ref2[1];

        var planned = _this._getAction(plannedActionsJson, vehicleId, compartmentId);
        var executed = _this._getAction(executedActionsJson, vehicleId, compartmentId);

        var materialId = executed.product_id || planned.product_id;
        var storageId = executed.site_storage_id || planned.site_storage_id;

        return {
          id: tripStopId + '-' + vehicleId + '-' + compartmentId,
          type: 'trip-stop-truck-storage',
          attributes: {
            confirmedQty: executed.volume_in_ambient_liters || null,
            confirmed15Qty: executed.volume_in_standard_liters || null,
            Density: executed.density_in_kilograms_per_cubic_meter || null,
            MaterialID: materialId,
            plannedQty: planned.volume_in_ambient_liters || 0,
            StorageID: storageId || _this._getStorageId(tripStopStorages, materialId),
            Temperature: executed.temperature_in_celsius || null,
            TripStopID: tripStopId,
            TruckID: vehicleId,
            TruckStorageID: Number(compartmentId)
          }
        };
      });

      return { data: mappedTripStopTruckStorages };
    },
    _mergeCompartmentIds: function _mergeCompartmentIds(actionsJson, initialCollection) {
      return actionsJson.reduce(function (collector, action) {
        (collector[action.vehicle_id] || (collector[action.vehicle_id] = [])).push(action.compartment_number);
        collector[action.vehicle_id] = collector[action.vehicle_id].uniq();
        return collector;
      }, initialCollection);
    },
    _getAction: function _getAction(actionsJson, vehicleId, compartmentId) {
      return actionsJson.filterBy('vehicle_id', vehicleId).findBy('compartment_number', compartmentId) || {};
    },
    _getStorageId: function _getStorageId(tripStopStorages, materialId) {
      var tripStopStorage = tripStopStorages.data.find(function (tss) {
        return tss.attributes.MaterialID == materialId;
      }).attributes;
      if (Ember.isPresent(tripStopStorage)) return tripStopStorage.StorageID;
    }
  });
});